<button type="button" class="btn btn-primary d-none" data-toggle="modal" id="modal-ayuda-component-btn" data-target="#modal-ayuda-component">
</button>
<!-- Modal -->
<a class="TEXTO_AYUDA" style="margin: 0px; display: none;
            padding: 0px;" (click)="clickOrganizaLista()" id="modal-ayuda-organizar-btn" data-toggle="modal"
  data-target="#modal-ayuda-component">
  <em class="bi bi-question-circle"></em>¿Necesitas ayuda?</a>
<div class="modal fade" id="modal-ayuda-component" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
  aria-hidden="true">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h2 class="modal-title pasos-popup-modal-titulo" id="exampleModalLabel">Pasos para completar tu inscripción</h2>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <ul class="list-group">
          <ng-container *ngFor='let item of paginaLista;let i = index'>
            <li class="list-group-item">
              <div class="d-flex">
                <div class="titulos-pasos-popup-linea"></div>
                <h5 class="font-lato titulos-pasos-popup-numero">PASO {{item.orden}}:</h5> <h3 class="font-lato-bold ml-1 titulos-pasos-popup-titulo">{{item.titulo}}</h3>
              </div>
              <p class="font-lato text-justify" [innerHTML]="item.texto"></p>
            </li>
          </ng-container>
        </ul>
        <ul *ngIf="esUltimaPagina()" class="list-group">
          <li class="list-group-item">
            <div *ngIf="esUltimaPagina()" class="text-right ">
              <strong class="TEXT_BIENVENIDO">
                Bienvenido a Educación Continua Duoc UC
              </strong>
            </div>
          </li>
        </ul>
      </div>
      <div class="modal-footer text-center">
        <div class="d-flex justify-content-center">
          <button type="button" *ngIf="pagina>1" class="btn btn-duoc btn-duoc-outline" (click)="clickAnterior()">
            Volver
          </button>
          <button type="button" *ngIf="!esUltimaPagina()" class="btn btn-duoc btn-duoc-success" (click)="clickSiguiente()">
            Siguiente
          </button>

          <button type="button" *ngIf="esUltimaPagina()" class="btn btn-duoc btn-duoc-success" data-dismiss="modal">
            Continuar
          </button>
        </div>
      </div>
    </div>
  </div>
</div>