import { HttpClient } from '@angular/common/http';
import { EventEmitter, Injectable, Output } from '@angular/core';
import { Observable, of, Subject } from 'rxjs';
import { environment } from "../../../environments/environment";

const urlTyc = '/contenido/pantalla/TERMINOS_CONDICIONES';

@Injectable({
  providedIn: 'root'
})
export class ContentService {
  public userData = new Subject();
  @Output() getLoggedInName: EventEmitter<any> = new EventEmitter();
  @Output() modalInfoData: EventEmitter<any> = new EventEmitter();

  constructor(
    private http: HttpClient
  ) { }

  asyncUserData(userData: any): Observable<boolean> {
    if ( userData ) {
      this.getLoggedInName.emit( userData )
      return of(true)
    } else {
      this.getLoggedInName.emit('logged out')
      return of(false)
    }
  }

  asyncModalInfo(modalInfo: any): Observable<boolean> {
    if ( modalInfo ) {
      this.modalInfoData.emit( modalInfo )
      return of(true)
    } else {
      this.modalInfoData.emit('sin data')
      return of(false)
    }
  }

  setInfoModal(screenInfo: any) {
    localStorage.setItem('infoModal', JSON.stringify(screenInfo))
  }

  getInfoModal() {
    return localStorage.getItem('infoModal')
  }

  getTyc(): Observable<any> {
    const url = `${environment.urlBase}${urlTyc}`
    return this.http.get(url)
  }

  getScreenContent(screen: string): Observable<any> {
    const url = `${environment.urlBase}/contenido/pantalla/${screen}`
    return this.http.get(url)
  }

  getPersonalData(): Observable<any> {
    const url = `${environment.urlBase}/obtenerDatosPersonales`
    return this.http.get(url)
  }


  showMore() {
    window.document
      .getElementById('custom-card-gray-expand-content')
      ?.classList.remove('d-none');
    window.document
      .getElementById('custom-card-gray-collapse-btn-hidden')
      ?.classList.remove('d-none');
    window.document
      .getElementById('custom-card-gray-collapse-btn')
      ?.classList.add('d-none');
  }

  showLess() {
    window.document
      .getElementById('custom-card-gray-collapse-btn')
      ?.classList.remove('d-none');
    window.document
      .getElementById('custom-card-gray-collapse-btn-hidden')
      ?.classList.add('d-none');
    window.document
      .getElementById('custom-card-gray-expand-content')
      ?.classList.add('d-none');
  }
}
