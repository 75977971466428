import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ServicesService } from 'src/app/services/services.service';
import { ContentService } from 'src/app/services/content/content.service';
import { PostulacionService } from 'src/app/services/postulacion/postulacion.service';

@Component({
  selector: 'app-gestion-pago',
  templateUrl: './gestion-pago.component.html',
  styleUrls: ['./gestion-pago.component.css'],
})
export class GestionPagoComponent implements OnInit {
  timerId: any;
  mostrar: boolean = false;

  infoModalText: string = '';
  tituloPopup: string = 'Hubo un error';

  tituloPagina: string = 'Gestión de pago';
  textoInformativo: string = '';
  textoInfoOt: string = '';
  infoPago: any;
  error: string = '';
  errorTextFiles: string = '';

  filesAttached: any = [];
  allowedFilesExt = ['image/jpg', 'image/jpeg', 'application/pdf'];
  allowedFileSizeBytes = 10485760;
  urlHome: string = 'https://www.duoc.cl/oferta-academica/cursos-diplomados/';
  estadoValidacionOc: boolean = false;
  public cursoFueraPlazo: boolean = false;
  public estado: string = '';
  public botonContinuarMixto: boolean = false;

  public mensajeAlerta =
    'La información de pago de tu programa se encuentra en proceso de revisión. Serás contactado prontamente por una de nuestras ejecutivas comerciales.';
  public estadoVacantes: boolean = true;

  constructor(
    private servicios: ServicesService,
    private contentService: ContentService,
    private spinner: NgxSpinnerService,
    private router: Router,
    private _postulacionService: PostulacionService,
    public _contenidoService: ContentService
  ) {
    const routerData =
      this.router.getCurrentNavigation()?.extras?.state?.['data'];
    if (routerData?.infoModal) {
      window.document.getElementById('modal-ayuda-organizar-btn')?.click();
    }
  }

  async ngOnInit() {
    this.spinner.show();
    const email: any = localStorage.getItem('email');

    /**
     * Revisar estado
     */

    try {
      await this.getInfoPago();

      this.validarFuerzaPlazo();

      this.servicios.postEstado(email).subscribe((estado) => {
        this.servicios.getInfoPago().subscribe({
          next: (result) => {
            this.infoPago = result;

            localStorage.setItem('estado', estado?.estado);

            this.estado = estado?.estado;
            this.estadoValidacionOc = !!(
              estado && estado?.estado == 'VALIDAR_OC'
            );

            if (
              this.estadoValidacionOc &&
              this.infoPago?.financiamiento != 'Mixto' &&
              !this.cursoFueraPlazo
            ) {
              window.document.getElementById('modalValidacionOcBtn')?.click();
            }
            /** Se agrega variable del Screen comparando pago mixto */
            let pantalla: any = this.validarRedireccion();

            console.log('pantall', pantalla, 'estado', estado);
            this.getContent(pantalla);

            this.checkError(result, estado);
          },
        });
      });
    } catch {}
  }
  checkError(result: any, estado: any) {
    if (result.error) {
      if (
        result.error.mensaje ==
        'La postulacion no se pudo realizar. No existen Vacantes'
      ) {
        this.mensajeAlerta =
          "El programa no cuenta con vacantes para esta versión, por favor intenta más tarde o contáctanos al <span class='negrita'><strong>+56 442 201 096</strong>   </span>";
        this.estadoVacantes = false;
        window.document.getElementById('modalValidacionOcBtn')?.click();
        
      }
    }
  }
  getContent(pantalla: any) {
    this.contentService.getScreenContent(pantalla).subscribe((res: any) => {
      console.log(res);
      this.tituloPagina = res?.[0]?.titulo ? res[0].titulo : '';
      this.textoInformativo = res?.[0]?.texto ? res[0].texto : '';
      this.textoInfoOt = res?.[1]?.texto ? res[1].texto : '';
    });
  }
  validarRedireccion(): string {
    if (this.estado == 'PENDIENTE_BACKOFFICE') {
      this.revisarBotonContinuar();
      return 'GESTION_PAGO_MIXTO';
    }
    /**
     * Antes estaba así:
     * if (this.estado == 'PENDIENTE_BACKOFFICE' || this.estado == 'VALIDAR_OC') {
     *  return 'GESTION_PAGO_MIXTO';
     } (Daniel: 22/04/2022)
     */
    if (
      this.estado == 'VALIDAR_OC' &&
      this.infoPago?.financiamiento == 'Mixto'
    ) {
      this.revisarBotonContinuar();

      return 'GESTION_PAGO_MIXTO';
    }

    if (
      this.estado == 'POR_PAGAR' &&
      this.infoPago?.financiamiento != 'Empresa'
    ) {
      this.router.navigate(['/pago-en-linea']);
      return 'GESTION_PAGO';
    }

    return 'GESTION_PAGO';
  }

  mostrarFuerzaPlazo() {
    console.log('mostrar fuera plazo');
    this.tituloPopup = 'Importante';
    this.infoModalText =
      'No puedes dar continuidad al programa actual, ya que su fecha de inicio ha caducado. Sin embargo, tienes la opción de seleccionar otro curso para continuar con tu postulación.';
    window.document.getElementById('text-info-modal-btn')?.click();
  }

  validarFuerzaPlazo() {
    this._postulacionService.ofertaSeleccionada().subscribe({
      next: (res) => {
        console.log('validarFuerzaPlazo', res);
        this.spinner.hide();
        let selectedCourse = res.oferta;

        const fechaInicio = selectedCourse.fechaInicio;
        const partesFechaInicio = fechaInicio.split('/').map(Number);
        const fechaInicioDate = new Date(
          partesFechaInicio[2],
          partesFechaInicio[1] - 1,
          partesFechaInicio[0]
        );

        const hoy = new Date();
        hoy.setHours(0, 0, 0, 0); // Omitir la hora actual para comparar solo las fechas

        if (fechaInicioDate <= hoy) {
          console.log(' fechaInicioDate <= hoy true', fechaInicioDate, hoy);
          this.cursoFueraPlazo = true;

          this.mostrarFuerzaPlazo();
        } else {
          console.log(' fechaInicioDate <= hoy false', fechaInicioDate, hoy);
          this.cursoFueraPlazo = false;
        }
        console.log(this.cursoFueraPlazo);

        /** Se agrega validacion de vacantes para mostrar popup */
        if (!this.estadoVacantes) {
          console.log('estado vacantes', this.estadoVacantes);
          this.cursoFueraPlazo = true;
        }
        this.crearKit();
      },
      error: (err) => {
        this.spinner.hide();
      },
    });
  }

  btnHome() {
    localStorage.clear();
    window.location.href = this.urlHome;
  }

  async getInfoPago() {
    if (this.cursoFueraPlazo) {
      this.mostrarFuerzaPlazo();
    }

    this.spinner.show();
    this.servicios.getOcInfoPago().subscribe({
      next: (res: any) => {
        if (res?.infoPagoResponse) {
          this.infoPago = res.infoPagoResponse;
        }
        this.spinner.hide();
      },
      error: (err: any) => {
        console.log('error', err);
        this.spinner.hide();
        throw new Error(err);
      },
    });
  }

  clickFileUpload(input: any) {
    document.getElementById(input)?.click();
  }

  removeFile(index: number, discountFile: boolean = false) {
    this.filesAttached[index] = null;
  }

  limpiarErrores() {
    setTimeout(() => {
      this.errorTextFiles = '';
    }, 3000);
  }

  onFileChanged(event: any, index: number, discountFile: boolean = false) {
    console.log(event);
    if (event?.target?.files) {
      console.log(event.target.files);
      if (this.filesAttached[index]) this.filesAttached[index] = null;

      if (event.target.files[0].size > this.allowedFileSizeBytes) {
        this.errorTextFiles = 'El tamaño del archivo es mayor a 10Mb.';
        this.limpiarErrores();
        return;
      }
      if (!this.allowedFilesExt.includes(event.target.files[0].type)) {
        this.errorTextFiles =
          'Tipo de archivo no válido. El tipo de archivo debe ser PDF o jpg';
        this.limpiarErrores();
        return;
      }
      this.filesAttached[index] = event.target.files[0];
      console.log(this.filesAttached);
    }
  }

  revisarBotonContinuar() {
    this.servicios.getBotonContinuar().subscribe({
      next: (res: any) => {
        console.log('revisarBotonContinuar', res);
        if (res?.botonContinuar) {
          this.botonContinuarMixto = false;
        } else {
          this.botonContinuarMixto = true;
          if (!this.cursoFueraPlazo && this.estadoVacantes) {
            window.document.getElementById('modalAvisoMixtoBtn')?.click();
          }
        }
      },
      error: (err: any) => {
        this.botonContinuarMixto = true;
      },
    });
    console.log('botoncontinuarMixto final', this.botonContinuarMixto);
  }

  cambiarBotonContinuar() {
    this.servicios.postBotonContinuar();
  }

  clickRepostular(): void {
    clearTimeout(this.timerId);
    localStorage.removeItem('estado');
    let login = this.servicios.getLogin();
    this.spinner.show();
    this.servicios.postRepostular(login.email).subscribe((result) => {
      if (result.error === null) {
        this.router.navigate(['/postulacion']);
      } else {
        this.infoModalText = result?.error?.mensaje;
        window.document.getElementById('text-info-modal-btn')?.click();
      }
      this.spinner.hide();
    });
  }

  postGuardarArchivos(archivos: any) {
    if (this.cursoFueraPlazo) {
      this.mostrarFuerzaPlazo();
    } else {
      this.servicios.postGuardarArchivosOc(archivos).subscribe({
        next: (res: any) => {
          console.log(res);
          if (res?.error !== null) {
            this.error = res?.error?.mensaje
              ? res.error.mensaje
              : 'Hubo un error en los servicios';
            setTimeout(() => {
              this.error = '';
            }, 3000);
          } else {
            localStorage.setItem('estado', 'VALIDAR_OC');
            this.estadoValidacionOc = true;
            console.log(this.infoPago);
            if (this.infoPago.financiamiento != 'Mixto') {
              document.getElementById('modalAvisoBtn')?.click();
            }
          }
          this.spinner.hide();
        },
        error: (err) => {
          this.spinner.hide();
        },
      });
    }
  }

  continuar() {
    if (this.cursoFueraPlazo) {
      this.mostrarFuerzaPlazo();
    } else {
      console.log(this.filesAttached);
      const filterFiles = this.filesAttached.filter(
        (file: any) => file !== null
      );
      if (filterFiles.length > 0) {
        let formdata = new FormData();
        let index = 0;
        this.filesAttached.forEach((file: any, i: number) => {
          if (file) {
            formdata.append(`archivos[${index}].nombreArchivo`, file?.name);
            formdata.append(`archivos[${index}].tipoArchivo`, file?.type);
            formdata.append(`archivos[${index}].archivo`, file, file?.name);
            index++;
          }
        });

        this.spinner.show();
        this.postGuardarArchivos(formdata);
      } else {
        let formdata = new FormData();
        formdata.append(`noArchivos`, '0');
        this.postGuardarArchivos(formdata);
      }
    }
  }

  /**
   * Accion pago mixto (Daniel)
   */

  continuar_pago_mixto() {
    if (this.cursoFueraPlazo) {
      this.mostrarFuerzaPlazo();
    } else {
      this.servicios.postBotonContinuar().subscribe({
        next: (res: any) => {
          console.log('continuar_pago_mixto', res);
          this.botonContinuarMixto = true;
        },
        error: (err: any) => {
          this.botonContinuarMixto = false;
        },
      });
      window.document.getElementById('modalAvisoMixtoBtn')?.click();
    }
  }

  crearKit() {
    console.log('inicia crearKit');
    if (this.cursoFueraPlazo) {
      this.mostrarFuerzaPlazo();
    } else {
      this.timerId = setTimeout(() => {
        this.spinner.show();
        this.servicios.postCrearKit().subscribe((result) => {
          this.spinner.hide();
          console.log(result);
          switch (result.codigoError) {
            case 0:
              this.router.navigate(['/resumen-inscripcion-kit']);

              break;
            case 402:
              this.crearKit();

              break;
            case 412:
              localStorage.clear();
              window.location.href = '/';
              break;
            case 428:
              window.location.reload();

              break;
            default:
              this.router.navigate(['/resumen-inscripcion']);

              break;
          }
          console.log('finaliza llamdo crearKit');
        });
      }, 30000);
    }
  }
}
