import {createFeatureSelector, createSelector} from "@ngrx/store";
import {PARAMETRO_FEATURE_KEY, ParametroState} from "./parametro.state";

export const getParameterState = createFeatureSelector<ParametroState>(PARAMETRO_FEATURE_KEY);

export const fetchParametroLoaded = createSelector(
  getParameterState,
  (state: ParametroState) => state.loaded
);

export const fetchParametroError = createSelector(
  getParameterState,
  (state: ParametroState) => state.error
);

export const fetchParametro  = createSelector(
  getParameterState,
  (state: ParametroState) => state.parameter
);
