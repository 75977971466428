<div class="container container-duoc container-mantencion">
  <section class="mt-4 mb-5">
    <h1 class="text-center" style="letter-spacing: 1px;">Sitio en mantención</h1>
  </section>

  <div class="mantencion-container">
    <em class="bi bi-info-circle mt-3 mb-3 icono"></em>
    <h2 class="mb-4">¡ Lo sentimos !</h2>
    <h3>El sitio no se encuentra disponible temporalmente</h3>
    <h4 class="mb-4">Por favor contáctese con el administrador de Educación Continua Duoc UC.</h4>
    <h4>Te puedes contactar llamando al <strong>(+56) 442 201 096</strong> o al mail <strong>educacioncontinua@duoc.cl</strong>.</h4>

  </div>

</div>
