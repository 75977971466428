import { Component, OnInit } from '@angular/core';
import { Login } from 'src/app/models/login';
import { Pantallas } from 'src/app/models/pantallas';
import { ServicesService } from 'src/app/services/services.service';
import { FormBuilder, Validators } from '@angular/forms';
import { RutValidator } from 'ng9-rut';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-view-valida-rut',
  templateUrl: './view-valida-rut.component.html',
  styleUrls: ['./view-valida-rut.component.css']
})
export class ViewValidaRutComponent implements OnInit {
  textos: Pantallas[] = [];
  paginaLista: Pantallas[] = [];
  pagina: number = 1;
  numeroDocumento: string = "";

  titulo: string = "titulo";
  texto: string = "texto";
  error: string = "";
  login: Login = new Login();
  textoPrograma: string = "";

  usuarioExtranjero: any = false
  estadoRutExtranjero: boolean = false

  constructor(
    private servicios: ServicesService,
    fb: FormBuilder,
    private rutValidator: RutValidator,
    private formBuilder: FormBuilder,
    private router: Router,
    private spinner: NgxSpinnerService,
  ) {
    this.login = this.servicios.getLogin();
    console.log(this.login)
    if ( this.login?.rutExtranjero ) {
      this.usuarioExtranjero = true
      this.validarUsuario()
    }
    this.servicios.getTextos('VALIDA_RUT').subscribe(
      (respuesta) => {
        console.log(respuesta)
        let objeto = respuesta[0];
        this.titulo = objeto.titulo;
        this.texto = objeto.texto;

        this.textoPrograma = respuesta[1].texto
      }
    );
    this.servicios.getTextos('AYUDA').subscribe(
      (respuesta) => {
        this.textos = respuesta;
        this.clickOrganizaLista();
      }
    );

    const routerData = this.router.getCurrentNavigation()?.extras?.state?.['data']
    if (routerData?.infoModal) {
      window.document.getElementById('modal-ayuda-organizar-btn')?.click();
    }
  }

  ngOnInit(): void {
    const estado = localStorage.getItem('estado')
    this.estadoRutExtranjero = !!(estado && estado === 'RUT_EXTRANJERO')
    if ( this.estadoRutExtranjero ) {
      window.document.getElementById('usuarioExtranjeroInfoModalBtn')?.click();
    }
  }

  formularioRut = this.formBuilder.group({
    rut: ['', [Validators.required, this.rutValidator]],
    numeroDocumento: ['', []],
    usuarioExtranjero: [false, []]
  })

  setFormatoRut() {
    let valor: any = this.formularioRut.controls['rut'].value;
    if (valor.length > 1) {
      valor = valor.replace('-', '');
      valor = this.arreglarCP(valor, valor.length - 1);
    }
    this.formularioRut.controls['rut'].setValue(valor);
  }

  arreglarCP(addr: any, posicion: any) {
    return addr.slice(0, posicion) + "-" + (addr).slice(posicion);
  }

  keyBorrarErrores(): void {
    this.error = '';
  }

  /**
   * Función para validar algunos elementos como rut y texto
   * @param rut 
   * @param texto 
   */
  validaForm(rut:string, texto:string): boolean  {
    let continuar:boolean=true;
    if (!this.usuarioExtranjero && (rut == null || rut.trim() == '' || this.login.numeroDocumento == '' || this.login.numeroDocumento == null)) {
      this.error = '<b class="font-merriweather-bold !important;">El campo Rut y Número de Documento no deben ser vacíos</b>';
      this.limpiarError();
      continuar= false;
    } else if (!this.usuarioExtranjero && this.formularioRut.controls['rut'].status == 'INVALID') {
      this.error = '<b class="font-merriweather-bold !important;">Rut ingresado no es válido.</b>';
      this.limpiarError();
      continuar = false;
    } else if (!this.usuarioExtranjero && texto.length != 9) {
      this.error = '<b class="font-merriweather-bold !important;">Número de documento ingresado no es válido.</b>';
      this.limpiarError();
      continuar = false;
    }

    return continuar;
  }

  clickContinuar(): void {
    this.error = '';
    let rut: any = this.formularioRut.controls['rut'].value;
    let documento: any = this.formularioRut.controls['numeroDocumento'].value;
    this.formularioRut.controls['numeroDocumento'].setValue(documento.toUpperCase());
    documento = this.formularioRut.controls['numeroDocumento'].value;
    this.usuarioExtranjero = this.formularioRut.controls['usuarioExtranjero'].value;
    console.log(this.usuarioExtranjero)

    let texto = documento.toUpperCase().replace('A', '');
    this.login.rut = rut;
    this.login.numeroDocumento = documento;
   if (this.validaForm(rut, texto))  {

      this.spinner.show()
      this.servicios.postValidarRut(this.login.rut, this.login.numeroDocumento, this.usuarioExtranjero).subscribe({
        next: (respuesta) => {
          this.spinner.hide()
          if (respuesta.valor) {
            let login = this.servicios.getLogin();
            login.rut = this.login.rut;
            this.servicios.setLogin(login);
            if ( !this.usuarioExtranjero ) {
              window.location.href = '/view-terminos-legales';
            } else {
              this.estadoRutExtranjero = true
              localStorage.setItem('estado', 'RUT_EXTRANJERO')
              window.document.getElementById('usuarioExtranjeroInfoModalBtn')?.click()
            }
          } else if ( !this.usuarioExtranjero ){
              
              this.error = respuesta.error.mensaje;
              this.limpiarError();
            } else {
              window.document.getElementById('usuarioExtranjeroInfoModalBtn')?.click();
            }
          
        },
        error: (error) => {
          this.spinner.hide()
          console.log(error, 'error');
        }
    });
    }
  }
  limpiarError(): void {
    const limpiarError = () => {
      this.error = "";
    }
    setTimeout(limpiarError,3000);
  }

  clickOrganizaLista(): void {
    this.setOrganizaLista(1);
  }

  clickAnterior(): void {
    this.setOrganizaLista(this.pagina - 1);
  }

  clickSiguiente(): void {
    this.setOrganizaLista(this.pagina + 1);
  }

  validarUsuario(event?: any) {
    if ( event ) {
      const usuarioExtranjero = event?.target?.checked
      this.usuarioExtranjero = usuarioExtranjero
    }
    if ( this.usuarioExtranjero ) {
      this.formularioRut.controls['numeroDocumento'].setValue('')
      this.formularioRut.controls['rut'].setValue(null)

      this.formularioRut.controls['rut'].disable()
      this.formularioRut.controls['numeroDocumento'].disable()
    } else {
      this.formularioRut.controls['rut'].enable()
      this.formularioRut.controls['numeroDocumento'].enable()
    }
  }

  esUltimaPagina(): boolean {
    let esUltima = false;
    let total = ((this.pagina - 1) * 2) + 3;
    esUltima = total >= this.textos.length;
    return esUltima;
  }

  private setOrganizaLista(pagina: number) {
    let item = 1;
    this.pagina = pagina;
    this.paginaLista = [];
    let index = 0;
    this.textos.forEach(element => {
      if (this.pagina == 1) {
        if (item <= 3) {
          this.paginaLista[index] = element;
          index++;
        }
      } else if (this.pagina > 1) {
        let empieza = ((this.pagina - 2) * 2) + 3;
        let fin = empieza + 2;
        if (item > empieza && item <= fin) {
          this.paginaLista[index] = element;
          index++;
        }
      }
      item++;
    });
  }

  redirectHome() {
    localStorage.clear();
    window.location.href = "https://www.duoc.cl/oferta-academica/cursos-diplomados/";
  }

}
