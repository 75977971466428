import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { Ng9RutModule } from 'ng9-rut';
import { AppComponent } from './app.component';
import { ViewAyudasComponent } from './componentes/view-ayudas/view-ayudas.component';
import { ViewFooterComponent } from './componentes/view-footer/view-footer.component';
import { ViewHeaderComponent } from './componentes/view-header/view-header.component';
import { ViewPreInscripcionComponent } from './front/view-pre-inscripcion/view-pre-inscripcion.component';
import { ViewPrimerPasoComponent } from './front/view-primer-paso/view-primer-paso.component';
import { ViewRegistroComponent } from './front/view-registro/view-registro.component';
import { ViewTerminosLegalesComponent } from './front/view-terminos-legales/view-terminos-legales.component';
import { ViewValidaRutComponent } from './front/view-valida-rut/view-valida-rut.component';
import { RECAPTCHA_SETTINGS, RecaptchaFormsModule, RecaptchaModule, RecaptchaSettings } from 'ng-recaptcha';
import { PostulacionComponent } from './pages/postulacion/postulacion.component';
import { PostulacionResultadoComponent } from './pages/postulacion-resultado/postulacion-resultado.component';
import { TycComponent } from './pages/tyc/tyc.component';
import { PagoEnLineaComponent } from './pages/pago-en-linea/pago-en-linea.component';
import { FichaPreInscripcionComponent } from './pages/ficha-pre-inscripcion/ficha-pre-inscripcion.component';
import { DecimalPipe, registerLocaleData } from '@angular/common';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { AuthInterceptorService } from './services/auth-interceptor.service';
import { NgxSpinnerModule } from "ngx-spinner";
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import localeEsCl from '@angular/common/locales/es-CL';
import {ModalAyudaComponent} from "./componentes/modal-ayuda/modal-ayuda.component";
import {TextInfoModalComponent} from "./componentes/text-info-modal/text-info-modal.component";
import {ResumenInscripcionComponent} from "./pages/resumen-inscripcion/resumen-inscripcion.component";
import { ResumenInscripcionKitComponent } from './pages/resumen-inscripcion-kit/resumen-inscripcion-kit.component';
import { RecuperarContrasenaComponent } from './pages/recuperar-contrasena/recuperar-contrasena.component';
import { PreInscripcionEmpresaComponent } from './pages/pre-inscripcion-empresa/pre-inscripcion-empresa.component';
import { VerEmailComponent } from './pages/ver-email/ver-email.component';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from '../environments/environment';
import {reducer} from "./state/parametro/parametro.reducer";
import {ParametroEffects} from "./state/parametro/parametro.effects";
import {ParametroFacade} from "./state/parametroFacade";
import {PARAMETRO_FEATURE_KEY} from "./state/parametro/parametro.state";
import { SitioMantencionComponent } from './pages/sitio-mantencion/sitio-mantencion.component';
import { RecuperarContrasenaTempComponent } from './pages/recuperar-contrasena-temp/recuperar-contrasena-temp.component';
import { GestionPagoComponent } from './pages/gestion-pago/gestion-pago.component';
import { HeaderPasosComponent } from './componentes/header-pasos/header-pasos.component';
import { NgChartsModule } from 'ng2-charts';

import {
  ApexAxisChartSeries,
  ApexChart,
  ApexXAxis,
  ApexTitleSubtitle,
  NgApexchartsModule
} from "ng-apexcharts";
import { ModalValidaocPagomixtoComponent } from './componentes/modal-validaoc-pagomixto/modal-validaoc-pagomixto.component';


export type ChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  xaxis: ApexXAxis;
  title: ApexTitleSubtitle;
};


registerLocaleData(localeEsCl, 'es-CL');
interface NgxSpinnerConfig {
  type?: string;
}
@NgModule({
  declarations: [
    AppComponent,
    ViewHeaderComponent,
    ViewFooterComponent,
    ViewPrimerPasoComponent,
    ViewAyudasComponent,
    ViewRegistroComponent,
    ViewValidaRutComponent,
    ViewTerminosLegalesComponent,
    ViewPreInscripcionComponent,
    PostulacionComponent,
    PostulacionResultadoComponent,
    TycComponent,
    PagoEnLineaComponent,
    FichaPreInscripcionComponent,
    ModalAyudaComponent,
    TextInfoModalComponent,
    ResumenInscripcionComponent,
    ResumenInscripcionKitComponent,
    RecuperarContrasenaComponent,
    PreInscripcionEmpresaComponent,
    VerEmailComponent,
    SitioMantencionComponent,
    RecuperarContrasenaTempComponent,
    GestionPagoComponent,
    HeaderPasosComponent,
    ModalValidaocPagomixtoComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    RecaptchaFormsModule,
    RecaptchaModule,NgApexchartsModule,
    Ng9RutModule,NgChartsModule,
    RouterModule.forRoot([
      { path: 'view-login', component: ViewPrimerPasoComponent },
      { path: 'view-rut', component: ViewValidaRutComponent },
      { path: 'view-registro', component: ViewRegistroComponent },
      { path: 'view-terminos-legales', component: ViewTerminosLegalesComponent },
      { path: 'view-pre-inscripcion', component: ViewPreInscripcionComponent },
      { path: 'postulacion', component: PostulacionComponent },
      { path: 'resultado-postulacion', component: PostulacionResultadoComponent },
      { path: 'terminos-y-condiciones', component: TycComponent },
      { path: 'pago-en-linea', component: PagoEnLineaComponent },
      { path: 'ficha-pre-inscripcion', component: FichaPreInscripcionComponent },
      { path: 'resumen-inscripcion', component: ResumenInscripcionComponent },
      { path: 'resumen-inscripcion-kit', component: ResumenInscripcionKitComponent },
      { path: 'recuperar-contrasena', component: RecuperarContrasenaComponent },
      { path: 'recuperar-contrasena-temp', component: RecuperarContrasenaTempComponent },
      { path: 'ficha-pre-inscripcion-empresa', component: PreInscripcionEmpresaComponent },
      { path: 'ver-email/:idEmail', component: VerEmailComponent },
      { path: 'sitio-mantencion', component: SitioMantencionComponent },
      { path: 'gestion-pago', component: GestionPagoComponent },
      { path: '', redirectTo: 'view-login', pathMatch: "full" },
    ]),
    BrowserAnimationsModule,
    NgxSpinnerModule.forRoot({ type: 'ball-scale-multiple' }),
    MatAutocompleteModule,
    StoreModule.forRoot({}),
    StoreModule.forFeature(PARAMETRO_FEATURE_KEY, reducer),
    EffectsModule.forRoot([ParametroEffects]),
    StoreDevtoolsModule.instrument({ maxAge: 25, logOnly: environment.production })
  ],
  providers: [
    {
      provide: RECAPTCHA_SETTINGS,
      useValue: {
        siteKey: environment.recaptcha.siteKey,
      } as RecaptchaSettings,
    },
    {
      provide: MAT_DATE_LOCALE,
      useValue: 'en-GB'
    },
    DecimalPipe,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptorService,
      multi: true
    },
    { provide: LOCALE_ID, useValue: 'es-CL' },
    ParametroFacade
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
