<div class="fondo_step" *ngIf="isMobile === false" ></div>
<div id="msform" *ngIf="isMobile === false" class="paso" style="z-index:-1">
  <ul id="progressbar" >
    <li *ngIf="!estado && paso===1"  [ngClass]="{'active': paso >= 1}"  id="p1"><strong style="font-size: 10px;">REGISTRO DE DATOS</strong>
    </li>

    <li *ngIf="estado  && paso===1"  [ngClass]="{'active': paso >= 1}"  id="p11"><div class="checkmark1"></div><strong style="font-size: 10px;">REGISTRO DE DATOS</strong>
    </li>

    <li *ngIf="estado  && paso===1 && !rechazado"  [ngClass]="{'active': paso >= 1}"  id="p11R"><strong style="font-size: 10px;">REGISTRO DE DATOS</strong>
    </li>

    <li *ngIf="paso > 1"  [ngClass]="{'active': paso >= 1}"  id="p11"><div class="checkmark1"></div><strong style="font-size: 10px;">REGISTRO DE DATOS</strong>
    </li>



    <li *ngIf="!estado && paso===2" [ngClass]="{'active': paso >= 2}" id="p2"><strong style="font-size: 10px;">POSTULACIÓN ENVIADA</strong></li>

    <li *ngIf="estado && paso===2 && !rechazado" [ngClass]="{'active': paso >= 2}" id="p22"><div class="checkmark2"></div><strong style="font-size: 10px;">POSTULACIÓN ENVIADA</strong></li>


    <li *ngIf="estado && paso===2 && rechazado" [ngClass]="{'active': paso >= 3}" id="p22R">
      <strong style="font-size: 10px;">POSTULACIÓN ENVIADA</strong>
    </li>

    <li *ngIf="paso > 2" [ngClass]="{'active': paso >= 2}" id="p22"><div class="checkmark2"> </div><strong style="font-size: 10px;">POSTULACIÓN ENVIADA</strong></li>
    




    <li *ngIf="!estado && paso===3 && !rechazado" [ngClass]="{'active': paso >= 3}" id="p3"><strong style="font-size: 8px;">VALIDACIÓN POSTULACIÓN</strong></li>

    <li *ngIf="estado && paso===3 && !rechazado" [ngClass]="{'active': paso >= 3}" id="p33">
      <div class="checkmark3"></div><strong style="font-size: 10px;">VALIDACIÓN POSTULACIÓN</strong>
    </li>

    <li *ngIf="paso===3 && rechazado" [ngClass]="{'active': paso >= 3}" id="p33R">
      <strong style="color: white; position: absolute; margin-left: 61px;margin-top: -26px;">X</strong>
      <strong style="font-size: 10px;">VALIDACIÓN POSTULACIÓN</strong>
      
    </li>

    <li *ngIf="paso > 3" [ngClass]="{'active': paso >= 3}" id="p33"><div class="checkmark3"> </div><strong style="font-size: 10px;">VALIDACIÓN POSTULACIÓN</strong></li>





    <li *ngIf="!estado && paso===4" [ngClass]="{'active': paso >= 4}" id="p4"><strong style="font-size: 10px;">PAGO</strong></li>

    <li *ngIf="estado && paso===4" [ngClass]="{'active': paso >= 4}" id="p44">
      <div class="checkmark4"></div><strong style="font-size: 10px;">PAGO</strong>
    </li>

    <li *ngIf="paso > 4" [ngClass]="{'active': paso >= 5}" id="p44"><div class="checkmark4"> </div><strong style="font-size: 10px;">PAGO</strong></li>





    <li *ngIf="!estado && paso===5" [ngClass]="{'active': paso >= 5}" id="p5"><strong style="font-size: 10px;">INSCRIPCIÓN REALIZADA</strong></li>

    <li *ngIf="estado && paso===5" [ngClass]="{'active': paso >= 5}" id="p55">
      <div class="checkmark5"></div><strong style="font-size: 10px;">INSCRIPCIÓN REALIZADA</strong>
    </li>

    <li *ngIf="paso > 5" [ngClass]="{'active': paso >= 5}" id="p55"><div class="checkmark5"> </div><strong style="font-size: 10px;">INSCRIPCIÓN REALIZADA</strong></li>




    <li *ngIf="paso < 2"  id="p2"><strong style="font-size: 10px;">POSTULACIÓN ENVIADA</strong></li>
    <li *ngIf="paso < 3"  id="p3"><strong style="font-size: 10px;">VALIDACIÓN POSTULACIÓN</strong></li>
    <li *ngIf="paso < 4"  id="p4"><strong style="font-size: 10px;">PAGO</strong></li>
    <li *ngIf="paso < 5"  id="p5"><strong style="font-size: 10px;">INSCRIPCIÓN REALIZADA</strong></li>


  </ul>
</div>



<div class="fondo_step_movil" *ngIf="isMobile === true" ></div>
<div id="msform" *ngIf="isMobile === true" class="paso_movil">
<table style="width: 100%; ">
  <caption hidden>barra progreso version mobile</caption>
  <th></th>
  <tr>

    <td style="width: 16% !important;">
      <div style="margin-left:17px;font: 0.4em; text-align: center;">{{ paso }} de {{ total }}</div></td>


    <td style="width: 24% !important;">  
      <div class="grafico">
        <canvas  id="myChart"  #myChart></canvas>
      </div>
    </td>

    <td  style="width: 50% !important;">
      <div style="font-size: 0.8em; font-weight: bold; text-align: left;">{{ paso_texto }} </div> 
      <div *ngIf="paso < 5" style="font-size: 0.6em; color: gray; text-align: left;">SIGUIENTE: <strong style="font-size: 1em;">{{ paso_texto_siguiente }}</strong> </div> 
    </td>
  </tr>
</table>




</div>


