import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { Pantallas } from 'src/app/models/pantallas';
import { PostulacionResultado } from 'src/app/models/postulacion-resultado';
import { PostulacionService } from 'src/app/services/postulacion/postulacion.service';
import { ServicesService } from 'src/app/services/services.service';

@Component({
  selector: 'app-postulacion-resultado',
  templateUrl: './postulacion-resultado.component.html',
  styleUrls: ['./postulacion-resultado.component.css']
})
export class PostulacionResultadoComponent implements OnInit {

  resultado: PostulacionResultado = new PostulacionResultado();
  titulo: string = 'Resultado postulación';
  infoModalText: string = '';
  tituloPopup: string = "Hubo un error";
  error: string = "";
  respuestaResultado: any;
  textosVer: Pantallas = new Pantallas();
  postulacionRechazada: boolean = false;
  postulacionPendiente: boolean = false;
  esEmpresa: boolean = false

  getPostulacionTerminado: boolean = false;

  public cursoFueraPlazo:boolean = true;


  public estadoFinal:boolean = false; //para header paso

  constructor(
    private servicio: ServicesService,
    private spinner: NgxSpinnerService,
    private router: Router,
    private _postulacionService: PostulacionService
  ) {
    this.spinner.show();
    const login = this.servicio.getLogin();
    this.getPostulacionTerminado = false;
    this.servicio.getPostulacionResultado(login.email).subscribe({
      next: (result: any) => {
        console.log("getPostulacionResultado", result);
        this.resultado = result;
        console.log(this.resultado);
        this.esEmpresa = result?.esEmpresa
        this.estadoFinal = true;
       
        const mensajeTitulo = result.mensajes.find((x: any) => x.pantalla === 'RESULTADO_POSTULACION_TITULO')
        if (mensajeTitulo !== undefined) {
          this.titulo = mensajeTitulo.titulo;
        }
        if (this.resultado.estado == "VALIDACION_MANUAL_RECHAZADA") {
          
          this.postulacionRechazada = true;
          this.estadoFinal = false;

          
          if (this.resultado.motivoRechazo != null) {
            this.resultado.motivoRechazo = "Motivos: " + this.resultado.motivoRechazo;
          }
        }
        if (this.resultado.estado == "VALIDACION_MANUAL_PENDIENTE"){
          this.postulacionPendiente = true;
          this.estadoFinal = false;
        }
        
        this.getPostulacionTerminado = true;
        this.resultado.mensajes = result.mensajes.filter((x: any) => x.pantalla !== 'RESULTADO_POSTULACION_TITULO')
        this.spinner.hide();
      },
      error: (error) => {
        this.spinner.hide();
      }
  });

    const routerData = this.router.getCurrentNavigation()?.extras?.state?.['data']
    if (routerData?.infoModal) {
      window.document.getElementById('modal-ayuda-organizar-btn')?.click();
    }
  }


  mostrarFuerzaPlazo() {
    this.tituloPopup = "Importante";
      this.infoModalText = "No puedes dar continuidad al programa actual, ya que su fecha de inicio ha caducado. Sin embargo, tienes la opción de seleccionar otro curso para continuar con tu postulación.";
      window.document.getElementById('text-info-modal-btn')?.click();
  }


  validarFuerzaPlazo() {
     this._postulacionService.ofertaSeleccionada().subscribe({
      next: (res) => {
        this.spinner.hide()
        let selectedCourse = res.oferta
       
        const fechaInicio = selectedCourse.fechaInicio;
        const partesFechaInicio = fechaInicio.split('/').map(Number);
        const fechaInicioDate = new Date(partesFechaInicio[2], partesFechaInicio[1] - 1, partesFechaInicio[0]);
        
          const hoy = new Date();
            hoy.setHours(0, 0, 0, 0); // Omitir la hora actual para comparar solo las fechas
        
            if (fechaInicioDate <= hoy) {
              this.cursoFueraPlazo = true;
              this.mostrarFuerzaPlazo();
            }
            else {
              this.cursoFueraPlazo = false;
            }
        
            


       
      }, error: err => this.spinner.hide()
  })
  }


  ngOnInit(): void {
    this.validarFuerzaPlazo();
  }

  clickRepostular(): void {
    
    this.tituloPopup = "Hubo un error";
    let login = this.servicio.getLogin();
    this.spinner.show();
    this.servicio.postRepostular(login.email).subscribe({
      next: (result) => {
        if (result.error === null) {
          this.router.navigate(['/postulacion'])
        } else {
          this.infoModalText = result?.error?.mensaje
          window.document.getElementById('text-info-modal-btn')?.click();
        }
        this.spinner.hide();
      },
      error: (error) => {
        this.spinner.hide();
        this.infoModalText = "Hubo un error al intentar repostular";
        window.document.getElementById('text-info-modal-btn')?.click();
      }

    }
    );
    
  }
  clickHome(): void {
    localStorage.clear();
    window.location.href = "https://www.duoc.cl/oferta-academica/cursos-diplomados/";
  }

  private getErrorPorTipo(codigoError: number): void {
    this.infoModalText = "";
    switch (codigoError) {
      case (417): 
        this.infoModalText = "La postulación no se pudo realizar, la respuesta del servicio esta vacía";
       break;
      case (403): 
        this.tituloPopup = "Importante";
        this.infoModalText = "Este programa no cuenta con vacantes para esta versión, por favor intenta más tarde o contáctanos al <b>+56 442 201 096</b>";
       break;
      case (404): 
        this.infoModalText = "postulante y postulación no se encuentra con el email";
       break;
      case (406): 
        this.infoModalText = "La postulación no se pudo realizar, no hay vacante";
       break;
      case (412): 
        this.infoModalText = "La postulación no se pudo realizar, la respuesta del servicio de consulta de vacante esta vacía";
       break;
      case (409): 
        this.infoModalText = "La postulación no se pudo realizar, la respuesta del servicio de consulta de vacante es no hay vacante";
       break;
      case (428): 
        this.infoModalText = "La postulación no se pudo realizar, no hay vacante para su elección, luego de un reintento";
       break;
    }

  }

  clickContinuar(): void {
    if (this.cursoFueraPlazo) {
      this.mostrarFuerzaPlazo();
    }
    else {
    this.tituloPopup = "Hubo un error";
    this.spinner.show();
    localStorage.removeItem('botonContinuarMixto');
    this.servicio.postInscripcion().subscribe({
      next: (result) => {
        if (result.error != null) {
          this.getErrorPorTipo(result.codigoError);
          window.document.getElementById('text-info-modal-btn')?.click();
          this.spinner.hide();
        } else {
          this.servicio.getTextos('PAGO_EN_LINEA').subscribe(
            (text) => {
              this.textosVer = text[0];
              this.spinner.hide();
              this.respuestaResultado = result;
              window.document.getElementById('btnModalImportante')?.click();
            }
          );
        }

      },
      error: (error) => {
        this.spinner.hide();
      }
    });
    }
  }

  clickAceptar(): void {
    if (this.cursoFueraPlazo) {
      this.mostrarFuerzaPlazo();
    }
    else if ( this.esEmpresa ) {
      window.location.href = '/gestion-pago';
    } else {
      window.location.href = '/pago-en-linea';
    }
  }

  showMore() {
    window.document.getElementById('custom-card-gray-expand-content')?.classList.remove('d-none')
    window.document.getElementById('custom-card-gray-collapse-btn-hidden')?.classList.remove('d-none')
    window.document.getElementById('custom-card-gray-collapse-btn')?.classList.add('d-none')
  }

  showLess() {
    window.document.getElementById('custom-card-gray-collapse-btn')?.classList.remove('d-none')
    window.document.getElementById('custom-card-gray-collapse-btn-hidden')?.classList.add('d-none')
    window.document.getElementById('custom-card-gray-expand-content')?.classList.add('d-none')
  }
}
