<div class="container container-duoc mb-1">
  <div class="text-center-mobile mb-3" >
    <br>
    <app-view-ayudas *ngIf="parametro.estadoSitio"></app-view-ayudas>
    <br>
    <br>
  </div>
</div>
<footer class="">
  <div class="container">
    <div class="row">
      <div class="col">
        <img class="size-logo d-lg-none" src="./assets/images/logo-duoc.svg" alt="logo-duoc" />
        <img class="size-logo d-none d-lg-block" src="./assets/images/logo-escritorio.svg" alt="logo-duoc" />
      </div>
      <div class="col ALINEAR_DERECHA">
        <a href="https://www.facebook.com/EducacionContinuaDuocUC" target="_blank" rel="noopener"><em aria-hidden="true"
            class="icon-facebook"></em></a>
        <a href="https://www.instagram.com/educacioncontinuaduoc/" target="_blank" rel="noopener"><em aria-hidden="true"
            class="icon-instagram"></em></a>
        <a href="https://www.linkedin.com/company/educaci%C3%B3n-continua-duoc-uc/" target="_blank" rel="noopener"><em aria-hidden="true"
            class="icon-linkedin"></em></a>
      </div>
    </div>
  </div>
</footer>
