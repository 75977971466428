import { createAction, props } from "@ngrx/store";
import {Parametro} from "../../models/parametro";

export enum ParametroActionsNames {
  Init = '[Parameter] Init',
  fetchParametro = '[Parameter] Get Parameter',
  fetchParametroSuccess = '[Parameter] Get Parameter Success',
  fetchParametroFailure = '[Parameter] Get Parameter Failure',
}

export const Init = createAction(ParametroActionsNames.Init);

export const fetchParametro = createAction(ParametroActionsNames.fetchParametro);

export const fetchParametroSuccess = createAction(
  ParametroActionsNames.fetchParametroSuccess,
  props<{ parameter: Parametro }>()
);

export const fetchParametroFailure = createAction(
  ParametroActionsNames.fetchParametroFailure,
  props<{ error: any }>()
);
