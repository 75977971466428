export class Oferta {
    id: string = "";
    nombre: string = "";
    tipo: string = "";
    modalidad: string = "";
    fechaInicio: string = "";
    fechaTermino: string = "";
    diasClases: string = "";
    horario: string = "";
    valorPrograma: number = 0;
}