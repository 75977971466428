import {Component, OnInit} from '@angular/core';
import {ActivatedRouteSnapshot, Router, RouterStateSnapshot, RoutesRecognized} from "@angular/router";
import {filter, pairwise, switchMap} from "rxjs";
import {startWith} from "rxjs/operators";
import {Parametro} from "./models/parametro";
import {ParametroFacade} from "./state/parametroFacade";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  paramCerrarSesion = 'logout';
  title = 'frontend';
  isPantallaVerEmail: boolean = false;

  parametro: Parametro = new Parametro();
  currentUrl: string | null = null;

  constructor(private router: Router,
              private readonly parametroFacade: ParametroFacade) {

    this.router.events
      .pipe(
        filter((evt: any) => evt instanceof RoutesRecognized),
        startWith({}),
        pairwise(),
      )
      .subscribe((events: RoutesRecognized[]) => {
        let event = events[1];


        const snapshot: RouterStateSnapshot = event.state;
        const root: ActivatedRouteSnapshot = snapshot.root;
        const child = root.firstChild;
        if (child) {
          const queryParams: any = child.queryParams;
          if (Object.keys(queryParams).length !== 0 &&
            queryParams.hasOwnProperty(this.paramCerrarSesion) &&
            queryParams[this.paramCerrarSesion] === 'true') {
            
              localStorage.clear();
              window.location.href = "/view-login";
          }
        }

        this.currentUrl = event.urlAfterRedirects;
        this.isPantallaVerEmail = event.urlAfterRedirects.startsWith("/ver-email/");
      });
  }

  public ngOnInit(): void {
    this.parametroFacade.parametro();
    this.parametroFacade.loaded$
      .pipe(
        filter((isLoaded: boolean) => isLoaded),
        switchMap(() => this.parametroFacade.parametro$)
      )
      .subscribe((parametro: Parametro) => {
        this.parametro = parametro;
        if (!this.parametro.estadoSitio) {
          this.goToUrl('/sitio-mantencion')
        }
        if (this.parametro.estadoSitio && this.currentUrl !== null && this.currentUrl.startsWith("/sitio-mantencion")) {
          this.goToUrl('/');
        }
      });
  }

  goToUrl(url: string) {
    this.router.navigateByUrl('/', {skipLocationChange: true})
      .then(() =>
        this.router.navigate([url]));
  }
}
