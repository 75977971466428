<app-header-pasos [paso]=4 [estado]="false" [total]="5" [paso_texto]="'PAGO'" [paso_texto_siguiente]="'INSCRIPCIÓN REALIZADA'"></app-header-pasos>

<div class="container container-duoc mb-4">
  <section class="mt-4 mb-4">
    <p class="text-center font-lato">EDUCACIÓN CONTINUA</p>
    <h1 class="text-center" [innerHTML]="texto1.titulo"></h1>
  </section>

  <div class="custom-card-border" *ngIf="this.mostrar">
    <p class="font-lato pt-2 pb-2 pr-1 pl-4">
      ¡Tienes un cupo reservado! <br>
      La reserva de tu cupo estará vigente hasta el <strong class="font-lato-bold">{{parametros.fechaExpiracion}}</strong> a
      las <strong class="font-lato-bold">{{parametros.horaExpiracion}} hrs</strong>.
    </p>
  </div>

  <!-- Desktop -->
  <div class="dn-mobile">
    <div class="custom-card-gray mt-2">
      <div class="custom-card-gray-content position-relative">
        <div class="custom-item-border-75 pl-4">
          <div class="row">
            <div class="col-md-6">
              <div class="custom-card-gray-title">
                <h5 class="font-lato-bold text-uppercase">{{parametros.oferta.tipo}}</h5>
                <h2 class="font-lato-bold">{{parametros.oferta.nombre}}</h2>
              </div>
            </div>
            <div class="col-md-6">
              <div class="custom-card-gray-content-info">
                <p>Modalidad: <strong class="custom-bold">{{parametros.oferta.modalidad}}</strong></p>
                <p>Fecha de inicio: <strong class="custom-bold">{{parametros.oferta.fechaInicio}}</strong></p>
                <p>Fecha de término: <strong class="custom-bold">{{parametros.oferta.fechaTermino}}</strong></p>
                <p>Días de clases: <strong class="custom-bold">{{parametros.oferta.diasClases}}</strong></p>
                <p>Horario: <strong class="custom-bold">{{parametros.oferta.horario}}</strong></p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Mobile -->
  <div class="dn-desktop">
    <div class="custom-card-gray mt-2">
      <div class="custom-card-gray-content position-relative">
        <div class="custom-item-border-75 pl-4">
          <div class="row">
            <div class="col-10">
              <div class="custom-card-gray-title">
                <h5 class="font-lato-bold text-uppercase">{{parametros.oferta.tipo}}</h5>
                <h2 class="font-lato-bold">{{parametros.oferta.nombre}}</h2>
              </div>
            </div>
            <div class="col-2">
              <em id="custom-card-gray-collapse-btn" class="bi bi-plus-circle custom-card-gray-collapse-btn" (click)="_contenidoService.showMore()"></em>
              <em id="custom-card-gray-collapse-btn-hidden" class="bi bi-dash-circle d-none custom-card-gray-collapse-btn" (click)="_contenidoService.showLess()"></em>
            </div>
            <div id="custom-card-gray-expand-content" class="col-md-12 d-none custom-card-gray-expand-hidden">
              <div class="custom-card-gray-content-info">
                <p>Modalidad: <strong class="custom-bold">{{parametros.oferta.modalidad}}</strong></p>
                <p>Fecha de inicio: <strong class="custom-bold">{{parametros.oferta.fechaInicio}}</strong></p>
                <p>Fecha de término: <strong class="custom-bold">{{parametros.oferta.fechaTermino}}</strong></p>
                <p>Días de clases: <strong class="custom-bold">{{parametros.oferta.diasClases}}</strong></p>
                <p>Horario: <strong class="custom-bold">{{parametros.oferta.horario}}</strong></p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="mt-4 text-pago">
    <p [innerHTML]="texto1.texto"></p>
  </div>

  <div class="mt-4">
    <h5 class="font-lato-bold">{{texto2.titulo}}</h5>
    <p [innerHTML]="texto2.texto"></p>





<div *ngIf="!pagoMixto">
    <div class="mt-4">
      <div class="valores-container dn-mobile">
        <div class="valores-info-container">
          <p>Valor del programa:&nbsp;&nbsp;&nbsp;</p>
          <h5>${{parametros.valor | number}}</h5>
        </div>
        <div class="separador"></div>
        <div class="valores-info-container">
          <p>Descuento:&nbsp;&nbsp;&nbsp;</p>
          <h5>${{parametros.descuento | number}}</h5>
        </div>
        <div class="separador"></div>
        <div class="valores-info-container">
          <p><strong>Monto a pagar:&nbsp;&nbsp;&nbsp;</strong> </p>
          <h5>${{parametros.total | number}}</h5>
        </div>
      </div>
    </div>


  <ng-template [ngIf]="estadoVacantes">
  <div class="text-center mt-2 pt-4 dn-mobile">
    <div *ngIf="!cursoFueraPlazo">
      <button type="button" data-toggle="modal" data-target="#modalVolverPostular"
      class="btn btn-duoc btn-duoc-outline mr-1">
      Volver a postular
    </button>

    <a [href]="urlPagoOnline + '?rut_pago=' + rut" target="_blank"
      class="btn btn-duoc btn-duoc-success" *ngIf="this.mostrar">Pagar</a>
    </div>



    <div *ngIf="cursoFueraPlazo">
      <button type="button" data-toggle="modal" data-target="#modalVolverPostular"
      class="btn btn-duoc btn-duoc-outline mr-1">
      Volver a postular
    </button>

    <a class="btn btn-duoc btn-duoc-disabled" *ngIf="this.mostrar">Pagar</a>
    </div>
  </div>

  <div class="text-center mt-5 dn-desktop">
    <div *ngIf="!cursoFueraPlazo" >
      <button type="button" data-toggle="modal"  data-target="#modalVolverPostular"
      class="btn btn-duoc btn-duoc-outline mt-2 btn-w-260">
      Volver a postular
      </button>
      <a [href]="urlPagoOnline + '?rut_pago=' + rut" target="_blank"
      class="btn btn-duoc btn-duoc-success btn-w-260" style="margin-top: 30px;">Pagar</a>
    </div>

    <div *ngIf="cursoFueraPlazo">
      <button type="button" data-toggle="modal"  data-target="#modalVolverPostular"
      class="btn btn-duoc btn-duoc-outline mt-2 btn-w-260">
      Volver a postular
      </button>
      <a class="btn btn-duoc btn-duoc-disabled btn-w-260" style="margin-top: 30px;" >Pagar</a>
    </div>


  </div>
  </ng-template>

  <ng-template [ngIf]="!estadoVacantes">
    <div class="text-center mt-5 dn-desktop">
    <button type="button" data-toggle="modal"  data-target="#modalVolverPostular"
      class="btn btn-duoc btn-duoc-success mt-2 btn-w-260">
      Volver a postular
      </button>
      <a class="btn btn-duoc btn-duoc-disabled btn-w-260" style="margin-top: 30px;" >Pagar</a>
    </div>


    <div class="text-center mt-2 pt-4 dn-mobile">
      <button type="button" data-toggle="modal" data-target="#modalVolverPostular"
      class="btn btn-duoc btn-duoc-success mr-1">
      Volver a postular
    </button>

    <a class="btn btn-duoc btn-duoc-disabled" >Pagar</a>

    </div>


  </ng-template>

</div><!-- NO ES PAGO MIXTO -->


<div *ngIf="pagoMixto"><!-- PAGO MIXTO -->

 <!-- Escritorio -->
 <div class="mt-4 "  *ngIf="pagoMixto">
  <p>Valor del programa:&nbsp;&nbsp;&nbsp; <span style="font-size: 1.2em; font-weight: bold;">${{parametros.valor | number}} </span>

  <p>Descuento:&nbsp;&nbsp;&nbsp;
  <span style="font-size: 1.2em; font-weight: bold;">${{parametros.descuento | number}}</span>
</div>

<div class="mt-4">

<table>
  <caption hidden>valores</caption>
  <th hidden></th>
  <tr>
    <td>
      Aporte empresa: <span style="font-size: 1.2em; font-weight: bold;">${{parametros.montoEmpresa | number}}</span>
    </td>
    <td class="celda_negra">
      Total pago particular: <span style="font-size: 1.2em; font-weight: bold;">${{parametros.montoParticular | number}}</span>
    </td>
  </tr>
</table>


<ng-template [ngIf]="estadoVacantes">
<div class="text-center mt-2 pt-4 dn-mobile">
  <div *ngIf="!cursoFueraPlazo && !mensajeValidaOCMixto">
    <button type="button" data-toggle="modal" data-target="#modalVolverPostular"
    class="btn btn-duoc btn-duoc-outline mr-1"*ngIf="cursoFueraPlazo">
    Volver a postular
  </button>

  <a [href]="urlPagoOnline + '?rut_pago=' + rut" target="_blank"
    class="btn btn-duoc btn-duoc-success"  style="padding-left:80px;padding-right:80px" *ngIf="!cursoFueraPlazo">Pagar</a>
  </div>

  <div *ngIf="!cursoFueraPlazo && mensajeValidaOCMixto" >
    <button  class="btn btn-duoc btn-duoc-success" [disabled]="true">Pagar</button>
  </div>



  <div *ngIf="cursoFueraPlazo">
    <button type="button" data-toggle="modal" data-target="#modalVolverPostular"
    class="btn btn-duoc btn-duoc-outline mr-1" *ngIf="cursoFueraPlazo">
    Volver a postular
  </button>

  <a class="btn btn-duoc btn-duoc-disabled" style="padding-left:80px;padding-right:80px" *ngIf="!cursoFueraPlazo">Pagar</a>
  </div>
</div>

<div class="text-center mt-5 dn-desktop">
  <div *ngIf="!cursoFueraPlazo && !mensajeValidaOCMixto" >
    <button type="button" data-toggle="modal"  data-target="#modalVolverPostular"
    class="btn btn-duoc btn-duoc-outline mt-2 btn-w-260"  *ngIf="cursoFueraPlazo">
    Volver a postular
    </button>
    <a [href]="urlPagoOnline + '?rut_pago=' + rut" target="_blank"
    class="btn btn-duoc btn-duoc-success btn-w-260" style="margin-top: 30px;" *ngIf="!cursoFueraPlazo">Pagar</a>
  </div>

  <div *ngIf="!cursoFueraPlazo && mensajeValidaOCMixto" >
    <button  class="btn btn-duoc btn-duoc-success" [disabled]="true">Pagar</button>
  </div>

  <div *ngIf="cursoFueraPlazo">
    <button type="button" data-toggle="modal"  data-target="#modalVolverPostular"
    class="btn btn-duoc btn-duoc-outline mt-2 btn-w-260"  *ngIf="cursoFueraPlazo">
    Volver a postular
    </button>
    <a class="btn btn-duoc btn-duoc-disabled btn-w-260" style="margin-top: 30px;" *ngIf="!cursoFueraPlazo">Pagar</a>
  </div>

  


</div>
</ng-template>


<ng-template [ngIf]="!estadoVacantes">

  <div class="text-center mt-2 pt-4 dn-mobile">

      <button type="button" data-toggle="modal" data-target="#modalVolverPostular"
      class="btn btn-duoc btn-duoc-success mr-1" >
      Volver a postular
    </button>

    <a class="btn btn-duoc btn-duoc-disabled" style="padding-left:80px;padding-right:80px">Pagar</a>
  </div>


  <div class="text-center mt-5 dn-desktop">

      <button type="button" data-toggle="modal"  data-target="#modalVolverPostular"
      class="btn btn-duoc btn-duoc-success mt-2 btn-w-260" >
      Volver a postular
      </button>
      <a class="btn btn-duoc btn-duoc-disabled btn-w-260"
      style="margin-top: 30px;" >Pagar</a>


  </div>


</ng-template>


</div>



</div> <!-- PAGO MIXTO -->








</div>

<app-text-info-modal [title]="tituloPopup" [innerHtmlText]="infoModalText"></app-text-info-modal>



<app-modal-validaoc-pagomixto [title]="tituloPopup" [innerHtmlText]="infoModalText"></app-modal-validaoc-pagomixto>


<!-- Modal Volver a Postular -->
<!-- Modal -->
<div class="modal fade" id="modalVolverPostular" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
  aria-hidden="true">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h2 class="modal-title" id="exampleModalLabel">Aviso</h2>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true" class="span-modal">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <p class="font-lato text-justify text-center-mobile">Al presionar el botón Volver a postular, Ud. podrá generar una nueva
          postulación y la actual postulación quedará no vigente. <span class="dn-desktop"><br></span> ¿Está seguro de volver a postular?</p>
      </div>
      <div class="modal-footer text-center">
        <button type="button" class="btn btn-duoc btn-duoc-outline" data-dismiss="modal"
          (click)="clickRepostular()">Si</button>
        <button type="button" class="btn btn-duoc btn-duoc-success" data-dismiss="modal">No</button>
      </div>
    </div>
  </div>
</div>



