import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Login } from 'src/app/models/login';
import { ServicesService } from 'src/app/services/services.service';

@Component({
  selector: 'app-view-terminos-legales',
  templateUrl: './view-terminos-legales.component.html',
  styleUrls: ['./view-terminos-legales.component.css']
})
export class ViewTerminosLegalesComponent implements OnInit {

  titulo: string = "titulo";
  texto: string = "texto";
  error: string = "";
  terminosLegales:boolean = false;
  login: Login = new Login();

  constructor(
    private servicios: ServicesService,
    private router: Router
  ) { 
    this.login = this.servicios.getLogin();
    this.servicios.getTextos('TERMINOS_LEGALES').subscribe(
      (respuesta) => {
        let objeto = respuesta[0];
        this.titulo = objeto.titulo;
        this.texto = objeto.texto;
      }
    );

    const routerData = this.router.getCurrentNavigation()?.extras?.state?.['data']
    if ( routerData?.infoModal ) {
      window.document.getElementById('modal-ayuda-organizar-btn')?.click();
    }
  }

  ngOnInit(): void {
    // This is intentional
  }

  clickAceptaLegales():void {
    this.servicios.postAceptaLegales(this.login.email).subscribe(
      (estado) => {
        if(estado.valor) {
          window.location.href = '/ficha-pre-inscripcion/';
        } else {
          this.error = estado.error;
        }
      }
    );
  }

}
