<div class="container container-duoc mb-4">
  <div class="row mt-4">
    <div class="col-md-4 col-xs-12 dn-mobile">
      <div class="position-relative">
        <div class="custom-item-border-100 duoc-logo-container mb-2">
        </div>
      </div>
    </div>
    <div class="col-md-8 col-xs-12">
      <div class="containers">
        <p class="font-lato">EDUCACION CONTINUA</p>
        <h1 class="font-merriweather-bold mb-2">Recuperar contraseña</h1>

        <div>
          <div class="form-group">
            <label for="tmpPassword">Contraseña provisoria <span class="text-danger">(*)</span></label>
            <input type="text" class="form-control" id="tmpPassword" aria-describedby="Contraseña provisoria" maxlength="8" name="tmpPassword"
              placeholder="Contraseña provisoria" required [(ngModel)]="passwordProvisoria" (ngModelChange)="error = ''">
          </div>

          <div>
            <label for="newPassword">Nueva contraseña <span class="text-danger">(*)</span></label>
            <div class="form-group custom-input-with-icon input-group" style="margin-bottom: 0;">
              <input [type]="showPassword ? 'text' : 'password'" class="form-control" id="newPassword" aria-describedby="Nueva contraseña" maxlength="8"
                placeholder="Nueva contraseña" required [(ngModel)]="password" [ngClass] = "{ 'inputError': errorPassword }" (ngModelChange)="validarPassword(password, true)">
                <span (click)="viewPassword()" class="input-group-text"><em [ngClass]="showPassword ? 'bi-eye' : 'bi-eye-slash'" id="togglePassword"></em></span>
            </div>
            <div style="margin-bottom: 10px;" *ngIf="errorPassword">
              <span style="color: #dc3545;">La contraseña debe tener mínimo 6 carácteres</span>
            </div>
            <div class="col-lg-12" style="margin: 0px; padding: 0px;">
              <p style="font-size: 12px;">La contraseña debe tener largo minimo 6 y máximo 8 caracteres, con letras,
                números y caracter especial.</p>
            </div>
          </div>

          <div>
            <label for="confirmPassword">Confirmar nueva contraseña <span class="text-danger">(*)</span></label>
            <div class="form-group custom-input-with-icon input-group" style="margin-bottom: 0;">
              <input [type]="showComfirPassword ? 'text' : 'password'" class="form-control" id="confirmPassword" aria-describedby="Contraseña provisoria" maxlength="8"
                placeholder="Confirmar nueva contraseña" required [(ngModel)]="confirmarPassword" [ngClass] = "{ 'inputError': errorPasswordConfirmar }" (ngModelChange)="validarPassword(confirmarPassword, false)">
                <span (click)="viewConfirmPassword()" class="input-group-text"><em [ngClass]="showComfirPassword ? 'bi-eye' : 'bi-eye-slash'" id="togglePassword"></em></span>

              </div>
            </div>
            <div style="margin-bottom: 10px;" *ngIf="errorPasswordConfirmar">
              <span style="color: #dc3545;">La contraseña debe tener mínimo 6 carácteres</span>
            </div>
          <div *ngIf="error!='' && !condicionExito" class="mensaje-alerta col-md-12 col-xs-12 alert alert-danger" style="margin-top: 16px;">
            <em class="bi bi-x-circle icono-alerta"></em>
            <div role="alert" [innerHTML]="error">
            </div>
          </div>
          <div *ngIf="condicionExito" class="col-md-12 col-xs-12 alert alert-success mensaje-alerta">
            <em class='bi bi-check2-circle icono-alerta'></em>
            <div role="alert" [innerHTML]="error"></div>
          </div>
          <div class="text-center mt-4 dn-mobile">
            <button style="padding-left: 5rem; padding-right: 5rem;" type="submit" class="btn btn-duoc btn-duoc-success btn-aceptar" (click)="requestNewPassword()">
              Aceptar
            </button>
          </div>

          <div class="text-center mt-4 dn-desktop">
            <button type="submit" class="btn btn-duoc btn-duoc-success btn-w-full" (click)="requestNewPassword();">
              Aceptar
            </button>
          </div>
        </div>

      </div>

    </div>
    <div class="col-md-2 col-xs-none"></div>
  </div>
</div>
