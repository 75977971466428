<app-header-pasos [paso]=4 [estado]="true" [total]="5" [paso_texto]="'PAGO'" [paso_texto_siguiente]="'INSCRIPCIÓN REALIZADA'"></app-header-pasos>


<div class="container container-duoc">
  <section class="mt-4 mb-4">
    <!-- ¡Bienvenido a <br> Educación Continua Duoc UC! -->
    <h1 class="text-center" style="font-size: 40px;" [innerHTML]="textosPantalla.titulo"></h1>
  </section>
  <div class="col-12 mt-2 text-ins">
    <p class="font-lato" [innerHTML]="textosPantalla.texto1"></p>
  </div>

  <!-- Desktop -->
  <div class="dn-mobile">
    <div class="custom-card-gray mt-2">
      <div class="custom-card-gray-content position-relative">
        <div class="custom-item-border-75 pl-4">
          <div class="row">
            <div class="col-md-6">
              <div class="custom-card-gray-title">
                <h5 class="font-lato-bold text-uppercase">{{resumenInscripcion.oferta.tipo}}</h5>
                <h2 class="font-lato-bold">{{resumenInscripcion.oferta.nombre}}</h2>
              </div>
            </div>
            <div class="col-md-6">
              <div class="custom-card-gray-content-info">
                <p>Modalidad: <strong class="custom-bold">{{resumenInscripcion.oferta.modalidad}}</strong></p>
                <p>Fecha de inicio: <strong class="custom-bold">{{resumenInscripcion.oferta.fechaInicio}}</strong></p>
                <p>Fecha de término: <strong class="custom-bold">{{resumenInscripcion.oferta.fechaTermino}}</strong></p>
                <p>Días de clases: <strong class="custom-bold">{{resumenInscripcion.oferta.diasClases}}</strong></p>
                <p>Horario: <strong class="custom-bold">{{resumenInscripcion.oferta.horario}}</strong></p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Mobile -->
  <div class="dn-desktop">
    <div class="custom-card-gray mt-2">
      <div class="custom-card-gray-content position-relative">
        <div class="custom-item-border-75 pl-4">
          <div class="row">
            <div class="col-10">
              <div class="custom-card-gray-title">
                <h5 class="font-lato-bold text-uppercase">{{resumenInscripcion.oferta.tipo}}</h5>
                <h2 class="font-lato-bold">{{resumenInscripcion.oferta.nombre}}</h2>
              </div>
            </div>
            <div class="col-2">
              <em id="custom-card-gray-collapse-btn" class="bi bi-plus-circle custom-card-gray-collapse-btn" (click)="showMore()"></em>
              <em id="custom-card-gray-collapse-btn-hidden" class="bi bi-dash-circle d-none custom-card-gray-collapse-btn" (click)="showLess()"></em>
            </div>
            <div id="custom-card-gray-expand-content" class="col-md-12 d-none custom-card-gray-expand-hidden">
              <div class="custom-card-gray-content-info">
                <p>Modalidad: <strong class="custom-bold">{{resumenInscripcion.oferta.modalidad}}</strong></p>
                <p>Fecha de inicio: <strong class="custom-bold">{{resumenInscripcion.oferta.fechaInicio}}</strong></p>
                <p>Fecha de término: <strong class="custom-bold">{{resumenInscripcion.oferta.fechaTermino}}</strong></p>
                <p>Días de clases: <strong class="custom-bold">{{resumenInscripcion.oferta.diasClases}}</strong></p>
                <p>Horario: <strong class="custom-bold">{{resumenInscripcion.oferta.horario}}</strong></p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="custom-card-price">
    <p>Valor del programa: <strong class="custom-bold text-white">${{ resumenInscripcion.oferta.valorPrograma | number:'':'es-CL'
        }}</strong></p>
  </div>

  <div class="mt-5-5"></div>
  <div class="col-12 mt-4 text-ins">
      <p class="font-lato text-alineacion mensaje-desktop" [innerHTML]="textosPantalla.texto2"></p>
      <div class="mensaje-mobile">
        <p class="font-lato text-alineacion mb-4" [innerHTML]="textosPantalla.texto2"></p>
      </div>
  </div>

  <!-- Desktop -->
  <div class="dn-mobile">
    <div class="col-12 mt-4 text-ins">
      <p class="font-lato">Educación Continua Duoc UC</p>
      <p class="font-lato">Id de reserva: {{resumenInscripcion.idReserva}}</p>
    </div>
  </div>

  <!-- Mobile -->
  <div class="dn-desktop">
    <div class="col-12 mt-4 text-ins">
      <p class="font-lato">Educación Continua Duoc UC</p>
      <p class="font-lato"><strong>Id de reserva:</strong> {{resumenInscripcion.idReserva}}</p>
    </div>
 </div>

<div class="mt-4"></div>
  <div class="text-center col-12">
    <button type="button" class="btn btn-duoc btn-duoc-outline mr-1" (click)="clickCerrarSesion()">
      Ir al Home
    </button>
  </div>
</div>
