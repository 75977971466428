import { Mensaje } from "./mensaje";
import { Oferta } from "./oferta";

export class ResumenInscripcion {
    /** Atributos de Clase */
    valor: boolean = false;
    idReserva: string = "";
    oferta: Oferta = new Oferta();
    mensajes: Mensaje[] = [];
    links: Mensaje[] = [];
    error: Error = new Error();
}
