import { Error } from "./error";
import { Oferta } from "./oferta";

export class InfoPago {

    minutosRestantes: number = 0;
    valor: number = 0;
    descuento: number = 0;
    total: number = 0;
    error: Error = new Error();
    oferta: Oferta = new Oferta();
    fechaExpiracion: string = "";
    horaExpiracion: string = "";
    montoEmpresa: number = 0;
    montoParticular: number = 0;
    financiamiento?: string = "";
}
