<app-header-pasos [paso]=4 [estado]="false" [total]="5" [paso_texto]="'PAGO'" [paso_texto_siguiente]="'INSCRIPCIÓN REALIZADA'"></app-header-pasos>

<div class="container container-duoc mb-4">
  <section class="mt-4 mb-4">
    <p class="text-center font-lato">EDUCACIÓN CONTINUA</p>
    <h1 class="text-center">{{ tituloPagina }}</h1>
  </section>

  <div class="custom-card-border mt-5 mb-4">
    <p class="font-lato pt-2 pb-2 pr-1 pl-4">
      ¡Tienes un cupo reservado! <br>
      La reserva de tu cupo estará vigente hasta el <strong class="font-lato-bold">{{ infoPago?.fechaExpiracion ? infoPago?.fechaExpiracion : '...' }}</strong> a
      las <strong class="font-lato-bold">{{ infoPago?.horaExpiracion ? infoPago?.horaExpiracion : '...' }} hrs</strong>.
    </p>
  </div>

  <!-- Desktop -->
  <div class="dn-mobile" *ngIf="infoPago?.oferta">
    <div class="custom-card-gray mt-2">
      <div class="custom-card-gray-content position-relative">
        <div class="custom-item-border-75 pl-4">
          <div class="row">
            <div class="col-md-6">
              <div class="custom-card-gray-title">
                <h5 class="font-lato-bold text-uppercase">{{ infoPago?.oferta?.tipo }}</h5>
                <h2 class="font-lato-bold text-uppercase">{{ infoPago?.oferta?.nombre }}</h2>
              </div>
            </div>
            <div class="col-md-6">
              <div class="custom-card-gray-content-info">
                <p>Modalidad: <strong class="custom-bold">{{ infoPago?.oferta?.modalidad }}</strong></p>
                <p>Fecha de inicio: <strong class="custom-bold">{{ infoPago?.oferta?.fechaInicio }}</strong></p>
                <p>Fecha de término: <strong class="custom-bold">{{ infoPago?.oferta?.fechaTermino }}</strong></p>
                <p>Días de clases: <strong class="custom-bold">{{ infoPago?.oferta?.diasClases }}</strong></p>
                <p>Horario: <strong class="custom-bold">{{ infoPago?.oferta?.horario }}</strong></p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Mobile -->
  <div class="dn-desktop">
    <div class="custom-card-gray mt-2">
      <div class="custom-card-gray-content position-relative">
        <div class="custom-item-border-75 pl-4">
          <div class="row">
            <div class="col-10">
              <div class="custom-card-gray-title">
                <h5 class="font-lato-bold text-uppercase">{{ infoPago?.oferta?.tipo }}</h5>
                <h2 class="font-lato-bold">{{ infoPago?.oferta?.nombre }}</h2>
              </div>
            </div>
            <div class="col-2">
              <em id="custom-card-gray-collapse-btn" class="bi bi-plus-circle custom-card-gray-collapse-btn" (click)="_contenidoService.showMore()"></em>
              <em id="custom-card-gray-collapse-btn-hidden" class="bi bi-dash-circle d-none custom-card-gray-collapse-btn" (click)="_contenidoService.showLess()"></em>
            </div>
            <div id="custom-card-gray-expand-content" class="col-md-12 d-none custom-card-gray-expand-hidden">
              <div class="custom-card-gray-content-info">
                <p>Modalidad: <strong class="custom-bold">{{ infoPago?.oferta?.modalidad }}</strong></p>
                <p>Fecha de inicio: <strong class="custom-bold">{{ infoPago?.oferta?.fechaInicio }}</strong></p>
                <p>Fecha de término: <strong class="custom-bold">{{ infoPago?.oferta?.fechaTermino }}</strong></p>
                <p>Días de clases: <strong class="custom-bold">{{ infoPago?.oferta?.diasClases }}</strong></p>
                <p>Horario: <strong class="custom-bold">{{ infoPago?.oferta?.horario }}</strong></p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="mt-4 text-pago">
    <p [innerHTML]="textoInformativo"></p>
  </div>

  <ng-template [ngIf]="estado != 'PENDIENTE_BACKOFFICE'">
  <div class="mt-4">
    <h5 class="font-lato-bold text-uppercase">Información del pago</h5>

    <div class="mt-3 mb-4">

      <div class="valores-container dn-mobile">
        <div class="valores-info-container">
          <p>Valor del programa:&nbsp;&nbsp;&nbsp;</p>
          <h5>${{infoPago?.valor | number}}</h5>
        </div>
        <div class="separador"></div>
        <div class="valores-info-container">
          <p>Descuento:&nbsp;&nbsp;&nbsp;</p>
          <h5>${{infoPago?.descuento | number}}</h5>
        </div>
        <div class="separador"></div>
        <div class="valores-info-container">
          <p><strong>Monto a pagar:&nbsp;&nbsp;&nbsp;</strong> </p>
          <h5>${{infoPago?.total | number}}</h5>
        </div>
      </div>
    </div>

    <div [innerHTML]="textoInfoOt" style="text-align: justify;"></div>

    <p *ngIf="!estadoValidacionOc" class="mt-4 mb-3">Si ya cuentas con la OC adjúntala a continuación:</p>

    <div *ngIf="!estadoValidacionOc" class="row mb-3 cstm-row-height">
      <div class="col-md-4" style="padding: 0;">
        <input type="file" name="" [id]="'file-0'" class="d-none" (change)="onFileChanged($event, 0)" onclick="this.value=null;">
        <button [disabled]="estadoValidacionOc" type="button" class="btn btn-dark btn-file-upload" (click)="clickFileUpload('file-0')">
          Seleccionar archivo
        </button>
      </div>
      <div class="col-md-8 padding-mobile">
        <p class="d-inline text-break">{{ filesAttached[0]?.name ? filesAttached[0]?.name : 'Sin archivos seleccionado' }}</p>
        <div class="d-inline" *ngIf="filesAttached[0]?.name && !estadoValidacionOc">
          <strong><i class="bi bi-trash text-danger cstm-icon-trash" (click)="removeFile(0)"></i></strong>
        </div>
      </div>

      <!-- segundo archivo -->
      <div class="col-md-4 mt-4" style="padding: 0;">
        <input type="file" name="" [id]="'file-1'" class="d-none" (change)="onFileChanged($event, 1)" onclick="this.value=null;">
        <button [disabled]="estadoValidacionOc" type="button" class="btn btn-dark btn-file-upload" (click)="clickFileUpload('file-1')">
          Seleccionar archivo
        </button>
      </div>
      <div class="col-md-8 mt-4 padding-mobile">
        <p class="d-inline text-break">{{ filesAttached[1]?.name ? filesAttached[1]?.name : 'Sin archivos seleccionado' }}</p>
        <div class="d-inline" *ngIf="filesAttached[1]?.name && !estadoValidacionOc">
          <strong><i class="bi bi-trash text-danger cstm-icon-trash" (click)="removeFile(1)"></i></strong>
        </div>
      </div>

    </div>

    <div *ngIf="errorTextFiles != ''" class="col-md-12 col-xs-12 mt-1 alert alert-danger mensaje-alerta">
      <em class='bi bi-x-circle icono-alerta'></em>
      <div role="alert" [innerHTML]="errorTextFiles"></div>
    </div>

    <p class="mt-5">Si necesitas ayuda, contáctanos al (56) 442 201 096</p>

  </div>

  <div *ngIf="error!=''" class="col-md-12 col-xs-12 mt-3 mb-2 alert alert-danger mensaje-alerta">
    <em class='bi bi-x-circle icono-alerta'></em>
    <div role="alert" [innerHTML]="error"></div>
  </div>

  <ng-template [ngIf]="estadoVacantes">

  <div class="text-center mt-2 pt-4 dn-mobile">
    <button [disabled]="estadoValidacionOc" *ngIf="!cursoFueraPlazo" type="button" data-toggle="modal" data-target="#modalVolverPostular"
      class="btn btn-duoc btn-duoc-outline mr-1">
      Volver a postular
    </button>


    <button (click)="continuar()" *ngIf="!cursoFueraPlazo" class="btn btn-duoc btn-duoc-success" [disabled]="estadoValidacionOc">Continuar</button>

    <button *ngIf="cursoFueraPlazo" type="button" data-toggle="modal" data-target="#modalVolverPostular"
      class="btn btn-duoc btn-duoc-success">
      Volver a postular
    </button>

    <button *ngIf="cursoFueraPlazo" class="btn btn-duoc btn-duoc-success" [disabled]="cursoFueraPlazo">Continuar</button>


  </div>

  <div class="text-center mt-4 dn-desktop">
    <button (click)="continuar()" *ngIf="!cursoFueraPlazo"
      class="btn btn-duoc btn-duoc-success btn-w-260" [disabled]="estadoValidacionOc ">Continuar</button>

      <button (click)="continuar()" *ngIf="cursoFueraPlazo"
      class="btn btn-duoc btn-duoc-success btn-w-260" [disabled]="cursoFueraPlazo ">Continuar</button>

    <button [disabled]="estadoValidacionOc" *ngIf="!cursoFueraPlazo" type="button" data-toggle="modal" data-target="#modalVolverPostular"
      class="btn btn-duoc btn-duoc-outline mt-2 btn-w-260">
      Volver a postular
    </button>

    <button *ngIf="cursoFueraPlazo" type="button" data-toggle="modal" data-target="#modalVolverPostular"
      class="btn btn-duoc btn-duoc-outline mt-2 btn-w-260">
      Volver a postular
    </button>
  </div>

  </ng-template>

  <ng-template [ngIf]="!estadoVacantes">

    <div class="text-center mt-2 pt-4 dn-mobile">
      <button   type="button" data-toggle="modal" data-target="#modalVolverPostular"
        class="btn btn-duoc btn-duoc-success mr-1">
        Volver a postular
      </button>


      <button  class="btn btn-duoc btn-duoc-success" [disabled]="true">Continuar</button>



    </div>

    <div class="text-center mt-4 dn-desktop">
      <button class="btn btn-duoc btn-duoc-success btn-w-260" [disabled]="true">Continuar</button>


      <button type="button" data-toggle="modal" data-target="#modalVolverPostular"
        class="btn btn-duoc btn-duoc-success mt-2 btn-w-260">
        Volver a postular
      </button>

    </div>


  </ng-template>



  </ng-template>



  <ng-template [ngIf]="estado == 'PENDIENTE_BACKOFFICE'">
    <div class="mt-4">
      <h5 class="font-lato-bold text-uppercase">ATENCIÓN</h5>
      <div class="mt-4 text-pago">
        <p [innerHTML]="textoInfoOt"></p>
      </div>
    </div>





  <div *ngIf="error!=''" class="col-md-12 col-xs-12 mt-3 mb-2 alert alert-danger mensaje-alerta">
    <em class='bi bi-x-circle icono-alerta'></em>
    <div role="alert" [innerHTML]="error"></div>
  </div>

  <ng-template [ngIf]="estadoVacantes">
  <div class="text-center mt-2 pt-4 dn-mobile">

    <button [disabled]="botonContinuarMixto" *ngIf="!cursoFueraPlazo" type="button" data-toggle="modal" data-target="#modalVolverPostular"
      class="btn  btn-duoc-success btn-duoc-outline mr-1">
      Volver a postular
    </button>

    <button  *ngIf="cursoFueraPlazo" type="button" data-toggle="modal" data-target="#modalVolverPostular"
      class="btn btn-duoc-success btn-duoc-outline mr-1">
      Volver a postular
    </button>



    <button (click)="continuar_pago_mixto()" *ngIf="!cursoFueraPlazo" class="btn btn-duoc btn-duoc-success" [disabled]="botonContinuarMixto">Continuar</button>



    <button *ngIf="cursoFueraPlazo" class="btn btn-duoc btn-duoc-success" [disabled]="cursoFueraPlazo">Continuar</button>


  </div>

  <div class="text-center mt-4 dn-desktop">


      <button (click)="continuar_pago_mixto()" *ngIf="!cursoFueraPlazo"
      class="btn btn-duoc btn-duoc-success btn-w-260" [disabled]="botonContinuarMixto">Continuar</button>

      <button *ngIf="cursoFueraPlazo"
      class="btn btn-duoc btn-duoc-success btn-w-260" [disabled]="cursoFueraPlazo ">Continuar</button>

    <button [disabled]="botonContinuarMixto" type="button" data-toggle="modal" data-target="#modalVolverPostular"
      class="btn btn-duoc btn-duoc-outline mt-2 btn-w-260">
      Volver a postular
    </button>


  </div>
</ng-template>

  <ng-template [ngIf]="!estadoVacantes">
    
        <div class="text-center mt-2 pt-4 dn-mobile">
          <button   type="button" data-toggle="modal" data-target="#modalVolverPostular"
            class="btn btn-duoc btn-duoc-success mr-1">
            Volver a postular
          </button>


          <button  class="btn btn-duoc btn-duoc-success" [disabled]="true">Continuar</button>



        </div>

        <div class="text-center mt-4 dn-desktop">
          <button class="btn btn-duoc btn-duoc-success btn-w-260" [disabled]="true">Continuar</button>


          <button type="button" data-toggle="modal" data-target="#modalVolverPostular"
            class="btn btn-duoc btn-duoc-success mt-2 btn-w-260">
            Volver a postular
          </button>

        </div>


      </ng-template>



  </ng-template>


</div>

<app-text-info-modal [title]="tituloPopup" [innerHtmlText]="infoModalText"></app-text-info-modal>

<!-- Modal Volver a Postular -->
<!-- Modal -->
<div class="modal fade" id="modalVolverPostular" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
  aria-hidden="true">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h2 class="modal-title" id="exampleModalLabel">Aviso</h2>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true" class="span-modal">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <p class="font-lato text-justify text-center-mobile">Al presionar el botón Volver a postular, Ud. podrá generar una nueva
          postulación y la actual postulación quedará no vigente. <span class="dn-desktop"><br></span> ¿Está seguro de volver a postular?</p>
      </div>
      <div class="modal-footer text-center">
        <button type="button" class="btn btn-duoc btn-duoc-outline" data-dismiss="modal"
          (click)="clickRepostular()">Si</button>
        <button type="button" class="btn btn-duoc btn-duoc-success" data-dismiss="modal">No</button>
      </div>
    </div>
  </div>
</div>

<!-- Modal aviso -->
<button type="button" id="modalAvisoBtn" class="btn btn-primary d-none" data-toggle="modal" data-target="#modalAviso">
  Launch modal
</button>
<div class="modal fade" id="modalAviso" tabindex="-1" role="dialog" aria-labelledby="modalAviso"
  aria-hidden="true">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h2 class="modal-title" id="exampleModalLabel">Importante</h2>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true" class="span-modal">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <p class="font-lato text-justify text-center-mobile">
          Hemos recibido la información respecto al pago de tu programa, serás contactado por una de nuestras ejecutivas comerciales.
        </p>
      </div>
      <div class="modal-footer text-center">
        <button (click)="btnHome()" class="btn btn-duoc btn-duoc-outline">Ir al home</button>
      </div>
    </div>
  </div>
</div>




<!-- Modal aviso Pago Mixo-->
<button type="button" id="modalAvisoMixtoBtn" class="btn btn-primary d-none" data-toggle="modal" data-target="#modalAvisoMixto">
  Launch modal
</button>
<div class="modal fade" id="modalAvisoMixto" tabindex="-1" role="dialog" aria-labelledby="modalAviso"
  aria-hidden="true">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h2 class="modal-title" id="exampleModalLabel">Importante</h2>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true" class="span-modal">&times;</span>
        </button>
      </div>

      <div class="modal-body" >
        <p class="font-lato text-justify text-center-mobile">
          Agradecemos tu participación.<br>
          Ahora, aguarda el contacto de nuestros ejecutivos comerciales para dar seguimiento a la inscripción completa de tu postulación. ¡Gracias!
        </p>
      </div>

      <div class="modal-footer text-center">
        <button (click)="btnHome()" class="btn btn-duoc btn-duoc-outline">Ir al home (DUOC.CL)</button>
      </div>
    </div>
  </div>
</div>



<!-- Modal en validacion OC -->
<button type="button" id="modalValidacionOcBtn" class="btn btn-primary d-none" data-toggle="modal" data-target="#modalValidacionOc">
  Launch modal
</button>
<div class="modal fade" id="modalValidacionOc" tabindex="-1" role="dialog" aria-labelledby="modalValidacionOc"
  aria-hidden="true">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h2 class="modal-title" id="exampleModalLabel">Importante</h2>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true" class="span-modal">&times;</span>
        </button>
      </div>
      <div class="modal-body">

        <div class="font-lato text-justify text-center-mobile" [innerHTML]="mensajeAlerta"></div>
      </div>
      <div class="modal-footer text-center">
        <button *ngIf="estadoVacantes" (click)="btnHome()" class="btn btn-duoc btn-duoc-outline">Ir al home</button>

        <button *ngIf="!estadoVacantes" class="btn btn-duoc btn-duoc-outline" data-dismiss="modal" aria-label="Close">Aceptar</button>


      </div>
    </div>
  </div>
</div>
