<div class="ESPACIO_TOP"></div>
<div class="container">
    <div class="row justify-content-md-center">
        <div class="col-lg-12">
            <div class="row justify-content-md-center">
                <div class="col-lg-12 SUBTITULOS-SITE text-center">
                    EDUCACIÓN CONTINUA
                </div>
                <div class="col-lg-12">
                    &nbsp;
                </div>
                <div class="col-lg-12 TITULOS-SITE text-center">
                    {{titulo}}
                </div>
                <div class="col-lg-12">&nbsp;</div>
            </div>
        </div>
        <div class="col-lg-3">
        </div>
    </div>

</div>
<div class="ESPACIO_BOTTOM"></div>