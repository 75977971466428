<div class="container container-duoc">
  <div *ngIf="cargando">
    Cargando email...
  </div>
  <div *ngIf="!cargando">
    <div *ngIf="!emailResponse.valor">
      Email no encontrado.
    </div>
    <div *ngIf="emailResponse.valor">
      <div [innerHTML]="email"></div>
    </div>
  </div>
</div>
