import { Component, OnInit, ViewChild } from '@angular/core';
import { Login } from 'src/app/models/login';
import { ServicesService } from 'src/app/services/services.service';
import { NgxSpinnerService } from "ngx-spinner";
import { ContentService } from 'src/app/services/content/content.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ViewHeaderComponent } from 'src/app/componentes/view-header/view-header.component';
import { Platform } from '@angular/cdk/platform';

@Component({
  selector: 'app-view-primer-paso',
  templateUrl: './view-primer-paso.component.html',
  styleUrls: ['./view-primer-paso.component.css'],
  providers: [ViewHeaderComponent]
})
export class ViewPrimerPasoComponent implements OnInit {

  @ViewChild('recaptcha') cap: any;

  titulo: string = "titulo";
  texto: string = "texto";
  error: string = "";
  login: Login = new Login();
  showPassword: boolean = false;
  token: string | undefined;
  idSap = 0

  constructor(
    private servicios: ServicesService,
    private spinner: NgxSpinnerService,
    private contentService: ContentService,
    private router: Router,
    private headerComponent: ViewHeaderComponent,
    private route: ActivatedRoute,
    private platform: Platform
  ) {

    

    this.token = undefined;
    this.spinner.show();
    this.servicios.getTextos('LOGIN').subscribe({
      next: (respuesta: any) => {
        let objeto = respuesta[0];
        this.titulo = objeto.titulo;
        this.texto = objeto.texto;
        this.spinner.hide()
      }, 
      error: (error) => {
        this.spinner.hide()
      }
  });

    this.route.queryParams.subscribe((param: any) => {
      if ( param.idSap ) {
        this.idSap = param.idSap
        console.log(this.idSap)
        localStorage.setItem('codigoCurso', this.idSap.toString());
      }
    })
  }

  getBrowserName(): string {
    const agent = window.navigator.userAgent.toLowerCase();
    let browser = 'other';
    
    switch (true) {
      case agent.indexOf('edge') > -1:
        browser = 'Microsoft Edge';
        break;
      case agent.indexOf('edg') > -1:
        browser = 'Chromium-based Edge';
        break;
      case agent.indexOf('opr') > -1:
        browser = 'Opera';
        break;
      case agent.indexOf('chrome') > -1:
        browser = 'Chrome';
        break;
      case agent.indexOf('trident') > -1:
        browser = 'Internet Explorer';
        break;
      case agent.indexOf('firefox') > -1:
        browser = 'Firefox';
        break;
      case agent.indexOf('safari') > -1:
        browser = 'Safari';
        break;
    }
    
    return browser;
  }
  

  ngOnInit(): void {
    

    

    if (Object.keys(this.route.snapshot.queryParams).length !== 0) {
      const queryParams = this.route.snapshot.queryParams;
      if (queryParams["logout"]) {
        localStorage.clear();
        window.location.href = "/view-login";
      }
    }
  }

  public send(): void {
    this.error = "";
    this.clickLoggin();
  }

  redirectByScreenName(screenName: string) {
    const urlByScreenName: Array<Object> = [
      {
        pantalla: 'VALIDACION_RUT',
        url: '/view-rut/'
      },
      {
        pantalla: 'TERMINOS_LEGALES',
        url: '/view-terminos-legales/'
      },
      {
        pantalla: 'FICHA_PREINSCRIPCION',
        url: '/ficha-pre-inscripcion/'
      },
      {
        pantalla: 'POSTULACION',
        url: '/postulacion/'
      },
      {
        pantalla: 'TERMINOS_CONDICIONES',
        url: '/terminos-y-condiciones/'
      },
      {
        pantalla: 'RESULTADO_POSTULACION',
        url: '/resultado-postulacion/'
      },
      {
        pantalla: 'PAGO',
        url: '/pago-en-linea/'
      },
      {
        pantalla: 'RESUMEN_POSTULACION_KIT',
        url: '/resumen-inscripcion-kit/'
      },
      {
        pantalla: 'RESUMEN_POSTULACION_KIT_SIN_KIT',
        url: '/resumen-inscripcion/'
      },
      {
        pantalla: 'GESTION_PAGO',
        url: '/gestion-pago/'
      }
      
    ]
    const currentScreen: any = urlByScreenName.find((screen: any) => screen.pantalla === screenName)
    if (currentScreen) {
      this.router.navigate([currentScreen.url], { state: { data: { infoModal: true } } })
    }
  }

  validaForm(): boolean {
    let continuar = true;
    const exp_password = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@.$!%*#?&])[A-Za-z\d@.$!%*#?&]{3,}$/;

    if (this.login.email === '' || this.login.password === '') {
      this.error = '<b class="font-merriweather-bold !important;">Los campos Email y Contraseña son obligatorios</b>';
      this.limpiarError();
      continuar = false;
    }
  
    if (this.login.password.length < 6 || this.login.password.length > 8) {
      this.error = '<b class="font-merriweather-bold !important;">La contraseña debe tener mínimo 6 y máximo 8 caracteres</b>';
      this.limpiarError();
      continuar = false;
    }
  
    if (!exp_password.exec(this.login.password)) {
      this.error = '<b class="font-merriweather-bold !important;">La contraseña debe tener mínimo un número, una letra y un caracter especial</b>';
      this.limpiarError();
      continuar = false;
    }
  
    if (!this.esEmailValido(this.login.email)) {
      this.error = '<b class="font-merriweather-bold !important;">El Email ingresado no es correcto</b>';
      this.limpiarError();
      continuar = false;
    }
  
    if (this.token === undefined || this.token === null || this.token === '') {
      this.error = '<b class="font-merriweather-bold !important;">Debe confirmar el captcha</b>';
      this.limpiarError();
      continuar = false;
    }

    return continuar;
  }

  clickLoggin(): void {
    this.error = '';
  
  
    if (this.validaForm()) {
  
    this.spinner.show();
    this.login.captcha = this.token ?? '';
    this.login.codigoCurso = Number(localStorage.getItem('codigoCurso'));
      this.getLogin();

    }
  }

  getLogin() {
    this.servicios.getLoggin(this.login).subscribe(
      (respuesta) => {
        if (!respuesta.correcto && respuesta.error != null) {
          if (respuesta.error.mensaje === 'Token reCAPTCHA invalido') {
            this.token = undefined;
            this.cap.reset();
          }
          this.error = respuesta.error.mensaje;
          setTimeout(() => {
            this.error = '';
          }, 3000);
          this.spinner.hide();
        } else {
          respuesta.email = this.login.email;
          localStorage.setItem('email', this.login.email);
          this.servicios.setLogin(respuesta);
          this.contentService.asyncUserData(respuesta);
          if (respuesta.cambiarPassword) {
            this.router.navigate(['/recuperar-contrasena-temp']);
            this.spinner.hide();
          } else {
            localStorage.removeItem('estado');
            this.servicios.postEstado(respuesta.email).subscribe(
              (estado) => {
                localStorage.setItem('estado', estado?.estado);
                localStorage.setItem('pantalla', estado?.pantalla);
                this.servicios.getTextos('AYUDA').subscribe({
                  next: (respuesta2) => {
                    this.servicios.setModal(respuesta2);
                    this.contentService.setInfoModal(respuesta2);
                    this.contentService.asyncModalInfo(respuesta2);
                    this.spinner.hide();
                    this.redirectByScreenName(estado?.pantalla);
                  },
                  error: (error) => {
                    this.spinner.hide();
                  }
                });
              }
            );
          }
        }
      }
    );
  }
  limpiarError(): void {
    const limpiarError = () => {
      this.error = "";
    }
    setTimeout(limpiarError,3000);
  }

  esEmailValido(email: string): boolean {
    let mailValido = false;
    let EMAIL_REGEX = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    if (EMAIL_REGEX.exec(email)) {
      mailValido = true;
    }
    return mailValido;
  }

  viewPassword() {
    this.showPassword = !this.showPassword;
  }

  keyBorrarErrores(): void {
    this.error = '';
  }

}
