import { Component, Input, OnInit, ElementRef } from '@angular/core';

import Chart from 'chart.js/auto';

import {
  ApexNonAxisChartSeries,
  ApexResponsive,
  ApexChart,
} from 'ng-apexcharts';
export type ChartOptions = {
  series: ApexNonAxisChartSeries;
  chart: ApexChart;
  responsive: ApexResponsive[];
  labels: any;
};


@Component({
  selector: 'app-header-pasos',
  templateUrl: './header-pasos.component.html',
  styleUrls: ['./header-pasos.component.css'],
})
export class HeaderPasosComponent implements OnInit {
  public anchoPagina: number = 0;
  public isMobile = false;

  public chart: any;

  @Input() total: number = 5;
  @Input() paso: number = 1;
  @Input() estado: boolean = false;

  @Input() rechazado: boolean = false;

  @Input() paso_texto: string = '';
  @Input() paso_texto_siguiente: string = '';

  @Input() graficas: any[] = [];

  /*
  @ViewChild('chart') chart: ChartComponent = {} as ChartComponent;
  public chartOptions: Partial<any> = {} as Partial<any>;
*/

  constructor(private elementRef: ElementRef) {
    this.anchoPagina = document.body.clientWidth;
  }

  createChart() {
    if (this.isMobile) {
      if (this.chart) {
        this.chart.destroy();
      }
     

     let info = this.configurarChart();
     let data = info.data;
     let colores = info.colores;

      setTimeout(() => {
        this.chart = new Chart('myChart', {
          type: 'doughnut', //this denotes tha type of chart
  
          data: {
            // values on X-Axis
            labels: [],
            datasets: [
              {
                label: '',
                data: data,
                backgroundColor: colores,
                hoverOffset: 4,
              },
            ],
          },
          options: {
            aspectRatio: 1,
          },
        });
      }, 100);
    } else if(this.chart) {
       this.chart.destroy();
    }
  }

  /**
   * Funcion para preparar colores según estados
   */
  configurarChart() {


    let colorEtapa: string = '#78b13f';
    if (this.rechazado) {
      colorEtapa = '#c43737';
    }

    let info =this.validaEstadoColores(colorEtapa);

    return info;
  }

  validaEstadoColores(colorEtapa:any):any {

    
    let colores:any = this.estado ? [colorEtapa, '#c5c5c5'] : ['#f9b803', '#c5c5c5'];
    let data:any = "[2, 8]"

    switch (this.paso) {
     
      case 2:
        if (this.estado) {
          data = [5, 8];
          colores = [colorEtapa, '#c5c5c5'];
        } else {
          data = [2, 2, 8];
          colores = [colorEtapa, '#f9b803', '#c5c5c5'];
        }


        break;

      case 3:
        if (this.estado) {
          data = [7, 4];
          colores = [colorEtapa, '#c5c5c5'];
        } else {
          data = [4, 2, 4];
          colores = [colorEtapa, '#f9b803', '#c5c5c5'];
        }


        break;

      case 4:
        if (this.estado) {
          data = [8, 2];
          colores = [colorEtapa, '#c5c5c5'];
        } else {
          data = [6, 2, 2];
          colores = [colorEtapa, '#f9b803', '#c5c5c5'];
        }


        break;

      case 5:
        if (this.estado) {
          data = [1];
          colores = [colorEtapa];
        } else {
          data = [8, 2, 0];
          colores = [colorEtapa, '#f9b803', '#c5c5c5'];
        }

        break;
    }

    colores = this.rechazado ? ['#78b13f', colorEtapa, '#c5c5c5'] : colores;

    let info = {
      data: data,
      colores: colores
    }
    return info;
  }

  
  getIsMobile(): boolean {
    const w = document.documentElement.clientWidth;
    const breakpoint = 767;
    if (w < breakpoint) {
      return true;
    } else {
      return false; 
    }
  }

  ngOnInit() {

    this.isMobile = this.getIsMobile();
    window.onresize = () => {
      this.isMobile = this.getIsMobile();
     
      this.createChart();
    };
    //posiciona la vista de la pagina al inicio de esta
    window.scrollTo(0, 0);
    console.log("postulacionRechazada", this.rechazado);
  }

  ngAfterViewInit() {
    this.createChart();
  }
}
