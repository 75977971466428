import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { environment } from "../../../environments/environment";
import { ServicesService } from '../services.service';

interface IPostulacion {
  email: string;
  codigoCurso: string;
}

@Injectable({
  providedIn: 'root'
})
export class PostulacionService {

  private eventSubject = new Subject<any>();


  constructor(
    private http: HttpClient,
    private servicios: ServicesService
  ) { }

  filterCursoDiplomado(nombre: string): Observable<any> {
    const url = `${environment.urlBase}/ofertaBuscar`
    return this.http.post(url, { nombre })
  }

  // Pendiente interface
  save(postulacion: any): Observable<any> {
    const url = `${environment.urlBase}/guardarPostulacion`
    return this.http.post(url, postulacion)
  }

  // Pendiente interface
  postular(postulacion: any): Observable<any> {
    const url = `${environment.urlBase}/postular`
    return this.http.post(url, postulacion)
  }

  aceptaTyC(email: string): Observable<any> {
    const url = `${environment.urlBase}/aceptaTerminosCondiciones`
    return this.http.post(url, {email})
  }

  repostular(email: string): Observable<any> {
    const url = `${environment.urlBase}/repostular`
    return this.http.post(url, {email})
  }

  ofertaSeleccionada(): Observable<any> {
    const url = `${environment.urlBase}/ofertaSeleccionada`
    return this.http.get(url)
  }

  detalleOferta(idSap: number): Observable<any> {
    const rut = this.servicios.getLogin().rut
    const url = `${environment.urlBase}/oferta/detalle?idSap=${idSap}&rut=${rut}`
    return this.http.get(url)
  } 

  emitEvent(data: any) {
    this.eventSubject.next(data);
  }

  getEvent() {
    return this.eventSubject.asObservable();
  }

  eliminarOferta(): Observable<any> {
    const url = `${environment.urlBase}/validarOfertaPostulacion`;
    return this.http.get(url);
  } 

}
