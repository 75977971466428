import { Mensaje } from "./mensaje";
import { Oferta } from "./oferta";

export class PostulacionResultado {
    valor: boolean = false;
    estado: string = "";
    oferta: Oferta = new Oferta();
    motivoRechazo: string = "";
    mensajes: Mensaje[] = [];
    error: Error = new Error();
}
