<div class="container container-duoc mb-4">
  <div class="row mt-4">
    <div class="col-md-4 col-xs-12 dn-mobile">
      <div class="position-relative">
        <div class="custom-item-border-100 duoc-logo-container mb-2">
        </div>
      </div>
    </div>
    <div class="col-md-8 col-xs-12">
      <div class="containers">
        <p class="font-lato">EDUCACION CONTINUA</p>
        <h1 class="font-merriweather-bold text-break" [innerHTML]="contenido.titulo"></h1>
        <div class="custom-card-gray mb-2">
          <p class="p-1 pr-3 pl-3 text-break" [innerHTML]="contenido.texto"></p>
        </div>

        <form [formGroup]="formPassRecover">
          <div class="form-group">
            <label for="email">Email</label>
            <input type="email" class="form-control" id="email" aria-describedby="Email" maxlength="50" name="email"
                   formControlName="email"
                   placeholder="Email" [(ngModel)]="email" (ngModelChange)="error =''; condicionExito = false">
          </div>

          <div *ngIf="error!='' && !condicionExito" class="col-md-12 col-xs-12 alert alert-danger mensaje-alerta">
            <em class='bi bi-x-circle icono-alerta'></em>
            <div role="alert" [innerHTML]="error"></div>
          </div>
          <div *ngIf="condicionExito" class="col-md-12 col-xs-12 alert alert-success mensaje-alerta">
            <em class='bi bi-check2-circle icono-alerta'></em>
            <div role="alert" [innerHTML]="error"></div>
          </div>

          <div class="text-center mt-4 dn-mobile">
            <button [routerLink]="['/']" type="button" class="btn btn-duoc btn-duoc-outline mr-1 btn-cancelar">
              Cancelar
            </button>
            <button type="submit" class="btn btn-duoc btn-duoc-success btn-aceptar" (click)="requestNewPassword()"
                    [disabled]="email == ''">
              Recuperar contraseña
            </button>
          </div>

          <div class="text-center mt-4 dn-desktop">
            <button type="submit" class="btn btn-duoc btn-duoc-success btn-w-full" (click)="requestNewPassword()"
                    [disabled]="email == ''">
              Recuperar contraseña
            </button>

            <button [routerLink]="['/']" type="button" class="btn btn-duoc btn-duoc-outline mt-4 btn-w-full">
              Cancelar
            </button>
          </div>
        </form>

      </div>

    </div>
    <div class="col-md-2 col-xs-none"></div>
  </div>
</div>
