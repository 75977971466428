import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Login } from 'src/app/models/login';
import { ServicesService } from 'src/app/services/services.service';

@Component({
  selector: 'app-view-pre-inscripcion',
  templateUrl: './view-pre-inscripcion.component.html',
  styleUrls: ['./view-pre-inscripcion.component.css']
})
export class ViewPreInscripcionComponent implements OnInit {

  titulo: string = "titulo";
  texto: string = "texto";
  error: string = "";
  terminosLegales:boolean = false;
  login: Login = new Login();

  constructor(
    private servicios: ServicesService,
    private router: Router
  ) {
    this.login = this.servicios.getLogin();
    console.log("login", this.login);
    
    this.servicios.getTextos('FICHA_PREINSCRIPCION').subscribe(
      (respuesta) => {
        console.log("respuesta", respuesta);

        let objeto = respuesta[0];
        this.titulo = objeto.titulo;
        this.texto = objeto.texto;
      }
    );
    
    const routerData = this.router.getCurrentNavigation()?.extras?.state?.['data']
    if ( routerData?.infoModal ) {
      window.document.getElementById('modal-ayuda-organizar-btn')?.click();
    }

   }

  ngOnInit(): void {
    // This is intentional
  }

}
