import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ResumenInscripcion } from 'src/app/models/resumen-inscripcion';
import { ServicesService } from 'src/app/services/services.service';

@Component({
  selector: 'app-resumen-inscripcion',
  templateUrl: './resumen-inscripcion.component.html',
  styleUrls: ['./resumen-inscripcion.component.css']
})
export class ResumenInscripcionComponent implements OnInit {

  /** Atributos de Clase */
  resumenInscripcion : ResumenInscripcion = new ResumenInscripcion;
  titulo : string = "";
  mensaje : string = ""; 
  mensajeMobile: string[] = [];

  textosPantalla = {
    titulo: '',
    texto1: '',
    texto2: ''
  }
  
  constructor(
    private servicio: ServicesService, 
    private router: Router,
    private spinner: NgxSpinnerService,
    ) {
    let login = this.servicio.getLogin();

    //Traer la información del resumen de inscripcion
    this.servicio.getResumenInscripcion(login.email,1).subscribe(
      (result) =>{
        console.log(result)
        this.resumenInscripcion = result;
        this.titulo = result.mensajes[0].titulo;
        this.mensaje =  result.mensajes[0].texto;
        let mensajeTmp = result.mensajes[0].texto;
        this.mensajeMobile = mensajeTmp.split('. ');
        this.mensajeMobile[0] = this.mensajeMobile[0].concat('.');
      }
    );

   }

  ngOnInit(): void {
    this.spinner.show()
    this.servicio.getTextos('RESUMEN_POSTULACION').subscribe({
      next: (respuesta) => {
        this.textosPantalla.titulo = respuesta[1].titulo
        this.textosPantalla.texto1 = respuesta[0].titulo
        this.textosPantalla.texto2 = respuesta[0].texto
        this.spinner.hide()
      }, error: (error) => {
        this.spinner.hide()
      }
  });
    this.crearKit();
  }

  clickCerrarSesion() {
    localStorage.clear();
    window.location.href = "https://www.duoc.cl/oferta-academica/cursos-diplomados/";
  }

  showMore() {
    window.document.getElementById('custom-card-gray-expand-content')?.classList.remove('d-none')
    window.document.getElementById('custom-card-gray-collapse-btn-hidden')?.classList.remove('d-none')
    window.document.getElementById('custom-card-gray-collapse-btn')?.classList.add('d-none')
  }

  showLess() {
    window.document.getElementById('custom-card-gray-collapse-btn')?.classList.remove('d-none')
    window.document.getElementById('custom-card-gray-collapse-btn-hidden')?.classList.add('d-none')
    window.document.getElementById('custom-card-gray-expand-content')?.classList.add('d-none')
  }

  crearKit(){
    setTimeout(() => {
      this.servicio.postCrearKit().subscribe(
        (result) => {
          console.log(result.codigoError);
          if(result.codigoError == 0){
            this.router.navigate(['/resumen-inscripcion-kit']);
          }
          else{
            this.crearKit();
          }
        }
      );
    }, 30000);
  }
}
