import { Component, Input } from '@angular/core';
import { PostulacionService } from 'src/app/services/postulacion/postulacion.service';

@Component({
  selector: 'app-text-info-modal',
  templateUrl: './text-info-modal.component.html',
  styleUrls: ['./text-info-modal.component.css']
})
export class TextInfoModalComponent {
  @Input() title = 'Titulo';
  @Input() innerHtmlText = 'Texto';
  @Input() id = 'text-info-modal';
  constructor(private PostulacionService: PostulacionService) {
  
  }

  clickAceptar() {
   
    this.PostulacionService.emitEvent({ mensaje: 'aceptar', texto: this.innerHtmlText, title: this.title });
  }

}
