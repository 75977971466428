import {HttpClient, HttpContext, HttpHeaders} from "@angular/common/http";
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { BooleanResponse } from "../models/boolean-response";
import { Comuna } from "../models/comuna";
import { EstadoCivil } from "../models/estado-civil";
import { Genero } from "../models/genero";
import { InfoPago } from "../models/info-pago";
import { Login } from "../models/login";
import { Nacionalidad } from "../models/nacionalidad";
import { Pais } from "../models/pais";
import { Pantallas } from '../models/pantallas';
import { PostulacionResultado } from "../models/postulacion-resultado";
import { Postulante } from "../models/postulante";
import { PreInscripcion } from "../models/pre-inscripcion";
import { Region } from "../models/region";
import { ResumenInscripcion } from "../models/resumen-inscripcion";
import {Parametro} from "../models/parametro";
import {BYPASS_LOG} from "./auth-interceptor.service";
import { DatosEmpresa } from "../models/datosEmpresa";

@Injectable({
  providedIn: 'root'
})
export class ServicesService {

  getHeaders(): HttpHeaders {
    return new HttpHeaders();
  }

  constructor(private http: HttpClient) { }

  public setToken(list: string) {
    this.setItem('token', list);
  }

  getToken(): string {
    const list = this.getItem('token');
    return list ?? "";
  }

  public setLogin(list: Login) {
    this.setItem('login', JSON.stringify(list));
  }

  getLogin(): Login {
    const list = this.getItem('login');
    return list == null ? new Login() : JSON.parse(list) as Login;
  }

  public setModal(paginaLista: Pantallas[]) {
    this.setItem('modal', JSON.stringify(paginaLista));
  }

  getModal(): Pantallas[] {
    const list = this.getItem('modal');
    return list == null ? [] : JSON.parse(list) as Pantallas[];
  }

  private getItem(item: string): string | null {
    const valor = localStorage.getItem(item);
    return valor != null ? atob(valor + '') : valor;
  }

  private setItem(item: string, valor: string) {
    localStorage.setItem(item, btoa(valor));
  }

  private excluirTokenRequest() {
    return { context: new HttpContext().set(BYPASS_LOG, true) }
  }

  /**
   * Servicio que registra la información de un Postulante
   * @param postulante          Objeto Postulante
   * @returns BooleanResponse   Objeto que retorna la respuesta del registro
   */
  postRegistrarPostulante(postulante: Postulante): Observable<BooleanResponse> {
    const url = `${environment.urlBase}/registrar`;
    return this.http.post<BooleanResponse>(url, postulante, this.excluirTokenRequest());
  }

  postValidarRut(rut: string, documento: string, rutExtranjero: boolean = false): Observable<any> {
    const url = `${environment.urlBase}/validaRut`;
    let bodyObject = {}
    if ( !rutExtranjero ) {
      bodyObject = {
        'email': this.getLogin().email,
        'user_rut': rut,
        'serial_number': documento,
        'rutExtranjero': rutExtranjero
      }
    } else {
      bodyObject = {
        'email': this.getLogin().email,
        'rutExtranjero': rutExtranjero
      }
    }
    return this.http.post<any>(url,
      bodyObject,
      { headers: this.getHeaders() });
  }

  postEstado(email: string): Observable<any> {
    const url = `${environment.urlBase}/estado`;
    return this.http.post<any>(url,
      {
        'email': email
      },
      { headers: this.getHeaders() });
  }

  actualizarPassword(body: {password: string, nuevaPassword: string}): Observable<BooleanResponse> {

    const url = `${environment.urlBase}/actualizar-password`;
    return this.http.put<BooleanResponse>(url,
      body,
      {headers: this.getHeaders()});
  }

  postAceptaLegales(email: string): Observable<any> {
    const url = `${environment.urlBase}/aceptaLegales`;
    return this.http.post<any>(url,
      {
        'email': email
      },
      { headers: this.getHeaders() });
  }
  postRecuperarContraseña(body: {email: string}): Observable<BooleanResponse> {
    const url = `${environment.urlBase}/recuperar-password`;
    return this.http.post<BooleanResponse>(url, body, this.excluirTokenRequest());
  }

  getTextos(pantalla: string): Observable<Pantallas[]> {
    const url = `${environment.urlBase}/contenido/pantalla/${pantalla}`;
    return this.http.get<Pantallas[]>(url, this.excluirTokenRequest());
  }

  getLoggin(login: Login): Observable<Login> {
    const url = `${environment.urlBase}/login`;
    return this.http.post<Login>(url, login, this.excluirTokenRequest());
  }

  setGuardaFichaPreInscripcion(obj: PreInscripcion): Observable<any> {
    const url = `${environment.urlBase}/guardarDatosPersonales`;
    return this.http.post<any>(url, obj, { headers: this.getHeaders() });
  }
  setGuardarDatosEmpresa(obj: DatosEmpresa): Observable<BooleanResponse> {
    const url = `${environment.urlBase}/postulacion/guardarDatosEmpresa`;
    return this.http.post<any>(url, obj, { headers: this.getHeaders() });

  }


  getPaises(): Observable<Pais[]> {
    const url = `${environment.urlBase}/pais/all/`;
    return this.http.get<Pais[]>(url, { headers: this.getHeaders() });
  }

  getNacionalidades(): Observable<Nacionalidad[]> {
    const url = `${environment.urlBase}/nacionalidad/all/`;
    return this.http.get<Nacionalidad[]>(url, { headers: this.getHeaders() });
  }

  getGeneros(): Observable<Genero[]> {
    const url = `${environment.urlBase}/genero/all/`;
    return this.http.get<Genero[]>(url, { headers: this.getHeaders() });
  }

  getRegiones(): Observable<Region[]> {
    const url = `${environment.urlBase}/region/all/`;
    return this.http.get<Region[]>(url, { headers: this.getHeaders() });
  }

  getComunas(idRegion:string): Observable<Comuna[]> {
    const url = `${environment.urlBase}/comuna/region/${idRegion}`;
    return this.http.get<Comuna[]>(url, { headers: this.getHeaders() });
  }

  getEstadosCiviles(): Observable<EstadoCivil[]> {
    const url = `${environment.urlBase}/estadocivil/all/`;
    return this.http.get<EstadoCivil[]>(url, { headers: this.getHeaders() });
  }

  getPostulacionResultado(email:string): Observable<PostulacionResultado> {
    const url = `${environment.urlBase}/postulacion/resultado?email=${email}`;
    return this.http.get<PostulacionResultado>(url, { headers: this.getHeaders() });
  }

  postRepostular(email: string): Observable<any> {
    const url = `${environment.urlBase}/repostular`;
    return this.http.post<any>(url,
      {
        'email': email
      },
      { headers: this.getHeaders() });
  }

  getInfoPago(): Observable<InfoPago> {
    const url = `${environment.urlBase}/infoPago`;
    return this.http.get<InfoPago>(url, { headers: this.getHeaders() });
  }

  getParametrosEdadesLimites(): Observable<any> {
    const url = `${environment.urlBase}/parametro/id?parametroId=1`;
    return this.http.get<any>(url, { headers: this.getHeaders() });

  }

  postInscripcion(): Observable<any> {
    const url = `${environment.urlBase}/inscripcion?origen=F`;
    return this.http.post<any>(url,{}, { headers: this.getHeaders() });
  }

  getResumenInscripcion(email:string,opcion:any): Observable<ResumenInscripcion> {
    const url = `${environment.urlBase}/postulacion/resumen?opcion=${opcion}&email=${email}`;
    return this.http.get<ResumenInscripcion>(url, { headers: this.getHeaders() });
  }

  postCrearKit(): Observable<any> {
    const url = `${environment.urlBase}/crear-kit`;
    return this.http.post<any>(url,{}, { headers: this.getHeaders() });
  }
  getEmail(idEmail: string): Observable<any> {
    const url = `${environment.urlBase}/ver-email/${idEmail}`;
    return this.http.get<any>(url, this.excluirTokenRequest());
  }
  getParametro(): Observable<Parametro> {
    const url = `${environment.urlBase}/parametro/parametros-iniciales`;
    return this.http.get<Parametro>(url, this.excluirTokenRequest());
  }

  getOcInfoPago(): Observable<Parametro> {
    const url = `${environment.urlBase}/infoPagoOC`;
    return this.http.get<any>(url, { headers: this.getHeaders() });
  }

  postGuardarArchivosOc(archivos?: any): Observable<any> {
    const url = `${environment.urlBase}/guardarArchivosOC`;
    return this.http.post<any>(url, archivos, { headers: this.getHeaders() });
  }

  getBotonContinuar(): Observable<any> {
    const url = `${environment.urlBase}/postulacion/botonContinuar`;
    return this.http.get<any>(url,  { headers: this.getHeaders() });
  }

  postBotonContinuar(): Observable<any> {
    const url = `${environment.urlBase}/postulacion/botonContinuar`;
    let data = {
      "botonContinuar": false
    }
    return this.http.put<any>(url,data,  { headers: this.getHeaders() });
  }
}
