import { Component, OnInit } from '@angular/core';
import { Pantallas } from 'src/app/models/pantallas';
import { ServicesService } from 'src/app/services/services.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-view-ayudas',
  templateUrl: './view-ayudas.component.html',
  styleUrls: ['./view-ayudas.component.css']
})
export class ViewAyudasComponent implements OnInit {

  textos: Pantallas[] = [];
  paginaLista: Pantallas[] = [];
  pagina: number = 1;
  urlAyuda: string = environment.urlAyuda

  constructor(private servicios: ServicesService) {
    // This is intentional
  }

  ngOnInit(): void {
    // This is intentional
  }

  
}