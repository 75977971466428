<app-header-pasos [paso]=2 [estado]="true" [total]="5" [paso_texto]="'POSTULACIÓN ENVIADA'" [paso_texto_siguiente]="'VALIDACIÓN POSTULACIÓN'"></app-header-pasos>



<div class="container">
  <section class="mt-4">
    <p class="text-center">EDUCACIÓN CONTINUA</p>
    <h1 class="text-center" [innerHTML]="titulo"></h1>
  </section>

  <div class="tyc-text text-justify mt-5">
    <div class="row">
      <div class="col-md-12">
        <div class="formattedTycText" [innerHTML]="tycText"></div>
      </div>
      <div class="col-12 text-center">
        <div class="form-check mb-4 mt-2" >
          <input class="form-check-input" type="checkbox" id="tyc-checkbox" [disabled]="checkAceptaTerminos" [(ngModel)]="accepted" >
          <label class="form-check-label" for="tyc-checkbox">
              Acepto los términos y condiciones
          </label>
      </div>
      
      </div>
    </div>
  </div>
</div>

<div class="tyc-sticky-btns text-center">
  <div class="row">
    <div class="col-12 mb-1">
      <strong class="font-lato-bold">Para continuar con tu inscripción, acepta los Términos y Condiciones</strong>
    </div>
    <div class="col-12 mt-2 dn-mobile">
      <button type="button" data-toggle="modal" data-target="#modalVolverPostular" class="btn btn-duoc btn-duoc-outline mr-1">
        Volver a postular
      </button>
      <button type="button" class="btn btn-duoc btn-duoc-success" data-toggle="modal" data-target="#modalTyc" [disabled]="!accepted">
        Aceptar
      </button>
    </div>

    <div class="col-12 mt-2 dn-desktop">
      <button type="button" class="btn btn-duoc btn-duoc-success btn-w-260" data-toggle="modal" data-target="#modalTyc" [disabled]="!accepted">
        Aceptar
      </button>
      <button type="button" data-toggle="modal" data-target="#modalVolverPostular" class="btn btn-duoc btn-duoc-outline btn-w-260 mt-2">
        Volver a postular
      </button>
    </div>
  </div>
</div>

<!-- Modal TyC -->
<div class="modal fade" id="modalTyc" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h2 class="modal-title" id="exampleModalLabel">Importante</h2>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <p class="font-lato text-justify tyc-accept-mobile">Al aceptar, declaras haber leído los Términos y Condiciones del servicio de Educación Continua Duoc UC</p>
      </div>
      <div class="modal-footer text-center dn-mobile">
        <button type="button" class="btn btn-duoc btn-duoc-outline" data-dismiss="modal">Cancelar</button>
        <button type="button" class="btn btn-duoc btn-duoc-success" data-dismiss="modal" (click)="aceptarTyc()">Aceptar</button>
      </div>
      <div class="modal-footer text-center dn-desktop">
        <button type="button" class="btn btn-duoc btn-duoc-success btn-w-180" data-dismiss="modal" (click)="aceptarTyc()">Aceptar</button>
        <button type="button" class="btn btn-duoc btn-duoc-outline btn-w-180 mt-2" data-dismiss="modal">Cancelar</button>
      </div>
    </div>
  </div>
</div>

<app-text-info-modal [title]="infoTitulo" [innerHtmlText]="infoModalText"></app-text-info-modal>

<!-- Modal Volver a Postular -->
<!-- Modal -->
<div class="modal fade" id="modalVolverPostular" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h2 class="modal-title" id="exampleModalLabel">Aviso</h2>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <p class="font-lato text-justify tyc-accept-mobile">Al presionar el botón Volver a postular, Ud. podrá generar una nueva postulación y la actual postulación quedará no vigente. <span class="dn-desktop"><br></span> ¿Está seguro de volver a postular?</p>
      </div>
      <div class="modal-footer text-center footer-desktop">
        <button type="button" class="btn btn-duoc btn-duoc-outline" data-dismiss="modal" (click)="volverPostular()">Si</button>
        <button type="button" class="btn btn-duoc btn-duoc-success" data-dismiss="modal">No</button>
      </div>
    </div>
  </div>
</div>