import { Component, OnInit } from '@angular/core';
import { Postulante } from 'src/app/models/postulante';
import { ServicesService } from 'src/app/services/services.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NgForm } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { ContentService } from 'src/app/services/content/content.service';

@Component({
  selector: 'app-view-registro',
  templateUrl: './view-registro.component.html',
  styleUrls: ['./view-registro.component.css']
})
export class ViewRegistroComponent implements OnInit {

  /** Atributos de Clase */
  titulo: string = "titulo";
  texto: string = "texto";
  postulante: Postulante = new Postulante();
  confirmarPassword: string = "";
  error: string = "";
  mensaje: string = "";
  showPassword: boolean = false;
  showComfirPassword: boolean = false;
  token: string | undefined;
  idSap = 0;

  constructor(
    private servicios: ServicesService,
    private router: Router,
    private spinner: NgxSpinnerService,
    private contentService: ContentService,
    private route: ActivatedRoute
  ) {
    this.token = undefined;

    this.route.queryParams.subscribe((param: any) => {
      if ( param.idSap ) {
        this.idSap = param.idSap
        console.log(this.idSap)
        localStorage.setItem('codigoCurso', this.idSap.toString());
      }
    })
  }

  public send(form: NgForm): void {
    this.error = "";
    this.postulante.nombres = this.postulante.nombres.trim();
    this.postulante.apellido1 = this.postulante.apellido1.trim();
    this.postulante.apellido2 = this.postulante.apellido2.trim();
    this.postulante.email = this.postulante.email.trim();

    if (form.invalid) {
      for (const control of Object.keys(form.controls)) {
        form.controls[control].markAsTouched();
      }
      return;
    }
    this.clickRegistro();
  }

  viewPassword() {
    this.showPassword = !this.showPassword;
  }

  viewConfirmPassword() {
    this.showComfirPassword = !this.showComfirPassword;
  }

  ngOnInit(): void {
    //Consulta el texto de la página
    this.spinner.show()
    this.servicios.getTextos('REGISTRO').subscribe({
      next: (respuesta) => {
        this.spinner.hide()
        let objeto = respuesta[0];
        this.titulo = objeto.titulo;
        this.texto = objeto.texto;
      }, error: (err) => { this.spinner.hide() }
  });
  }

  clickRegistro(): void {
    const exp_password = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@.$!%*#?&])[A-Za-z\d@.$!%*#?&]{3,}$/;
    this.error = '';
    //Inicializar mensajes
    this.mensaje = "";
    //Validar la información del postulante
    if (this.postulante.password.length < 6 || this.postulante.password.length > 8) {
      this.error = '<b class="font-merriweather-bold !important;">La contraseña debe tener mínimo 6 y máximo 8 caracteres</b>';
      this.limpiarError();
    }
    else if (!exp_password.exec(this.postulante.password)) {
      this.error = '<b class="font-merriweather-bold !important;">La contraseña debe tener mínimo un número, una letra y un caracter especial</b>';
      this.limpiarError();
    }
    else if (!this.esEmailValido(this.postulante.email)) {
      this.error = '<b class="font-merriweather-bold !important;">El Email ingresado no es correcto</b>';
      this.limpiarError();
    }
    else if (this.postulante.password != this.confirmarPassword) {
      this.error = '<b class="font-merriweather-bold !important;">Las contraseñas no son iguales</b>';
      this.limpiarError();
    }
    else {
      //Registrar la información del postulante
      this.spinner.show()
      this.postulante.codigoCurso = Number(localStorage.getItem('codigoCurso'))
      this.servicios.postRegistrarPostulante(this.postulante).subscribe({
        next: (respuesta) => {
          this.spinner.hide()
          //Validar si existen errores
          if (!respuesta.valor) {
            this.error = respuesta.error.mensaje;
          }
          else {
            //Retornar mensaje de Exito
            this.mensaje = '<b class="font-merriweather-bold !important;">¡ La inscripción ha sido existosa !</b>';
            setTimeout(() => {
              this.router.navigate(["/"]);
            }, 1000);
            this.limpiarError();
          }
        }, error: (err) => {
          this.spinner.hide()
        }
    });
    }
  }
  limpiarError(): void {
    const limpiarError = () => {
      this.error = "";
      this.mensaje = "";
    }
    setTimeout(limpiarError,3000);
  }
  esEmailValido(email: string): boolean {
    let mailValido = false;
    let EMAIL_REGEX = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    if (EMAIL_REGEX.exec(email)) {
      mailValido = true;
    }
    return mailValido;
  }

  limpiarMensaje() {
    this.error = "";
  }

  keyBorrarErrores(): void {
    this.error = '';
  }
}
