import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { InfoPago } from 'src/app/models/info-pago';
import { Pantallas } from 'src/app/models/pantallas';
import { ContentService } from 'src/app/services/content/content.service';
import { PostulacionService } from 'src/app/services/postulacion/postulacion.service';
import { ServicesService } from 'src/app/services/services.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-pago-en-linea',
  templateUrl: './pago-en-linea.component.html',
  styleUrls: ['./pago-en-linea.component.css'],
})
export class PagoEnLineaComponent implements OnInit {
  /** Atributos de Clase */
  textos: Pantallas[] = [];
  parametros: InfoPago = new InfoPago();
  urlPagoOnline: string = environment.urlPagosOnline;
  infoModalText: string = '';
  tituloPopup: string = 'Hubo un error';
  texto1: Pantallas = new Pantallas();
  texto2: Pantallas = new Pantallas();
  mostrar: boolean = false;
  esperar: boolean = true;
  timerId: any;
  rut: string = '';
  public pagoMixto: boolean = false;
  public mensajeValidaOCMixto: boolean = false;

  public cursoFueraPlazo: boolean = false;
  public estadoVacantes: boolean = true;

  @ViewChild('modalVolverPostular')
  modalVolverPostular!: ElementRef;

  constructor(
    private servicios: ServicesService,
    private spinner: NgxSpinnerService,
    private router: Router,
    private _postulacionService: PostulacionService,
    public _contenidoService: ContentService
  ) {
    this.spinner.show();

    this.servicios.getTextos('PAGO_EN_LINEA').subscribe({
      next: (respuesta) => {
        this.texto1 = respuesta[0];
        this.texto2 = respuesta[1];

        this.servicios.getInfoPago().subscribe({
          next: (result) => {
            this.spinner.hide();
            this.parametros = result;

            
            this.exitoLlamado(result);
            if (result.financiamiento == 'Mixto') {
              this.pagoMixto = true;
              if (result.error == null) {
                let email: any = localStorage.getItem('email');
                this.servicios.postEstado(email).subscribe((estado) => {
                  console.log('estado', estado);
                  console.log("error", result.error);
                  this.validarOC(estado);
                });

              }
            }
            
          },
          error: (error) => {
            this.spinner.hide();
          },
        });
      },
      error: (error) => {
        this.spinner.hide();
      },
    });

    let login = this.servicios.getLogin();
    this.rut = login.rut;

    const routerData =
      this.router.getCurrentNavigation()?.extras?.state?.['data'];
    if (routerData?.infoModal) {
      window.document.getElementById('modal-ayuda-organizar-btn')?.click();
    }
  }

  exitoLlamado(result: any) {
    if (result.error != null) {
      this.tituloPopup = 'Importante';
      if (
        result.error.mensaje ==
        'La postulacion no se pudo realizar. No existen Vacantes'
      ) {
      this.infoModalText =
        'Este programa no cuenta con vacantes para esta versión, por favor intenta más tarde o contáctanos al <b>+56 442 201 096</b>';
      window.document.getElementById('text-info-modal-btn')?.click();
        this.estadoVacantes = false;
      }
      else {
        this.infoModalText = result.error.mensaje;
        window.document.getElementById('text-info-modal-btn')?.click();
      }
    } else {

      this.mostrar = true;
    }
  }
  validarOC(estado: any) {
      if (estado?.estado == 'VALIDAR_OC') {
        this.mensajeValidaOCMixto = true;
        this.tituloPopup = '¡Pago Exitoso!';
        this.infoModalText =
          'Hemos recibido con éxito tu pago. Ahora, para dar inicio al curso, te recordamos que tu empresa debe saldar la deuda pendiente.<br><br>¡Gracias por tu participación!';
        window.document.getElementById('modal-validaoc-pagomixto-btn1')?.click();
      }
  }

  mostrarFuerzaPlazo() {
    console.log('mostrar fuera plazo');
    this.tituloPopup = 'Importante';
    this.infoModalText =
      'No puedes dar continuidad al programa actual, ya que su fecha de inicio ha caducado. Sin embargo, tienes la opción de seleccionar otro curso para continuar con tu postulación.';
    window.document.getElementById('text-info-modal-btn')?.click();
  }

  validarFuerzaPlazo() {
    this._postulacionService.ofertaSeleccionada().subscribe({
      next: (res) => {
        this.spinner.hide();
        let selectedCourse = res.oferta;

        const fechaInicio = selectedCourse.fechaInicio;
        const partesFechaInicio = fechaInicio.split('/').map(Number);
        const fechaInicioDate = new Date(
          partesFechaInicio[2],
          partesFechaInicio[1] - 1,
          partesFechaInicio[0]
        );

        const hoy = new Date();
        hoy.setHours(0, 0, 0, 0); // Omitir la hora actual para comparar solo las fechas

        if (fechaInicioDate <= hoy) {
          this.cursoFueraPlazo = true;
          this.mostrar = false;
          this.mostrarFuerzaPlazo();
        } else {
          this.cursoFueraPlazo = false;
        }
      },
      error: (err) => this.spinner.hide(),
    });
  }

  ngOnInit(): void {
    this.crearKit();
    this.validarFuerzaPlazo();
    
  }

  clickRepostular(): void {
    clearTimeout(this.timerId);
    let login = this.servicios.getLogin();
    this.spinner.show();
    this.servicios.postRepostular(login.email).subscribe((result) => {
      if (result.error === null) {
        this.router.navigate(['/postulacion']);
      } else {
        this.infoModalText = result?.error?.mensaje;
        window.document.getElementById('text-info-modal-btn')?.click();
      }
      this.spinner.hide();
    });
  }

  

  crearKit() {
    if (this.cursoFueraPlazo) {
      this.mostrarFuerzaPlazo();
    } else {
      this.timerId = setTimeout(() => {
        this.spinner.show();
        this.servicios.postCrearKit().subscribe((result) => {
          this.spinner.hide();
          console.log(result.codigoError);
          switch (result.codigoError) {
            case 0:
              this.router.navigate(['/resumen-inscripcion-kit']);

              break;
            case 402:
              this.crearKit();

              break;
            case 412:
              localStorage.clear();
              window.location.href = '/';
              break;
            case 428:
              window.location.reload();

              break;
            default:
              this.router.navigate(['/resumen-inscripcion']);

              break;
          }
        });
      }, 30000);
    }
  }
}
