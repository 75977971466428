<app-header-pasos [paso]=5 [estado]="true" [total]="5" [paso_texto]="'INSCRIPCIÓN REALIZADA'" [paso_texto_siguiente]="''"></app-header-pasos>

<div class="container container-duoc">
    <section class="mt-4 mb-4">
        <h1 class="text-center" style="font-size: 40px;" [innerHTML]="textosPantalla.titulo"></h1>
    </section>
        <div class="col-12 mt-2 text-ins">
            <p class="font-lato text-alineacion" [innerHTML]="textosPantalla.texto"></p>
        </div>
  <!-- Desktop -->
  <div class="dn-mobile">
    <div class="custom-card-gray mt-2">
      <div class="custom-card-gray-content position-relative">
        <div class="custom-item-border-75 pl-4">
          <div class="row">
            <div class="col-md-6">
              <div class="custom-card-gray-title">
                <h5 class="font-lato-bold text-uppercase">{{resumenInscripcion.oferta.tipo}}</h5>
                <h2 class="font-lato-bold">{{resumenInscripcion.oferta.nombre}}</h2>
              </div>
            </div>
            <div class="col-md-6">
              <div class="custom-card-gray-content-info">
                <p>Modalidad: <strong class="custom-bold">{{resumenInscripcion.oferta.modalidad}}</strong></p>
                <p>Fecha de inicio: <strong class="custom-bold">{{resumenInscripcion.oferta.fechaInicio}}</strong></p>
                <p>Fecha de término: <strong class="custom-bold">{{resumenInscripcion.oferta.fechaTermino}}</strong></p>
                <p>Días de clases: <strong class="custom-bold">{{resumenInscripcion.oferta.diasClases}}</strong></p>
                <p>Horario: <strong class="custom-bold">{{resumenInscripcion.oferta.horario}}</strong></p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Mobile -->
  <div class="dn-desktop">
    <div class="custom-card-gray mt-2">
      <div class="custom-card-gray-content position-relative">
        <div class="custom-item-border-75 pl-4">
          <div class="row">
            <div class="col-10">
              <div class="custom-card-gray-title">
                <h5 class="font-lato-bold text-uppercase">{{resumenInscripcion.oferta.tipo}}</h5>
                <h2 class="font-lato-bold">{{resumenInscripcion.oferta.nombre}}</h2>
              </div>
            </div>
            <div class="col-2">
              <em id="custom-card-gray-collapse-btn" class="bi bi-plus-circle custom-card-gray-collapse-btn" (click)="showMore()"></em>
              <em id="custom-card-gray-collapse-btn-hidden" class="bi bi-dash-circle d-none custom-card-gray-collapse-btn" (click)="showLess()"></em>
            </div>
            <div id="custom-card-gray-expand-content" class="col-md-12 d-none custom-card-gray-expand-hidden">
              <div class="custom-card-gray-content-info">
                <p>Modalidad: <strong class="custom-bold">{{resumenInscripcion.oferta.modalidad}}</strong></p>
                <p>Fecha de inicio: <strong class="custom-bold">{{resumenInscripcion.oferta.fechaInicio}}</strong></p>
                <p>Fecha de término: <strong class="custom-bold">{{resumenInscripcion.oferta.fechaTermino}}</strong></p>
                <p>Días de clases: <strong class="custom-bold">{{resumenInscripcion.oferta.diasClases}}</strong></p>
                <p>Horario: <strong class="custom-bold">{{resumenInscripcion.oferta.horario}}</strong></p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="custom-card-price">
    <p>Valor del programa: <strong class="custom-bold text-white">${{ resumenInscripcion.oferta.valorPrograma | number:'':'es-CL'
        }}</strong></p>
  </div>

  <div class="mt-5-5"></div>
        <div class="col-12 text-ins mb-4">
            <p class="font-lato">Accede a información de interés:</p>
        </div>
        <div class=" text-ins">
          <div class="mb-2" *ngFor="let mensaje of resumenInscripcion.links" >
            <div *ngIf="(mensaje.texto != null && mensaje.texto != '') && (mensaje.titulo != null && mensaje.titulo != '')">
              <table>
                <caption hidden></caption>
                <th hidden></th>
                <tr>
                  <td>
                    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-arrow-right-short" viewBox="0 0 16 16">
                      <path fill-rule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z"/>
                    </svg>
                  </td>
                  <td style="text-align: start;">
                    <a href="{{mensaje.texto}}" target="_blank" class="text-reset d-block"  style="margin-left: 10px;text-decoration: underline;">{{mensaje.titulo}}</a>
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </div>
        <div class="mt-2"></div>
        <div class="text-center col-12 mt-4">
            <button type="button" class="btn btn-duoc btn-duoc-outline mr-1" (click)="clickCerrarSesion()">
             Ir al Home
            </button>
          </div>
</div>
