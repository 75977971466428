import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { CarreraInscripcion } from 'src/app/models/carrera-inscripcion';
import { Comuna } from 'src/app/models/comuna';
import { DataCheck } from 'src/app/models/data-check';
import { EstadoCivil } from 'src/app/models/estado-civil';
import { Genero } from 'src/app/models/genero';
import { Nacionalidad } from 'src/app/models/nacionalidad';
import { Pais } from 'src/app/models/pais';
import { PreInscripcion } from 'src/app/models/pre-inscripcion';
import { Region } from 'src/app/models/region';
import { ContentService } from 'src/app/services/content/content.service';
import { ServicesService } from 'src/app/services/services.service';

@Component({
  selector: 'app-ficha-pre-inscripcion',
  templateUrl: './ficha-pre-inscripcion.component.html',
  styleUrls: ['./ficha-pre-inscripcion.component.css']
})
export class FichaPreInscripcionComponent implements OnInit {

  model: PreInscripcion = new PreInscripcion();
  nacionalidades: Nacionalidad[] = [];
  estadosCiviles: EstadoCivil[] = [];
  generos: Genero[] = [];
  paises: Pais[] = [];
  regiones: Region[] = [];
  comunas: Comuna[] = [];
  otro: string = "";
  dataChecks: DataCheck[] = [];
  error: string = "";
  limiteEdad: any;
  fuenteInformacionChecked: string[] = []

  constructor(
    private servicios: ServicesService,
    private spinner: NgxSpinnerService,
    private router: Router,
    private contentService: ContentService
  ) {
    let login = this.servicios.getLogin();
    this.setDataChecks();
    this.model.carreras.push(new CarreraInscripcion());
    this.model.email = login.email;
    this.model.rut = login.rut;
    this.model.nombres = login.nombres;
    this.model.apellido1 = login.apellido1;
    this.model.apellido2 = login.apellido2;
    this.model.financiamiento = "Particular";
    this.servicios.getNacionalidades().subscribe(
      (result) => {
        this.nacionalidades = result;
      }
    );
    this.servicios.getEstadosCiviles().subscribe(
      (result) => {
        this.estadosCiviles = result;
      }
    );
    this.servicios.getGeneros().subscribe(
      (result) => {
        this.generos = result;
      }
    );
    this.servicios.getPaises().subscribe(
      (result) => {
        this.paises = result;
      }
    );
    this.servicios.getRegiones().subscribe(
      (result) => {
        this.regiones = result;
      }
    );
    this.servicios.getParametrosEdadesLimites().subscribe(
      (result) => {
        this.limiteEdad = result;
      }
    );

    const routerData = this.router.getCurrentNavigation()?.extras?.state?.['data']
    if (routerData?.infoModal) {
      window.document.getElementById('modal-ayuda-organizar-btn')?.click();
    }
  }

  private setDataChecks(): void {
    let dto = new DataCheck();
    dto.id = "Pagina";
    dto.nombre = "Página Web Educación Continua Duoc UC";
    this.dataChecks.push(dto);

    dto = new DataCheck();
    dto.id = "Facebook";
    dto.nombre = "Facebook";
    this.dataChecks.push(dto);

    dto = new DataCheck();
    dto.id = "Google";
    dto.nombre = "Google";
    this.dataChecks.push(dto);

    dto = new DataCheck();
    dto.id = "Email";
    dto.nombre = "Email";
    this.dataChecks.push(dto);

    dto = new DataCheck();
    dto.id = "Instagram";
    dto.nombre = "Instagram";
    this.dataChecks.push(dto);

    dto = new DataCheck();
    dto.id = "LinkedIn";
    dto.nombre = "LinkedIn";
    this.dataChecks.push(dto);

    dto = new DataCheck();
    dto.id = "Otro";
    dto.nombre = "Otro";
    this.dataChecks.push(dto);
  }

  ngOnInit(): void {
    this.spinner.show()
    this.contentService.getPersonalData().subscribe({
      next: (res) => {
        console.log(res)
        this.model.nombres = res?.nombres ? res?.nombres : ''
        this.model.apellido1 = res?.apellido1 ? res?.apellido1 : ''
        this.model.apellido2 = res?.apellido2 ? res?.apellido2 : ''

        this.model.fechaNacimiento = res?.fechaNacimiento ? res?.fechaNacimiento : ''

        this.autocompletarSelects(res);
        if ( res?.financiamiento ) {
          this.model.financiamiento = res?.financiamiento ? res?.financiamiento : ''
        }
        if ( res?.fuenteInformacion ) {
          this.fuenteInformacionChecked = res?.fuenteInformacion.split(';')
          this.dataChecks.forEach(element => {
            if ( this.fuenteInformacionChecked.includes(element.id) ) {
              element.seleccionado = true
            }
          });
        }
        this.autocompletarCampos(res)
        
        this.spinner.hide()
      }, error: err => (this.spinner.hide())
  })
  }

  autocompletarSelects(res: any) {
    if ( res?.nacionalidad?.id ) {
      console.log(res?.nacionalidad?.id);
      this.model.nacionalidad.id = res?.nacionalidad?.id ? res.nacionalidad?.id : ''
    }
    
    if ( res?.estadoCivil?.id ) {
      this.model.estadoCivil.id = res?.estadoCivil?.id ? res.estadoCivil?.id : ''
    }

    if ( res?.genero?.id ) {
      this.model.genero.id = res?.genero?.id ? res.genero?.id : ''
    }

    if ( res?.region?.id ) {
      this.model.region.id = res?.region?.id ? res?.region?.id : ''
    }

    if ( res?.region?.id ) {
      this.getComunas(res);
    }

    
  }

  getComunas(res: any) {
    this.servicios.getComunas(this.model.region.id).subscribe(
      (result) => {
        this.comunas = result;
        this.model.comuna.id = res?.comuna?.id ? res?.comuna?.id : ''
      }
    );
  }
  autocompletarCampos(res: any) {
    this.model.calle = res?.calle ?? ''

        this.model.numeroDepartamento = res?.numeroDepartamento ?? ''
        this.model.telefonoFijo = res?.telefonoFijo ?? ''
        this.model.celular = res?.celular ?? ''
        this.model.empresa = res?.empresa ?? '';
        this.model.cargo = res?.cargo ?? '';
        this.model.numeroCalle = res?.numeroCalle ?? '';
        this.model.departamento = res?.departamento ?? '';
        if (res.carreras.length != 0) {
          this.model.carreras = [...res.carreras];
        }

  }
  onChangeRegion(): void {
    this.error = '';
    this.servicios.getComunas(this.model.region.id).subscribe(
      (result) => {
        this.comunas = result;
      }
    );
  }

  clickAgregaCarrera(): void {
    if (this.model.carreras.length < 3) {
      this.model.carreras.push(new CarreraInscripcion());
    }
    console.log(this.model);
  }
  validarErroresDatosPersonales() {
    if (this.esVacio(this.model.rut)) {
      this.error = this.getTextoObligatorio(this.error, "RUT");
    }
    if (this.esVacio(this.model.nombres)) {
      this.error = this.getTextoObligatorio(this.error, "Nombres");
    }
    if (this.esVacio(this.model.apellido1)) {
      this.error = this.getTextoObligatorio(this.error, "Primer Apellido");
    }
    if (this.esVacio(this.model.apellido2)) {
      this.error = this.getTextoObligatorio(this.error, "Segundo Apellido");
    }
    if (this.opcionVacio(this.model.nacionalidad.id)) {
      this.error = this.getTextoObligatorio(this.error, "Nacionalidad");
    }
    if (this.esVacio(this.model.fechaNacimiento)) {
      this.error = this.getTextoObligatorio(this.error, "Fecha de nacimiento");
    } else {
      let edad = this.model.edad;
      console.log(edad, ' edad ');
      if (edad < this.limiteEdad.edadMinima || edad > this.limiteEdad.edadMaxima) {
        this.error = '<b class="font-merriweather-bold !important;">- El rango de edad debe ser entre ' + this.limiteEdad.edadMinima + ' y ' + this.limiteEdad.edadMaxima + '.</b>';
      }
    }
    if (this.opcionVacio(this.model.estadoCivil.id)) {
      this.error = this.getTextoObligatorio(this.error, "Estado civil");
    }
    if (this.opcionVacio(this.model.genero.id)) {
      this.error = this.getTextoObligatorio(this.error, "G&eacute;nero");
    }
  }
  validarErroresProcedencia() {
    if (this.opcionVacio(this.model.pais.id)) {
      this.error = this.getTextoObligatorio(this.error, "Pa&iacute;s");
    }
    if (this.opcionVacio(this.model.region.id)) {
      this.error = this.getTextoObligatorio(this.error, "Regi&oacute;n");
    }
    if (this.opcionVacio(this.model.comuna.id)) {
      this.error = this.getTextoObligatorio(this.error, "Comuna");
    }
    if ( this.esVacio(this.model.calle)) {
      this.error = this.getTextoObligatorio(this.error, "Calle");
    }
    if ( this.esVacio(this.model.numeroCalle)) {
      this.error = this.getTextoObligatorio(this.error, "Numero de la calle");
    }
    if (this.opcionVacio(this.model.celular)) {
      this.error = this.getTextoObligatorio(this.error, "Celular");
    }
    if (this.opcionVacio(this.model.email)) {
      this.error = this.getTextoObligatorio(this.error, "Email");
    }
  }
  validarCarrera(carrerasGuardar: any, codigo: any) {
    this.model.carreras.forEach((element, index) => {
      let valida = false;
      if (index == 0) {
        valida = true;
      } else if (!this.esVacio(element.nombreCarrera) || !this.esVacio(element.institucion)) 
        {
          console.log("valida", true);
          valida = true;
        }
      if (valida) {
        if (this.esVacio(element.nombreCarrera)) {
          this.error = this.getTextoObligatorio(this.error, "Carrera " + (index + 1));
        }
        if (this.esVacio(element.institucion)) {
          this.error = this.getTextoObligatorio(this.error, "Instituci&oacute;n " + (index + 1));
        }
      }
      element.institucion = element.institucion.trim();
      element.nombreCarrera = element.nombreCarrera.trim();
      if (!this.esVacio(element.nombreCarrera) && !this.esVacio(element.institucion)) {
        carrerasGuardar[codigo] = element;
        codigo++;
      }
    });
  }
  estructurarTextoInformacion(){
    let texto = "";
    this.dataChecks.forEach(element => {
      if (element.seleccionado) {
        if (texto != "") {
          texto += ";";
        }
        texto += element.id;
        if (element.id == 'Otro') {
          texto += ":" + this.otro.trim();
        }
      }
    });
    this.model.fuenteInformacion = texto;
  }
  clickGuardar(): void {
    this.error = "";

    this.validarErroresDatosPersonales();
    this.validarErroresProcedencia();

    
    if (this.opcionVacio(this.model.empresa)) {
      this.error = this.getTextoObligatorio(this.error, "Empresa");
    }
    if (this.opcionVacio(this.model.cargo)) {
      this.error = this.getTextoObligatorio(this.error, "Cargo");
    }
    let carrerasGuardar: CarreraInscripcion[] = [];
    let codigo: number = 0;
    this.validarCarrera(carrerasGuardar, codigo);
    console.log(carrerasGuardar);

    this.dataChecks.forEach(element => {
      if (element.seleccionado && element.nombre == 'Otro') {
        if (this.esVacio(this.otro)) {
          this.error = this.getTextoObligatorio(this.error, "Otro");
        }
      }
    });

    if (this.esVacio(this.error)) {
      
      this.estructurarTextoInformacion();
      console.log(this.model, ' ------------------ MODELO -----------------------');
      this.spinner.show();
      this.setCamposVacios();
      this.model.carreras = carrerasGuardar;
      this.servicios.setGuardaFichaPreInscripcion(this.model).subscribe(
        (result) => {
          if (!result.valor) {
            this.error = result.error.mensaje;
            this.limpiarError();
          } else if(this.model.financiamiento == "Particular") {
              window.location.href = '/postulacion';
            }else {
              window.location.href = '/ficha-pre-inscripcion-empresa';
            }
          this.spinner.hide();
        }
      );
    }else {
      this.limpiarError();
    }
  }
  limpiarError(): void {
    const limpiarError = () => {
      this.error = "";
    }
    setTimeout(limpiarError,3000);
  }

  private setCamposVacios(): void {
    this.model.nombres = this.model.nombres.trim();
    this.model.apellido1 = this.model.apellido1.trim();
    this.model.apellido2 = this.model.apellido2.trim();

    this.model.numeroCalle = this.model.numeroCalle.trim();
    this.model.calle = this.model.calle.trim();
    this.model.departamento = this.model.departamento.trim();
    this.model.numeroDepartamento = this.model.numeroDepartamento.trim();
    this.model.telefonoFijo = this.model.telefonoFijo.trim();
    this.model.celular = this.model.celular.trim();
    this.model.empresa = this.model.empresa.trim();
    this.model.cargo = this.model.cargo.trim();

  }

  private opcionVacio(valor: string): boolean {
    return valor == null || valor == undefined || valor.trim() == '0' || valor.trim() == '';
  }

  private esVacio(valor: string): boolean {
    return valor == null || valor == undefined || valor.trim() == '';
  }

  private getTextoObligatorio(valor: string, campo: string): string {
    if (!this.esVacio(valor)) {
      valor += "<br/>"
    }
    return valor + '<b class="font-merriweather-bold !important;">- El campo ' + campo + ' es obligatorio</b>';
  }

  onChangeInformacion(): void {
    console.log(this.model);
  }

  setFormatoRut() {
    this.error = "";
    let valor: any = this.model.rut;
    if (valor.length > 1) {
      valor = valor.replace('-', '');
      valor = this.arreglarCP(valor, valor.length - 1);
    }
    this.model.rut = valor;
  }

  arreglarCP(addr: any, posicion: any) {
    return addr.slice(0, posicion) + "-" + (addr).slice(posicion);
  }

  checkFuenteInformacion(value: string) {
    return this.fuenteInformacionChecked.includes(value)
  }
}
