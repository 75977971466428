import { CarreraInscripcion } from "./carrera-inscripcion";
import { Comuna } from "./comuna";
import { EstadoCivil } from "./estado-civil";
import { Genero } from "./genero";
import { Nacionalidad } from "./nacionalidad";
import { Pais } from "./pais";
import { Region } from "./region";

export class PreInscripcion {
    region: Region = new Region();
    comuna: Comuna = new Comuna();
    carreras: CarreraInscripcion[] = [];
    fuenteInformacion: string = "";
    rut: string = "";
    nombres: string = "";
    apellido1: string = "";
    apellido2: string = "";
    nacionalidad: Nacionalidad = new Nacionalidad();
    fechaNacimiento: string = "";
    estadoCivil: EstadoCivil = new EstadoCivil();
    genero: Genero = new Genero();
    pais: Pais = new Pais();
    calle: string = "";
    numeroCalle: string = "";
    departamento: string = "";
    numeroDepartamento: string = "";
    telefonoFijo: string = "";
    celular: string = "";
    email: string = "";
    empresa: string = "";
    cargo: string = "";
    financiamiento: string = "";

    get edad(): number {
        console.log('edad escogida', this.fechaNacimiento, Date.now());
        return ~~((Date.now() - (new Date(this.fechaNacimiento).getTime())) / (24 * 3600 * 365.25 * 1000));
    }
}
