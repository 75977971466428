import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ContentService } from 'src/app/services/content/content.service';
import { PostulacionService } from 'src/app/services/postulacion/postulacion.service';
import { ServicesService } from 'src/app/services/services.service';

@Component({
  selector: 'app-tyc',
  templateUrl: './tyc.component.html',
  styleUrls: ['./tyc.component.css']
})
export class TycComponent implements OnInit {
  tycText: any = {}
  accepted: boolean = false
  selectedCourse: any = {}
  modalVolverPostularShow: boolean = true
  infoModalText: string = ''
  titulo: string = "";
  cursoFueraPlazo: boolean = false;
  checkAceptaTerminos:boolean =  false;
  infoTitulo:string = "Hubo un error";

  constructor(
    private contentService: ContentService,
    private spinner: NgxSpinnerService,
    private _postulacionService: PostulacionService,
    private servicios: ServicesService,
    private router: Router
  ) { 
    const routerData = this.router.getCurrentNavigation()?.extras?.state?.['data']
    if ( routerData?.selectedCourse ) {
      this.selectedCourse = routerData?.selectedCourse
    } else {
      this.spinner.show();
      
      
      this._postulacionService.ofertaSeleccionada().subscribe({
        next: (res) => {
          console.log(res)
          this.spinner.hide()
          this.selectedCourse = res.oferta
          this.cursoFueraPlazo = this.verificarFueraPlazo();
          if (this.cursoFueraPlazo) {
            this.mensajeFuerzaPlazo();
          }
         
        }, error: err => this.spinner.hide()
    })
    }

    if ( routerData?.infoModal ) {
      window.document.getElementById('modal-ayuda-organizar-btn')?.click();
    }
  }

  ngOnInit(): void {
    this.spinner.show()
    this.contentService.getTyc().subscribe({
      next: (res) => {
        this.tycText = res[0]?.texto;
        this.titulo = res[0].titulo;
        this.spinner.hide()
      }, error: err => this.spinner.hide()
  })
  }

  aceptarTyc() {
    if (!this.verificarFueraPlazo()) {
    this.spinner.show()
    this._postulacionService.aceptaTyC(this.servicios.getLogin().email).subscribe({
      next: (res) => {
        console.log("_postulacionService.aceptaTyC", res);
        this.spinner.hide().then(() => {
          if ( res.error === null ) {
            window.location.href = '/resultado-postulacion';
          } else {

            this.infoModalText = res?.error?.mensaje
            window.document.getElementById('text-info-modal-btn')?.click();
          }
        })
      }, error: err => this.spinner.hide()
    })
    }
    else {
      console.log("mensaje fuerza plazo");
      this.mensajeFuerzaPlazo();
    }
  }

  mensajeFuerzaPlazo() {
    this.infoTitulo = "Importante";
    this.infoModalText = "No puedes dar continuidad al programa actual, ya que su fecha de inicio ha caducado. Sin embargo, tienes la opción de seleccionar otro curso para continuar con tu postulación.";
    window.document.getElementById('text-info-modal-btn')?.click();
  }

  volverPostular() {
    this.spinner.show()
    this._postulacionService.repostular(this.servicios.getLogin().email).subscribe(
      (res: any) => {
        console.log(res)
        this.modalVolverPostularShow = false
        this.spinner.hide().then(() => {
          if ( res.error === null ) {
            window.location.href = '/postulacion';
          } else {
            this.infoModalText = res?.error?.mensaje
            window.document.getElementById('text-info-modal-btn')?.click();
          }
        })
      }
    )
  }

  verificarFueraPlazo():boolean {
    let cursoFueraPlazo:boolean = false;
const fechaInicio = this.selectedCourse.fechaInicio; // 
const partesFechaInicio = fechaInicio.split('/').map(Number);
const fechaInicioDate = new Date(partesFechaInicio[2], partesFechaInicio[1] - 1, partesFechaInicio[0]);

  const hoy = new Date();
    hoy.setHours(0, 0, 0, 0); // Omitir la hora actual para comparar solo las fechas

    if (fechaInicioDate <= hoy) {
      cursoFueraPlazo = true;
      this.checkAceptaTerminos = true;
    }
    else {
      this.checkAceptaTerminos = false;
    }

    return cursoFueraPlazo;

  }

}
