
import { Component, OnInit } from '@angular/core';
import { Login } from 'src/app/models/login';
import { Pantallas } from 'src/app/models/pantallas';
import { ContentService } from 'src/app/services/content/content.service';
import { ServicesService } from 'src/app/services/services.service';
import {filter, switchMap} from "rxjs";
import {Parametro} from "../../models/parametro";
import {ParametroFacade} from "../../state/parametroFacade";

@Component({
  selector: 'app-view-header',
  templateUrl: './view-header.component.html',
  styleUrls: ['./view-header.component.css']
})
export class ViewHeaderComponent implements OnInit {
  login: Login = new Login();
  textos: Pantallas[] = [];
  paginaLista: Pantallas[] = [];
  pagina: number = 1;
  render: boolean = false;
  parametro: Parametro = new Parametro();

  constructor(
    private servicios: ServicesService,
    private contentService: ContentService,
    private readonly parametroFacade: ParametroFacade
  ) {
    this.login = this.servicios.getLogin();

    this.contentService.getLoggedInName.subscribe((res) => {
      this.login = res
    })
  }

  ngOnInit(): void {
    this.parametroFacade.loaded$
      .pipe(
        filter((isLoaded: boolean) => isLoaded),
        switchMap(() => this.parametroFacade.parametro$)
      )
      .subscribe((parametro: Parametro) => {
        this.parametro = parametro;
      });
  }

  clickCerrarSesion() {
    localStorage.clear();
    window.location.href = "/";
  }

  showHideLogout() {
    const logoutBtnHiden = window.document.getElementById('floating-logout-btn')?.classList.contains('d-none')
    if ( logoutBtnHiden ) {
      window.document.getElementById('floating-logout-btn')?.classList.remove('d-none')
      window.document.getElementById('logout-btn-hidden')?.classList.add('d-none')
      window.document.getElementById('logout-btn-showed')?.classList.remove('d-none')
    } else {
      window.document.getElementById('floating-logout-btn')?.classList.add('d-none')
      window.document.getElementById('logout-btn-showed')?.classList.add('d-none')
      window.document.getElementById('logout-btn-hidden')?.classList.remove('d-none')
    }
  }


}
