import { Component } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { DatosEmpresa } from 'src/app/models/datosEmpresa';
import { ServicesService } from 'src/app/services/services.service';

@Component({
  selector: 'app-pre-inscripcion-empresa',
  templateUrl: './pre-inscripcion-empresa.component.html',
  styleUrls: ['./pre-inscripcion-empresa.component.css']
})
export class PreInscripcionEmpresaComponent {


  error: string = "";
  model: DatosEmpresa = new DatosEmpresa();

  constructor(
    private servicio: ServicesService,
    private spinner: NgxSpinnerService) { }



  private esVacio(valor: string): boolean {
    return valor == null || valor.trim() == '';
  }
  private getTextoObligatorio(valor: string, campo: string): string {
    if (!this.esVacio(valor)) {
      valor += "<br/>"
    }
    return valor + '<b class="font-merriweather-bold !important;">- El campo ' + campo + ' es obligatorio</b>';
  }
  setFormatoRut() {
    this.error = "";
    let valor: any = this.model.rutEmpresa;
    if (valor.length > 1) {
      valor = valor.replace('-', '');
      valor = this.arreglarCP(valor, valor.length - 1);
    }
    this.model.rutEmpresa = valor;
  }

  validarRut(parmRut: string): boolean {
    if (parmRut == "") {
      if (this.error == "") {
        this.error = '<b class="font-merriweather-bold !important; font-size:13px;">- El campo Rut no debe estar vacío</b>';
      } else {
        this.error = this.error + '</br><b class="font-merriweather-bold !important; font-size:13px;">- El campo Rut no debe estar vacío</b>';
      }
      return true;
    }

    if (!/^\d+[-|‐][0-9kK]$/.test(parmRut)) {
      if (this.error == "") {
        this.error = '<b class="font-merriweather-bold !important; font-size:13px;">- El campo Rut no es válido.</b>';
      } else {
        this.error = this.error + '</br><b class="font-merriweather-bold !important; font-size:13px;">- El campo Rut no es válido.</b>';
      }
      return true;
    }

    const tmp = parmRut.split('-');
    const digv = tmp[1].toLowerCase();
    const rut = tmp[0];
    if (this.validarDigito(rut) != digv) {
      if (this.error == "") {
        this.error = '<b class="font-merriweather-bold !important; font-size:13px;">- El campo Rut no es válido.</b>';
      } else {
        this.error = this.error + '</br><b class="font-merriweather-bold !important; font-size:13px;">- El campo Rut no es válido.</b>';
      }
      return true;
    }
    return false;
  }

  validarDigito(T: any) {
    let M = 0;
    let S = 1;
    for (; T; T = Math.floor(T / 10))
      S = (S + T % 10 * (9 - M++ % 6)) % 11;
    return S ? S - 1 : 'k';
  }

  arreglarCP(addr: any, posicion: any) {
    return addr.slice(0, posicion) + "-" + (addr).slice(posicion);
  }
  volverAFicha(): void {
    window.location.href = '/ficha-pre-inscripcion';
  }

  clickGuardar(): void {
    if (this.esVacio(this.model.rutEmpresa)) {
      this.error = this.getTextoObligatorio(this.error, "RUT Empresa");
    }else {
      this.validarRut(this.model.rutEmpresa);
    }
    if (this.esVacio(this.model.razonSocial)) {
      this.error = this.getTextoObligatorio(this.error, "Raz&oacute;n social");
    }
    if (this.esVacio(this.model.emailContacto)) {
      this.error = this.getTextoObligatorio(this.error, "Email contacto empresa");
    }
    if (this.esVacio(this.model.telefonoContacto)) {
      this.error = this.getTextoObligatorio(this.error, "Tel&eacute;fono contacto empresa");
    }

    this.esEmailValido(this.model.emailContacto);
    console.log(!this.esVacio(this.error));
    if (this.esVacio(this.error)) {
      this.spinner.show();
      this.servicio.setGuardarDatosEmpresa(this.model).subscribe(
        (respuesta: any) => {
          if (respuesta.error != null) {
            this.error = this.error + '<b class="font-merriweather-bold !important;">' + respuesta.error.mensaje + '</b>';
            this.limpiarError();
          }else {
            window.location.href = '/postulacion';
          }
          this.spinner.hide();
        }
      )
    }
    this.limpiarError();
  }
  limpiarError(): void {
    const limpiarError = () => {
      this.error = "";
    }
    setTimeout(limpiarError,3000);
  }

  esEmailValido(email: string): void {
    if (email == "") {
      return;
    }
    const EMAIL_REGEX = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    if (EMAIL_REGEX.exec(email)) {
      return;
    }
    if (this.error != "") {
      this.error = this.error + " <br/>";
    }
    this.error = this.error + '<b class="font-merriweather-bold !important;">- El campo Email contacto empresa ingresado no es correcto</b>';
  }
}
