<app-header-pasos [paso]=1 [estado]="false" [total]="5" [paso_texto]="'REGISTRO DE DATOS'" [paso_texto_siguiente]="'POSTULACIÓN ENVIADA'"></app-header-pasos>

<div class="container container-duoc">
  <section class="mt-4 mb-4">
    <p class="text-center font-lato">EDUCACIÓN CONTINUA</p>
    <h1 class="text-center">Ficha de pre-inscripción</h1>
  </section>

  <div class="position-relative pl-3">
    <h5 class="custom-item-border-100 ml-3">Datos personales</h5>
  </div>

  <div class="row mt-2 mb-4">
    <div class="col-md-6 col-xs-12">
      <div class="form-group">
        <label for="rut">RUT (sin punto y con gui&oacute;n) <span class="text-danger">(*)</span></label>

        <input type="text" class="form-control" id="rut" validateRut (keyup)="setFormatoRut()" readonly
          (change)="setFormatoRut()" maxlength="10" [(ngModel)]="model.rut" name="rut" placeholder="11111111-1">

      </div>
    </div>
    <div class="col-md-6 col-xs-12">
      <div class="form-group">
        <label for="nombres">Nombres <span class="text-danger">(*)</span></label>
        <input type="text" [(ngModel)]="model.nombres" class="form-control" id="nombres" aria-describedby="nombres"
          placeholder="Nombres" maxlength="111" (keyup)="error = ''">
      </div>
    </div>
    <div class="col-md-6 col-xs-12">
      <div class="form-group">
        <label for="primer-apellido">Primer apellido <span class="text-danger">(*)</span></label>
        <input type="text" [(ngModel)]="model.apellido1" class="form-control" id="primer-apellido"
          aria-describedby="primer-apellido" placeholder="Primer apellido" maxlength="111" (keyup)="error = ''">
      </div>
    </div>
    <div class="col-md-6 col-xs-12">
      <div class="form-group">
        <label for="segundo-apellido">Segundo apellido <span class="text-danger">(*)</span></label>
        <input type="text" [(ngModel)]="model.apellido2" class="form-control" id="segundo-apellido"
          aria-describedby="segundo-apellido" placeholder="Segundo apellido" maxlength="111" (keyup)="error = ''">
      </div>
    </div>
    <div class="col-md-6 col-xs-12">
      <div class="form-group">
        <label for="nacionalidad">Nacionalidad <span class="text-danger">(*)</span></label>
        <select class="form-control" [(ngModel)]="model.nacionalidad.id" aria-label="Nacionalidad"
          placeholder="Nacionalidad" id="nacionalidad" (change)="error = ''">
          <option value="0">Nacionalidad</option>
          <option *ngFor="let nacionalidad of nacionalidades" [value]="nacionalidad.id">{{nacionalidad.descripcion}}</option>
        </select>
      </div>
    </div>
    <div class="col-md-6 col-xs-12">
      <div class="form-group">
        <label for="fecha-nacimiento">Fecha de nacimiento <span class="text-danger">(*)</span></label>
        <div class="input-group mb-3">
          <div class="input-group-prepend">
            <span class="input-group-text" id="inputGroup-sizing-sm"><em class="bi bi-calendar-date"></em></span>
          </div>
          <input type="date" [(ngModel)]="model.fechaNacimiento" class="form-control" id="fecha-nacimiento"
          aria-describedby="fecha-nacimiento" (change)="error = ''">
        </div>
      </div>
    </div>
    <div class="col-md-6 col-xs-12">
      <div class="form-group">
        <label for="estado-civil">Estado civil <span class="text-danger">(*)</span></label>
        <select class="form-control" [(ngModel)]="model.estadoCivil.id" aria-label="Estado Civil"
          placeholder="Estado Civil" id="estado-civil" (change)="error = ''">
          <option value="0">Estado civil</option>
          <option *ngFor="let estadocivil of estadosCiviles" [value]="estadocivil.id">{{estadocivil.descripcion}}</option>
        </select>
      </div>
    </div>
    <div class="col-md-6 col-xs-12">
      <div class="form-group">
        <label for="genero">Género <span class="text-danger">(*)</span></label>
        <select class="form-control" [(ngModel)]="model.genero.id" aria-label="Masculino" placeholder="Género"
          id="genero" (change)="error = ''">
          <option value="0">Género</option>
          <option *ngFor="let genero of generos" [value]="genero.id">{{genero.descripcion}}</option>
        </select>
      </div>
    </div>
    <div class="col-md-6 col-xs-12">
      <div class="form-group">
        <label for="pais">País <span class="text-danger">(*)</span></label>
        <select class="form-control" (change)="error = ''" [(ngModel)]="model.pais.id" aria-label="País"
          placeholder="País" id="pais">
          <option *ngFor="let pais of paises" [value]="pais.id">{{pais.descripcion}}</option>
        </select>
      </div>
    </div>
    <div class="col-md-6 col-xs-12">
      <div class="form-group">
        <label for="region">Región <span class="text-danger">(*)</span></label>
        <select class="form-control" aria-label="region" placeholder="Región" id="region" [(ngModel)]="model.region.id"
          (change)="onChangeRegion()">
          <option value="0">Región</option>
          <option *ngFor="let region of regiones" [value]="region.id">{{region.descripcion}}</option>
        </select>
      </div>
    </div>
    <div class="col-md-6 col-xs-12">
      <div class="form-group">
        <label for="comuna">Comuna <span class="text-danger">(*)</span></label>
        <select class="form-control" (change)="error = ''" aria-label="comuna" placeholder="Comuna" id="comuna"
          [(ngModel)]="model.comuna.id">
          <option value="0">Comuna</option>
          <option *ngFor="let comuna of comunas" [value]="comuna.id">{{comuna.descripcion}}</option>
        </select>
      </div>
    </div>
    <div class="col-md-6 col-xs-12">
      <div class="form-group">
        <label for="calle">Calle <span class="text-danger">(*)</span></label>
        <input type="text" class="form-control" [(ngModel)]="model.calle" id="calle" aria-describedby="calle"
          placeholder="Calle" maxlength="111" (keyup)="error = ''">
      </div>
    </div>
    <div class="col-md-6 col-xs-12">
      <div class="form-group">
        <label for="numero-calle">Número de la calle <span class="text-danger">(*)</span></label>
        <input type="text" [(ngModel)]="model.numeroCalle" class="form-control" id="numero-calle"
          aria-describedby="numero-calle" placeholder="Número de la calle" maxlength="111" (keyup)="error = ''">
      </div>
    </div>
    <div class="col-md-6 col-xs-12">
      <div class="form-group">
        <label for="departamento-casa">Departamento/Casa</label>
        <input type="text" [(ngModel)]="model.departamento" class="form-control" id="departamento-casa"
          aria-describedby="departamento-casa" placeholder="Departamento/Casa" maxlength="111" (keyup)="error = ''">
      </div>
    </div>
    <div class="col-md-6 col-xs-12">
      <div class="form-group">
        <label for="numero-departamento">Número del departamento</label>
        <input type="text" [(ngModel)]="model.numeroDepartamento" class="form-control" id="numero-departamento"
          aria-describedby="numero-departamento" placeholder="Número del departamento" maxlength="111"
          (keyup)="error = ''">
      </div>
    </div>
    <div class="col-md-6 col-xs-12">
      <div class="form-group">
        <label for="telefono">Teléfono fijo</label>
        <div class="input-group mb-3">
          <div class="input-group-prepend">
            <span class="input-group-text" id="inputGroup-sizing-sm">+ 56 2</span>
          </div>
          <input type="text" [(ngModel)]="model.telefonoFijo" class="form-control" id="telefono"
            aria-describedby="telefono" placeholder="Teléfono fijo" maxlength="8" (keyup)="error = ''">
        </div>
      </div>
    </div>
    <div class="col-md-6 col-xs-12">
      <div class="form-group">
        <label for="celular">Celular <span class="text-danger">(*)</span></label>
        <div class="input-group mb-3">
          <div class="input-group-prepend">
            <span class="input-group-text" id="inputGroup-sizing-sm">+ 56 9</span>
          </div>
          <input type="text" [(ngModel)]="model.celular" class="form-control" id="celular" aria-describedby="celular"
            placeholder="Celular" maxlength="8" (keyup)="error = ''">
        </div>
      </div>
    </div>
    <div class="col-md-6 col-xs-12">
      <div class="form-group">
        <label for="email">Email <span class="text-danger">(*)</span></label>
        <input type="text" [(ngModel)]="model.email" readonly="true" class="form-control" id="email"
          aria-describedby="email" placeholder="Email" (keyup)="error = ''" maxlength="50">
      </div>
    </div>
    <div class="col-md-6 col-xs-12">
      <div class="form-group">
        <label for="empresa">Empresa <span class="text-danger">(*)</span></label>
        <input type="text" [(ngModel)]="model.empresa" class="form-control" id="empresa" aria-describedby="empresa"
          placeholder="Empresa" maxlength="111" (keyup)="error = ''">
      </div>
    </div>
    <div class="col-md-6 col-xs-12">
      <div class="form-group">
        <label for="cargo">Cargo <span class="text-danger">(*)</span></label>
        <input type="text" [(ngModel)]="model.cargo" class="form-control" id="cargo" aria-describedby="cargo"
          placeholder="Cargo" maxlength="111" (keyup)="error = ''">
      </div>
    </div>
  </div>

  <div class="position-relative pl-3">
    <h5 class="custom-item-border-100 ml-3">Estudios previos <small>(Máximo 3)</small></h5>
  </div>

  <div class="row mt-2 mb-2">
    <ng-container *ngFor="let carrera of model.carreras; let i = index">
      <div class="col-md-6 col-xs-12">
        <div class="form-group">
          <label for="carrera-1">Carrera {{i+1}} <span *ngIf="i==0" class="text-danger">(*)</span></label>
          <input type="text" class="form-control" [id]="'carrera-'+(i+1)" aria-describedby="carrera-1"
            [(ngModel)]="carrera.nombreCarrera" [placeholder]="'Carrera '+(i+1)" maxlength="100" (keyup)="error = ''">
        </div>
      </div>
      <div class="col-md-6 col-xs-12">
        <div class="form-group">
          <label for="institucion-1">Institución {{i+1}} <span *ngIf="i==0" class="text-danger">(*)</span></label>
          <input type="text" class="form-control" [id]="'institucion-'+(i+1)" aria-describedby="institucion-1"
            [(ngModel)]="carrera.institucion" [placeholder]="'Institución '+(i+1)" maxlength="100" (keyup)="error = ''">
        </div>
      </div>
    </ng-container>
  </div>

  <div class="text-center mt-2">
    <button type="button" (click)="clickAgregaCarrera()" class="btn btn-duoc btn-duoc-outline mr-1">
      <em class="bi bi-plus-circle"></em>
      Carrera
    </button>
  </div>

  <div class="position-relative pl-3 mt-4">
    <h5 class="custom-item-border-100 ml-3">Financiamiento</h5>
  </div>
  <div class="row mt-2 mb-4">
    <div class="col-md-12 col-xs-12">
      <div class="custom-card-gray p-1 pl-3 pr-3 mt-2">
        ¿Quién Financiará tu Curso o Diplomado?
      </div>
    </div>
  </div>
  <div class="row mt-2 mb-4">
    <div class="col-md-4 col-xs-4">
      <div class="form-check">
        <input class="form-check-input" type="radio" name="financiamiento" id="financiamiento"
          [(ngModel)]="model.financiamiento" value="Particular">
        <label class="form-check-label" for="financiamiento">
          Particular <div class="row" style="color: #9b9b9b;">(lo pagaré yo mismo)</div>
        </label>
      </div>
    </div>
    <div class="col-md-4 col-xs-4">
      <div class="form-check">
        <input class="form-check-input" type="radio" name="financiamiento" id="financiamiento2"
          [(ngModel)]="model.financiamiento" value="Empresa">
        <label class="form-check-label" for="financiamiento2">
          Empresa <div class="row" style="color: #9b9b9b;">(Mi empleador se hará cargo del pago)</div>
        </label>
      </div>
    </div>

    <div class="col-md-4 col-xs-4">
      <div class="form-check">
        <input class="form-check-input" type="radio" name="financiamiento" id="financiamiento3"
          [(ngModel)]="model.financiamiento" value="Mixto">
        <label class="form-check-label" for="financiamiento2">
          Pago mixto <div class="row" style="color: #9b9b9b;">(Co-financiado con empresa)</div>
        </label>
      </div>
    </div>
  </div>

  <div class="position-relative pl-3">
    <h5 class="custom-item-border-100 ml-3">¿Cómo obtuvo información del Diplomado/Curso?</h5>
  </div>
  <div class="row mt-2 mb-4"></div>
  <div class="row mt-2 mb-4">
    <div class="col-md-6 col-xs-12">
      <div *ngFor="let opcion of dataChecks" class="form-check">
        <input class="form-check-input" type="checkbox" [value]="opcion.id" [id]="opcion.id"
          (change)="onChangeInformacion()" [(ngModel)]="opcion.seleccionado" [checked]="checkFuenteInformacion(opcion.id)">
        <label [innerHTML]="opcion.nombre" class="form-check-label" [for]="opcion.id">
        </label>
      </div>
      <input type="text" [(ngModel)]="otro" class="form-control" id="otroTexto" aria-describedby="Otro">
    </div>
  </div>
  <div *ngIf="error!=''" class="col-md-12 col-xs-12 alert alert-danger mensaje-alerta">
    <em class='bi bi-x-circle icono-alerta'></em>
    <div role="alert" [innerHTML]="error"></div>
  </div>
  <div class="text-center mt-4">
    <button type="button" (click)="clickGuardar()" class="btn btn-duoc btn-duoc-success mr-1">
      Continuar
    </button>
  </div>
</div>