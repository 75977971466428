import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-modal-validaoc-pagomixto',
  templateUrl: './modal-validaoc-pagomixto.component.html',
  styleUrls: ['./modal-validaoc-pagomixto.component.css']
})
export class ModalValidaocPagomixtoComponent  {
  @Input() title = 'Titulo';
  @Input() innerHtmlText = 'Texto';
  @Input() id = 'modal-validaoc-pagomixto';



  irHome() {
    localStorage.clear();
    window.location.href = "https://www.duoc.cl/oferta-academica/cursos-diplomados/";

}

}
