import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { ContentService } from 'src/app/services/content/content.service';
import { ServicesService } from 'src/app/services/services.service';

@Component({
  selector: 'app-recuperar-contrasena',
  templateUrl: './recuperar-contrasena.component.html',
  styleUrls: ['./recuperar-contrasena.component.css']
})
export class RecuperarContrasenaComponent implements OnInit {
  formPassRecover: FormGroup
  contenido: any = {
    titulo: '',
    texto: ''
  };
  email: string = "";
  error: string = "";
  condicionExito: boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    private spinner: NgxSpinnerService,
    private contentService: ContentService,
    private service: ServicesService
  ) {
    this.formPassRecover = this.formBuilder.group({
      email: ['', [
        Validators.required,
        Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$"),
        Validators.maxLength(50)
      ]],
    });
  }

  ngOnInit(): void {
    this.spinner.show();
    this.contentService.getScreenContent('RECUPERAR_CONTRASENIA').subscribe({
      next: (respuesta: any[]) => {
        let objeto = respuesta[0];
        this.contenido = {
          titulo: objeto.titulo,
          texto: objeto.texto
        }
        this.spinner.hide()
      }, error: (error: any) => {
        this.spinner.hide()
      }
  });
  }

  requestNewPassword() {
    if (this.email != ""){
      const EMAIL_REGEX = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
      if (!EMAIL_REGEX.exec(this.email)) {
        this.error = '<b class="font-merriweather-bold">El Email ingresado no es correcto</b>';
      }
    } else {
      this.error = '<b class="font-merriweather-bold">El campo Email es obligatorio</b>';
    }
    if ( this.error == ""){
      this.spinner.show();
      this.service.postRecuperarContraseña({email:this.email}).subscribe(
        (response: any) => {
          this.condicionExito = response.valor;

          this.error = this.condicionExito? '<b class="font-merriweather-bold">Se ha enviado una contraseña provisoria al correo registrado</b>' : '<b class="font-merriweather-bold">' + response.error.mensaje + '</b>';
          this.limpiarError();
          this.spinner.hide();
        }
      );
    }else {
      this.limpiarError();
    }
  }
  limpiarError(): void {
    const limpiarError = () => {
      this.error = "";
      this.condicionExito = false;
    }
    setTimeout(limpiarError,3000);
  }

}
