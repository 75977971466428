import {Parametro} from "../../models/parametro";

export const PARAMETRO_FEATURE_KEY = 'parametro';

export interface ParametroState {
  parameter: Parametro;
  loaded: boolean;
  error?: any;
}

export const initialState: ParametroState = {
  parameter: new Parametro(),
  loaded: false,
  error: null,
};
