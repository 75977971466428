<nav class="navbar navbar-expand-lg navbar-light bg-light bg-color-duoc-primary pt-3 pb-3 fixed-top" *ngIf="login?.nombres == ''" aria-label="Site menu login">
  <div class="ml-2">
    <a [routerLink]="['/']">
      <img class="responsive size-logo duoc-logo-mobile d-lg-none" src="./assets/images/logo-duoc.svg"
      alt="logo-duoc" />
      <img class="responsive size-logo d-none d-lg-block ml-6" src="./assets/images/logo-escritorio.svg"
      alt="logo-duoc" />
    </a>
  </div>

  <button *ngIf="parametro.estadoSitio" [routerLink]="['/view-registro']" type="button" class="btn btn-duoc btn-duoc-dark dn-desktop btn-register-mobile">
    Registrate aquí
  </button>

  <div *ngIf="parametro.estadoSitio" class="navbar-collapse dn-mobile" id="navbarText">
    <ul class="navbar-nav ml-auto">
      <li class="nav-item mt-2">
        <em class="text-white dn-mobile">¿No tienes una cuenta?</em>
      </li>
      <li class="nav-item">
        <button [routerLink]="['/view-registro']" type="button" class="btn btn-duoc btn-duoc-dark ml-4">
          Registrate aquí
        </button>
      </li>
    </ul>
  </div>
</nav>

<nav class="navbar navbar-expand-lg bg-dark bg-color-duoc-primary fixed-top" *ngIf="login?.nombres != ''" aria-label="Site menu logged in">
  <div class="ml-2 pt-2 pb-2">
    <img class="responsive size-logo duoc-logo-mobile d-lg-none" src="./assets/images/logo-duoc.svg"
    alt="logo-duoc" />
    <img class="responsive size-logo d-none d-lg-block ml-6" src="./assets/images/logo-escritorio.svg"
    alt="logo-duoc" />
  </div>
  <h5 *ngIf="parametro.estadoSitio" class="text-white text-right mb-0 dn-desktop">Hola, {{ login.nombres }}</h5>
  <button *ngIf="parametro.estadoSitio" class="navbar-toggler text-right dn-desktop" type="button" data-toggle="collapse" data-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon">
      <h3 class="bi bi-list text-white"></h3>
    </span>
  </button>
  <div *ngIf="parametro.estadoSitio" class="collapse navbar-collapse dn-desktop" id="navbarNavAltMarkup">
    <div class="navbar-nav">
      <a class="nav-item nav-link text-right text-white dn-desktop" (click)="clickCerrarSesion()">CERRAR SESIÓN</a>
    </div>
  </div>

  <div *ngIf="parametro.estadoSitio" class="navbar-collapse dn-mobile" id="navbarText">
    <ul class="navbar-nav ml-auto">
      <li class="nav-item mt-2">
        <h5 class="text-white text-right mb-0 dn-mobile">Hola, {{ login.nombres }}</h5>
      </li>
      <li class="nav-item">
        <button type="button" (click)="showHideLogout()" id="btn-my-account" class="btn btn-duoc btn-duoc-dark ml-4">
          Mi cuenta <em id="logout-btn-hidden" class="bi bi-chevron-down"></em> <em id="logout-btn-showed" class="bi bi-chevron-up d-none"></em>
        </button>
      </li>
    </ul>
  </div>
</nav>
<div *ngIf="parametro.estadoSitio" id="floating-logout-btn" class="dn-mobile d-none">
  <p (click)="clickCerrarSesion()" class="floating-logout-btn font-lato">Cerrar Sesión</p>
</div>
