export class Login {
    email: string = "";
    password: string = "";
    correcto: boolean = false;
    error: Login | null = null;
    mensaje: string = "";
    nombres: string = "";
    apellido1: string = "";
    apellido2: string = "";
    numeroDocumento: string = "";
    rut: string = "";
    token: string = "";
    nombreCompleto: string = "";
    codigoCurso: number = 0;
    captcha: string = "";
    cambiarPassword: boolean = false;
    rutExtranjero: boolean = false;
}
