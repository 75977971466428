import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {ServicesService} from "../../services/services.service";
import {ActivatedRoute} from "@angular/router";
import {NgxSpinnerService} from "ngx-spinner";
import {VerEmailResponse} from "../../models/ver-email-response";
import {DomSanitizer, SafeHtml} from '@angular/platform-browser';

@Component({
  selector: 'app-ver-email',
  templateUrl: './ver-email.component.html',
  styleUrls: ['./ver-email.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class VerEmailComponent implements OnInit {
  emailResponse: VerEmailResponse = new VerEmailResponse();
  email!: SafeHtml;
  cargando: boolean = true;

  constructor(private servicios: ServicesService,
              private route: ActivatedRoute,
              private spinner: NgxSpinnerService,
              private sanitizer: DomSanitizer) {
    localStorage.clear();
  }

  ngOnInit(): void {
    const idEmail = this.route.snapshot.paramMap.get('idEmail');
    if (idEmail !== null) {
      this.getEmail(idEmail);
    }
  }

  getEmail(idEmail: string) {
    this.cargando = true;

    this.spinner.show();
    this.servicios.getEmail(idEmail)
      .subscribe({next: (response: VerEmailResponse) => {
        this.email = this.sanitizer.bypassSecurityTrustHtml(response.email);
        this.emailResponse.valor = response.valor;
        this.emailResponse.error = response.error;
        this.spinner.hide();
        this.cargando = false;

      },error: err => this.spinner.hide()})
  }

}
