<app-header-pasos [paso]=2 [estado]="false" [total]="5"  [paso_texto]="'POSTULACIÓN ENVIADA'" [paso_texto_siguiente]="'VALIDACIÓN POSTULACIÓN'"></app-header-pasos>



<div class="container container-duoc">
  <section class="mt-4 mb-4">
    <p class="text-center font-lato">EDUCACIÓN CONTINUA</p>
    <h1 class="text-center" [innerHTML]="titulo"></h1>
  </section>

  <div class="row">
    <div class="col-12 mt-4">
      <form (ngSubmit)="selectCourse()">
        <div class="custom-search-bar-duoc">
          <input type="text" class="form-control custom-search-input" id="buscarCurso" aria-describedby="buscarCurso" placeholder="Busca tu curso o diplomado" [formControl]="searchCoursesForm" [matAutocomplete]="auto">
          <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
            <mat-option *ngFor="let course of filteredCourses | async" [value]="course">
              {{course?.nombre}}
            </mat-option>
          </mat-autocomplete>
          <button type="button" (click)="selectCourse()" class="btn btn-warning custom-btn-search"><em class="bi bi-search"></em></button>
        </div>
      </form>

      <div class="alert alert-warning mt-2" *ngIf="!courseIsSelected">
        <div class="row">
          <div class="col-lg-12 col-xs-8">
            <p class="font-lato-bold pb-0 mb-0"><em class="bi bi-exclamation-circle mr-2"></em> Seleccione un nuevo Curso o Diplomado</p>
          </div>
        </div>
      </div>

      <!-- desktop -->
      <div class="mb-5-5 dn-mobile" *ngIf="courseIsSelected">
        <div class="custom-card-gray mt-4">
          <div class="custom-card-gray-content position-relative">
            <div class="custom-item-border-75 ml-4">
              <div class="row">
                <div class="col-md-6">
                  <!-- d-flex justify-content-center align-items-center -->
                  <div class="custom-card-gray-title">
                    <h5 class="font-lato text-uppercase">{{ selectedCourse.tipo }}</h5>
                    <h2 class="font-lato-bold text-uppercase">{{ selectedCourse.nombre }}</h2>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="custom-card-gray-content-info">
                    <p>Modalidad: <strong class="custom-bold">{{ selectedCourse.modalidad }}</strong></p>
                    <p>Fecha de inicio: <strong class="custom-bold">{{ selectedCourse.fechaInicio }}</strong></p>
                    <p>Fecha de término: <strong class="custom-bold">{{ selectedCourse.fechaTermino }}</strong></p>
                    <p>Días de clases: <strong class="custom-bold">{{ selectedCourse.diasClases }}</strong></p>
                    <p>Horario: <strong class="custom-bold">{{ selectedCourse.horario }}</strong></p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="custom-card-price">
          <p>Valor del programa: <strong class="custom-bold text-white">${{ selectedCourse.valorPrograma | number:'':'es-CL' }}</strong></p>
        </div>
      </div>

      <!-- mobile -->
      <div class="mb-5-5 dn-desktop" *ngIf="courseIsSelected">
        <div class="custom-card-gray mt-4">
          <div class="custom-card-gray-content position-relative">
            <div class="custom-item-border-75 ml-4">
              <div class="row">
                <div class="col-10">
                  <!-- d-flex justify-content-center align-items-center -->
                  <div class="custom-card-gray-title">
                    <h5 class="font-lato text-uppercase">{{ selectedCourse.tipo }}</h5>
                    <h2 class="font-lato-bold text-uppercase">{{ selectedCourse.nombre }}</h2>
                  </div>
                </div>
                <div class="col-2">
                  <em id="custom-card-gray-collapse-btn" class="bi bi-plus-circle custom-card-gray-collapse-btn" (click)="showMore()"></em>
                  <em id="custom-card-gray-collapse-btn-hidden" class="bi bi-dash-circle d-none custom-card-gray-collapse-btn" (click)="showLess()"></em>
                </div>
                <div id="custom-card-gray-expand-content" class="col-md-12 d-none custom-card-gray-expand-hidden">
                  <div class="custom-card-gray-content-info">
                    <p>Modalidad: <strong class="custom-bold">{{ selectedCourse.modalidad }}</strong></p>
                    <p>Fecha de inicio: <strong class="custom-bold">{{ selectedCourse.fechaInicio }}</strong></p>
                    <p>Fecha de término: <strong class="custom-bold">{{ selectedCourse.fechaTermino }}</strong></p>
                    <p>Días de clases: <strong class="custom-bold">{{ selectedCourse.diasClases }}</strong></p>
                    <p>Horario: <strong class="custom-bold">{{ selectedCourse.horario }}</strong></p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="custom-card-price">
          <p>Valor del programa: <strong class="custom-bold text-white">${{ selectedCourse.valorPrograma | number:'':'es-CL' }}</strong></p>
        </div>
      </div>

      <div class="custom-card-border" *ngIf="detalleOferta?.detalle?.requisitosEspeciales?.length > 0">
        <div class="ml-3 mr-3 mt-3 mb-3">
          <h3>
            {{ detalleOferta?.detalle?.mensajeRequisito?.[0]?.titulo }}
          </h3>
          <p class="text-justify text-muted">
            {{ detalleOferta?.detalle?.mensajeRequisito?.[0]?.texto }}
          </p>
        </div>

        <div *ngFor="let requisitoEspecial of detalleOferta?.detalle?.requisitosEspeciales; let i = index">
          <div class="row mb-3 cstm-row-height">
            <div class="col-md-4">
              {{ i+1 }}. {{ requisitoEspecial.nombre }}
              <input type="file" name="" [id]="'file-'+i" class="d-none" (change)="onFileChanged($event, i)" onclick="this.value=null;">
            </div>
            <div class="col-md-4 dn-mobile">
              <button type="button" class="btn btn-dark btn-file-upload" (click)="clickFileUpload('file-'+i)" *ngIf="requisitoEspecial.cargaArchivo">
                Seleccionar archivo
              </button>
              <button type="button" *ngIf="requisitoEspecial.descargaArchivo" (click)="downloadFile(requisitoEspecial)" class="btn btn-dark btn-file-upload">Descargar archivo</button>
              <a target="_blank"  *ngIf="requisitoEspecial.link" [href]="requisitoEspecial?.url" class="duoc-color-primary text-underline">{{ requisitoEspecial?.url }}</a>
            </div>
            <div class="col-md-4 dn-mobile" *ngIf="requisitoEspecial.cargaArchivo">
              <p class="d-inline text-break">{{ filesAttached[i]?.name ? filesAttached[i]?.name : 'Sin archivos seleccionado' }}</p>
              <div class="d-inline" *ngIf="filesAttached[i]?.name">
                <strong><i class="bi bi-trash text-danger cstm-icon-trash" (click)="removeFile(i)"></i></strong>
              </div>
            </div>

            <div class="col-md-4 dn-desktop">
              <button type="button" class="btn btn-dark btn-file-upload mb-0" (click)="clickFileUpload('file-'+i)" *ngIf="requisitoEspecial.cargaArchivo">
                Seleccionar archivo
              </button>
            </div>
            <div class="col-md-4 dn-desktop" *ngIf="requisitoEspecial.cargaArchivo">
              <p class="d-inline text-break">{{ filesAttached[i]?.name ? filesAttached[i]?.name : 'Sin archivos seleccionado' }}</p>
              <div class="d-inline" *ngIf="filesAttached[i]?.name">
                <strong><i class="bi bi-trash text-danger cstm-icon-trash" (click)="removeFile(i)"></i></strong>
              </div>
            </div>
            <div class="col-md-4 dn-desktop">
              <button type="button" *ngIf="requisitoEspecial.descargaArchivo" (click)="downloadFile(requisitoEspecial)" class="btn btn-dark btn-file-upload">Descargar archivo</button>
              <a target="_blank"  *ngIf="requisitoEspecial.link" [href]="requisitoEspecial?.url" class="duoc-color-primary text-underline">{{ requisitoEspecial?.url }}</a>
            </div>
          </div>
        </div>
      </div>

      <div *ngIf="errorTxtReqEspeciales != ''" class="col-md-12 col-xs-12 mt-1 alert alert-danger mensaje-alerta">
        <em class='bi bi-x-circle icono-alerta'></em>
        <div role="alert" [innerHTML]="errorTxtReqEspeciales"></div>
      </div>

      <div class="descuentos mt-4" *ngIf="detalleOferta?.detalle?.descuentos && detalleOferta?.detalle?.descuentos.length > 0 && !specialDiscounts">
        <h5 class="font-merriweather-bold ml-2">Descuentos</h5>

        <div class="row duoc-discount-card custom-card-border">
          <div class="col-md-2 col-xs-4 duoc-discount-card-percentaje">
            <h1 class="font-lato-bold">{{ detalleOferta?.detalle?.descuentos?.[0]?.porcentajeDescuento }}%</h1>
          </div>
          <div class="col-md-10 col-xs-8">
            <div class="duoc-discount-card-info">
              <h5>{{ detalleOferta?.detalle?.descuentos?.[0]?.mensajeDescuento?.titulo }}</h5>
              <p>{{ detalleOferta?.detalle?.descuentos?.[0]?.mensajeDescuento?.texto }}</p>
            </div>
          </div>
        </div>
      </div>

      <div class="descuentos mt-4" *ngIf="detalleOferta?.detalle?.descuentos && detalleOferta?.detalle?.descuentos.length > 0 && specialDiscounts">
        <div class="row duoc-discount-card custom-card-border">
          <div class="col-md-12 mt-3 mb-3">
            <h5 class="font-merriweather-bold d-inline">Descuentos</h5>
            <small class="font-merriweather-bold d-inline ml-2">(* Descuentos no acumulables)</small>
          </div>
          <div class="col-12">
            <p class="text-muted">Selecciona el descuento que deseas solicitar:</p>
            <div class="radio-options mt-3 mb-3">
              <div class="custom-control custom-radio custom-control-inline" *ngFor="let discount of detalleOferta?.detalle?.descuentos">
                <input type="radio" [id]="discount.id" [value]="discount" [(ngModel)]="selectedDiscountData" (ngModelChange)="selectDiscount(discount)" class="custom-control-input">
                <label class="custom-control-label" [for]="discount.id">{{ discount?.nombreTipoDescuento }}</label>
              </div>
            </div>
          </div>

          <div class="col-md-12" *ngIf="selectedDiscountData?.cargarArchivo">
            <h6 class="text-muted mb-0">Descuentos complementarios:</h6>
            <small class="text-muted">Para solicitar este documento, debe adjuntar la siguiente documentación: </small>
          </div>

          <div class="col-md-12" *ngIf="selectedDiscountData?.cargarArchivo">
            <div class="row mt-4 mb-3 cstm-row-height">
              <div class="col-md-4 p-0 pl-md-2 pr-md-2" style="text-align: justify">
                1. {{ selectedDiscountData?.descripcionArchivo }}
                <input type="file" name="" [id]="'file-discount-'+selectedDiscountData?.id" class="d-none" (change)="onFileChanged($event, 0, true)">
              </div>
              <div class="col-md-4 p-0 pl-md-2 pr-md-2">
                <button type="button" class="btn btn-dark btn-file-upload mb-0" (click)="clickFileUpload('file-discount-'+selectedDiscountData?.id)">
                  Seleccionar archivo
                </button>
              </div>
              <div class="col-md-4 p-0 pl-md-2 pr-md-2">
                <p class="d-inline text-break">{{ filesAttachedDiscounts[0]?.name ? filesAttachedDiscounts[0]?.name : 'Sin archivos seleccionado' }}</p>
                <div class="d-inline" *ngIf="filesAttachedDiscounts[0]?.name">
                  <strong><i class="bi bi-trash text-danger cstm-icon-trash" (click)="removeFile(0, true)"></i></strong>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>

      <div *ngIf="errorTxtDescEspeciales != ''" class="col-md-12 col-xs-12 mt-1 alert alert-danger mensaje-alerta">
        <em class='bi bi-x-circle icono-alerta'></em>
        <div role="alert" [innerHTML]="errorTxtDescEspeciales"></div>
      </div>

      <div class="alert alert-success mt-2" *ngIf="saved">
        <div class="row">
          <div class="col-lg-12 col-xs-8">
            <p class="font-lato-bold pb-0 mb-0">La postulación ha sido guardada exitosamente</p>
          </div>
        </div>
      </div>
      <div *ngIf="error!=''" class="col-md-12 col-xs-12 mt-3 alert alert-danger mensaje-alerta">
        <em class='bi bi-x-circle icono-alerta'></em>
        <div role="alert" [innerHTML]="error"></div>
      </div>
      <div *ngIf="!courseIsSelected" style="margin-bottom:250px;"></div>
      <div class="justify-content-center text-center mt-4 dn-mobile">
        <button type="button" (click)="guardar()" class="btn btn-duoc btn-duoc-outline mr-1" [disabled]=" (checkFilesAttached()) || (specialDiscounts && filesAttachedDiscounts.length < filesUploadDiscountsRequired)">
          Guardar
        </button> 
        <button type="button" (click)="postular()" class="btn btn-duoc btn-duoc-success" [disabled]=" (checkFilesAttached()) || (specialDiscounts && filesAttachedDiscounts.length < filesUploadDiscountsRequired)">
          Postular
        </button>
      </div>

      <div class="justify-content-center text-center mt-4 dn-desktop">
        <button type="button" (click)="postular()" class="btn btn-duoc btn-duoc-success btn-w-260" [disabled]=" (checkFilesAttached()) || (specialDiscounts && filesAttachedDiscounts.length < filesUploadDiscountsRequired)">
          Postular
        </button>
        <button type="button" (click)="guardar()" class="btn btn-duoc btn-duoc-outline btn-w-260 mt-2" [disabled]=" (checkFilesAttached()) || (specialDiscounts && filesAttachedDiscounts.length < filesUploadDiscountsRequired)">
          Guardar
        </button>
      </div>
    </div>
  </div>
</div>

<app-text-info-modal [title]="avisoModalTitle" [innerHtmlText]="avisoModalText"></app-text-info-modal>
<app-text-info-modal [title]="errorModalTitle" [innerHtmlText]="errorModalText" [id]="'errorModal'"></app-text-info-modal>

<button type="button" id="triggerErrorModal" class="btn btn-primary d-none" data-toggle="modal" data-target="#errorModal"></button>

<!-- Modal en validacion OC -->
<button type="button" id="modalCambioFinanciamientoBtn" class="btn btn-primary d-none" data-toggle="modal" data-target="#modalCambioFinanciamiento">
  Launch modal
</button>
<div class="modal fade" id="modalCambioFinanciamiento" tabindex="-1" role="dialog" aria-labelledby="modalCambioFinanciamiento"
  aria-hidden="true">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h2 class="modal-title" id="exampleModalLabel">Aviso</h2>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true" class="span-modal">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <p class="font-lato text-justify text-center-mobile">
          No puedes hacer uso de la franquicia tributaria (SENCE) en el programa seleccionado. <br> ¿Deseas cambiar el tipo de financiamiento?
        </p>
      </div>
      <div class="modal-footer text-center">
        <button (click)="cambioTipoFinanciamiento()" class="btn btn-duoc btn-duoc-outline">Si</button>
        <button (click)="filtrarSence()" type="button" class="btn btn-duoc btn-duoc-success" data-dismiss="modal">No</button>
        <button class="d-none" data-dismiss="modal" id="modalCambioFinanciamientoDismiss"></button>
      </div>
    </div>
  </div>
</div>
