import { Action, createReducer, on } from "@ngrx/store";
import * as ParametroActions from "./parametro.actions";
import { initialState, ParametroState } from "./parametro.state";

const parametroReducer = createReducer(
  initialState,
  on(ParametroActions.Init, (state) => ({ ...state, loaded: false, error: null })),
  on(ParametroActions.fetchParametro, (state) => ({ ...state, loaded: false, error: null })),
  on(ParametroActions.fetchParametroSuccess, (state, action) => ({
    ...state,
    parameter: action.parameter,
    loaded: true,
    error: null,
  })),
  on(ParametroActions.fetchParametroFailure, (state, { error }) => ({ ...state, error })),
);

export function reducer(state: ParametroState | undefined, action: Action) {
  return parametroReducer(state, action);
}
