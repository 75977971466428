<button type="button" class="btn btn-primary d-none " data-toggle="modal" [id]="id+'-btn1'" data-target="#modal-validaoc-pagomixto">
</button>
<!-- Modal -->
<div class="modal fade" [id]="id" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
  aria-hidden="true">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h2 class="modal-title">{{ title }}</h2>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body ">
        <p class="font-lato text-justify text-center-mobile"  [innerHtml]="innerHtmlText"></p>
      </div>
      
      <div class="modal-footer text-center">
        <div class="row justify-content-center">
          <button type="button" (click)="irHome()" class="btn btn-duoc btn-duoc-outline" data-dismiss="modal">
            IR AL HOME (DUOC.CL)
          </button>
        </div>
      </div>
    </div>
  </div>
</div>