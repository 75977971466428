import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ResumenInscripcion } from 'src/app/models/resumen-inscripcion';
import { ServicesService } from 'src/app/services/services.service';

@Component({
  selector: 'app-resumen-inscripcion-kit',
  templateUrl: './resumen-inscripcion-kit.component.html',
  styleUrls: ['./resumen-inscripcion-kit.component.css']
})
export class ResumenInscripcionKitComponent implements OnInit {

  /** Atributos de Clase */
  resumenInscripcion : ResumenInscripcion = new ResumenInscripcion;
  titulo : string = "";
  mensaje : string = "";
  textosPantalla = {
    titulo: '',
    texto: '',
  }
  
  constructor(
    private servicio: ServicesService,
    private spinner: NgxSpinnerService,
  ) {
    let login = this.servicio.getLogin();

    //Traer la información del resumen de inscripcion
    this.servicio.getResumenInscripcion(login.email,2).subscribe(
      (result) =>{
        this.resumenInscripcion = result;
        this.titulo = result.mensajes[0].titulo;
        this.mensaje =  result.mensajes[0].texto;
      }
    );
   }

  ngOnInit(): void {
    this.spinner.show()
    this.servicio.getTextos('RESUMEN_POSTULACION_KIT').subscribe({
      next: (respuesta) => {
        console.log(respuesta)
        this.textosPantalla.titulo = respuesta[0].titulo
        this.textosPantalla.texto = respuesta[0].texto
        this.spinner.hide()
      }, error: (error) => {
        this.spinner.hide()
      }
  });
  }

  clickCerrarSesion() {
    localStorage.clear();
    window.location.href = "https://www.duoc.cl/oferta-academica/cursos-diplomados/";
  }

  showMore() {
    window.document.getElementById('custom-card-gray-expand-content')?.classList.remove('d-none')
    window.document.getElementById('custom-card-gray-collapse-btn-hidden')?.classList.remove('d-none')
    window.document.getElementById('custom-card-gray-collapse-btn')?.classList.add('d-none')
  }

  showLess() {
    window.document.getElementById('custom-card-gray-collapse-btn')?.classList.remove('d-none')
    window.document.getElementById('custom-card-gray-collapse-btn-hidden')?.classList.add('d-none')
    window.document.getElementById('custom-card-gray-expand-content')?.classList.add('d-none')
  }
}
