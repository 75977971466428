<div class="container container-duoc mb-4">
  <div class="row mt-4">
    <div class="col-md-4 col-xs-12">
      <div class="position-relative">
        <div class="custom-item-border-100 duoc-logo-container mb-2">
        </div>
      </div>
    </div>
    <div class="col-md-8 col-xs-12">
      <div class="containers">
        <p class="font-lato">EDUCACION CONTINUA</p>
        <h1 class="font-merriweather-bold" [innerHTML]="titulo"></h1>
        <div class="custom-card-gray mb-2">
          <p class="d-block" [innerHTML]="texto"></p>
        </div>

        <div class="row p-0">
          <form #formRegistro="ngForm">

            <div class="col-md-12 p-0 mt-2">
              <div class="form-group">
                <label for="nombres">Nombres <span class="text-danger">(*)</span></label>
                <input type="text" class="form-control" id="nombres" aria-describedby="Nombres" name="postulante.nombres"
                  [(ngModel)]="postulante.nombres" placeholder="Nombres" required #name="ngModel"
                  [ngClass]="name.invalid && (name.dirty || name.touched) ? 'border-danger' : ''"/>
                <div *ngIf="name.invalid && (name.dirty || name.touched)">
                  <div *ngIf="name.errors?.['required']" class="text-danger">
                    El campo Nombres es requerido.
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-6 p-0">
                <div class="form-group">
                  <div class="primer-apellido">
                    <label for="apellido1">Primer Apellido <span class="text-danger">(*)</span></label>
                    <input type="text" class="form-control" id="apellido1" aria-describedby="apellido1"
                      name="postulante.apellido1" [(ngModel)]="postulante.apellido1" placeholder="Primer Apellido" required
                      [ngClass]="apellido1.invalid && (apellido1.dirty || apellido1.touched) ? 'border-danger' : ''"
                      #apellido1="ngModel">
                    <div *ngIf="apellido1.invalid && (apellido1.dirty || apellido1.touched)">
                      <div *ngIf="apellido1.errors?.['required']" class="text-danger">
                        El campo Primer apellido es requerido.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
  
              <div class="col-md-6 p-0">
                <div class="form-group">
                  <label for="apellido2">Segundo Apellido <span class="text-danger">(*)</span></label>
                  <input type="text" class="form-control" id="apellido2" aria-describedby="apellido2"
                    name="postulante.apellido2" [(ngModel)]="postulante.apellido2" placeholder="Segundo Apellido"
                    [ngClass]="apellido2.invalid && (apellido2.dirty || apellido2.touched) ? 'border-danger' : ''" required
                    #apellido2="ngModel">
                  <div *ngIf="apellido2.invalid && (apellido2.dirty || apellido2.touched)">
                    <div *ngIf="apellido2.errors?.['required']" class="text-danger">
                      El campo Segundo apellido es requerido.
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-md-12 p-0">
              <div class="form-group">
                <label for="email">Email <span class="text-danger">(*)</span></label>
                <input type="email" class="form-control" id="email" aria-describedby="Email" name="postulante.email"
                  [(ngModel)]="postulante.email" placeholder="Email" required #email="ngModel" maxlength="50"
                  (keyup)="error = ''"
                  [ngClass]="email.invalid && (email.dirty || email.touched) ? 'border-danger' : ''" />
                <div *ngIf="email.invalid && (email.dirty || email.touched)">
                  <div *ngIf="email.errors?.['required']" class="text-danger">
                    El campo Email es requerido.
                  </div>
                </div>
              </div>
            </div>

            <div class="col-md-12 p-0">
              <label for="password">Contraseña <span class="text-danger">(*)</span></label>
              <div class="input-group custom-input-with-icon">
                <input type="password" [type]="showPassword ? 'text' : 'password'" class="form-control" id="password"
                aria-describedby="password" name="postulante.password" [(ngModel)]="postulante.password"
                placeholder="Contraseña" required #contrasena="ngModel" maxlength="8" minlength="6"
                [ngClass]="contrasena.invalid && (contrasena.dirty || contrasena.touched) ? 'border-danger' : ''" 
                (change) = limpiarMensaje()>
                <span (click)="viewPassword()" class="input-group-text"><em [ngClass]="showPassword ? 'bi-eye' : 'bi-eye-slash'" id="togglePassword"></em></span>
              </div>
              
              <!-- Errors -->
              <div *ngIf="contrasena.invalid && (contrasena.dirty || contrasena.touched)">
                <div *ngIf="contrasena.errors?.['required']" class="text-danger">
                  El campo Contraseña es requerido.
                </div>
                <div *ngIf="contrasena.errors?.['minlength']" class="text-danger">
                  La contraseña debe tener minimo 6 carácteres.
                </div>
                <div *ngIf="contrasena.errors?.['minlength'] || contrasena.errors?.['required']" class="col-lg-12"
                  style="margin: 0px; padding: 0px;">&nbsp;</div>
              </div>
              <div class="col-lg-12" style="margin: 0px; padding: 0px;">
                <p style="font-size: 12px;">La contraseña debe tener largo minimo 6 y máximo 8 caracteres, con letras,
                  números y caracter especial.</p>
              </div>
            </div>
            
            <div class="col-md-12 p-0 mt-1">
              <label for="confirmarPassword">Confirmar Contraseña <span class="text-danger">(*)</span></label>
              <div class="input-group custom-input-with-icon">
                <input type="password" [type]="showComfirPassword ? 'text' : 'password'" class="form-control"
                id="confirmarPassword" aria-describedby="confirmarPassword" name="confirmarPassword"
                [(ngModel)]="confirmarPassword" placeholder="Confirmar Contraseña" required #confirmContrasena="ngModel"
                maxlength="8" minlength="6"
                [ngClass]="confirmContrasena.invalid && (confirmContrasena.dirty || confirmContrasena.touched) ? 'border-danger' : ''">
                <span (click)="viewConfirmPassword()" class="input-group-text"><em [ngClass]="showPassword ? 'bi-eye' : 'bi-eye-slash'" id="togglePassword"></em></span>
              </div>
    
              <div *ngIf="confirmContrasena.invalid && (confirmContrasena.dirty || confirmContrasena.touched)">
                <div *ngIf="confirmContrasena.errors?.['required']" class="text-danger">
                  El campo Confirmar Contraseña es requerido.
                </div>
                <div *ngIf="confirmContrasena.errors?.['minlength']" class="text-danger">
                  La contraseña debe tener minimo 6 carácteres.
                </div>
              </div>
              <div *ngIf="error!=''" class="alert alert-danger mt-2 mensaje-alerta" role="alert">
                <em class='bi bi-x-circle icono-alerta'></em>
                <div role="alert" [innerHTML]="error"></div>
              </div>
              <div *ngIf="mensaje!=''" class="alert alert-success mt-2 mensaje-alerta" role="alert">
                <em class='bi bi-check2-circle icono-alerta'></em>
                <div role="alert" [innerHTML]="mensaje"></div>
              </div>
            </div>

            <div class="dn-mobile">
              <div class="row p-0 justify-content-center mt-4 mb-4">
                <div class="col-md-6 text-center">
                  <button [routerLink]="['/']" type="button" class="btn btn-duoc btn-duoc-outline w-100">
                    Cancelar
                  </button>
                </div>
                <div class="col-md-6 text-center">
                  <button (click)="clickRegistro()" [disabled]="!formRegistro.valid" type="button" class="btn btn-duoc btn-duoc-success w-100">
                    Crear cuenta
                  </button>
                </div>
              </div>
            </div>

            <div class="dn-desktop">
              <div class="row p-0 justify-content-center">
                <div class="col-md-6 text-center">
                  <button (click)="clickRegistro()" [disabled]="!formRegistro.valid" type="button" class="btn btn-duoc btn-duoc-success w-100">
                    Crear cuenta
                  </button>
                </div>
                <div class="col-md-6 text-center mt-4 mb-4">
                  <button [routerLink]="['/']" type="button" class="btn btn-duoc btn-duoc-outline w-100">
                    Cancelar
                  </button>
                </div>
              </div>
            </div>

          </form>
        </div>

      </div>

    </div>
  </div>
</div>
