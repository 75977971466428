<div class="container container-duoc mb-4">
  <div class="row mt-4 mb-0">
    <div class="col-md-4 col-xs-12 d-none d-lg-block">
      <div class="position-relative">
        <div class="custom-item-border-100 duoc-logo-container mb-2">
        </div>
      </div>
    </div>
    <div class="col-md-6 col-xs-12">
      <div class="containers">
        <p class="font-lato">EDUCACION CONTINUA</p>
        <h1 class="font-merriweather-bold">{{titulo}} {{this.login.nombres}}</h1>
        <div class="custom-card-gray mb-2">
          <p class="p-1 pr-3 pl-3" [innerHTML]="texto"></p>
        </div>

        <!-- form -->
        <form [formGroup]="formularioRut">
          <div class="form-group">
            <label for="rut">RUT (Sin punto y con guión)</label>
            <input type="text" class="form-control" id="rut" validateRut (keyup)="setFormatoRut()"
              (change)="setFormatoRut()" maxlength="10"
              (keyup)="keyBorrarErrores()"
              formControlName="rut" name="rut" placeholder="11111111-1">
          </div>
          <div class="form-group">

            <label for="contrasena">Número de documento
              <strong>
                <button type="button" class="BOTON_BLANCO" data-toggle="modal" id="btnModal1"
                  data-target="#exampleModal1">
                  <em class="bi bi-question-circle"></em>
                </button>
              </strong>
            </label>
            <input type="text" class="form-control" id="numeroDocumento" name="login.numeroDocumento"
              aria-describedby="Número de documento" name="numeroDocumento"
              (keyup)="keyBorrarErrores()"
              onkeydown="return /[a,0-9]/i.test(event.key);" maxlength="10" formControlName="numeroDocumento"
              placeholder="Número de documento">

            <div class="form-check" style="display: none;">
              <input type="checkbox" class="form-check-input" id="exampleCheck1">
              <label class="form-check-label TEXT_EXTRANJERO" for="exampleCheck1">Soy extranjero(a), sin RUT
                chileno.</label>
            </div>
          </div>
          <div class="form-group">
            <div class="form-check">
              <input type="checkbox" class="form-check-input" id="rut-extranjero" formControlName="usuarioExtranjero" [(ngModel)]="usuarioExtranjero" (click)="validarUsuario($event)" [attr.disabled]="estadoRutExtranjero ? 'disabled' : null">
              <label class="form-check-label TEXT_EXTRANJERO" for="rut-extranjero">
                <strong>Soy extranjero(a), sin RUT chileno.</strong>
              </label>
          </div>
          </div>
          <div *ngIf="error!=''" class="col-md-12 col-xs-12 alert alert-danger mensaje-alerta">
            <em class='bi bi-x-circle icono-alerta'></em>
            <div role="alert" [innerHTML]="error"></div>
          </div>

          <div class="text-center mt-4">
            <button [disabled]="estadoRutExtranjero" (click)="clickContinuar()" type="button" class="btn btn-duoc btn-duoc-success mr-1">
              Continuar
            </button>
          </div>
        </form>

    </div>
    <div class="col-md-2 col-xs-none"></div>
  </div>
  <div class="font-lato mt-2 text-justify" style="font-size: 14px;" [innerHtml]="textoPrograma"></div>
</div>

<!-- Modal usuario extranjero info -->
<div class="modal fade" id="usuarioExtranjeroInfoModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
  aria-hidden="true">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h2 class="modal-title">Importante</h2>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div [innerHtml]="'Serás contactado por una de nuestras ejecutivas comerciales para ayudarte en tu proceso de postulación.'"></div>
      </div>
      <div class="modal-footer text-center">
        <div class="row justify-content-center">
          <button type="button" class="btn btn-duoc btn-duoc-outline" (click)="redirectHome()">
            Ir al home
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<button type="button" id="usuarioExtranjeroInfoModalBtn" class="btn btn-primary d-none" data-toggle="modal" data-target="#usuarioExtranjeroInfoModal"></button>

<!-- Modal Imagenes CEDULA-->
<div class="modal fade" id="exampleModal1" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel1"
  aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Ejemplo</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row justify-content-md-center">
          <div class="col-lg-12 TEXTO_SUPERIOR_AYUDA text-left">
            En caso de tener un carnet de identidad antiguo, encontrarás este número en la parte posterior del
            documento.
            <br />
            <br />
          </div>
          <div class="row justify-content-md-center">
            <div class="col-lg-4 TEXTO_AYUDA text-right">
              CÉDULA NUEVA PARTE DE ADELANTE
            </div>
            <div class="col-lg-7 text-center">
              <img class="img-fluid" src="assets/images/site/cedula1.png" alt="Cedula identidad frontal"/>
            </div>
          </div>
          <div class="row justify-content-md-center">
            <div class="col-lg-4 TEXTO_AYUDA text-right">
              CÉDULA ANTIGUA PARTE DE ATRÁS
            </div>
            <div class="col-lg-7 text-center">
              <img class="img-fluid" src="assets/images/site/cedula2.png" alt="Cedula identidad parte trasera"/>
            </div>
          </div>
          <div class="row justify-content-md-center">
            &nbsp;
          </div>
        </div>
        <div class="modal-footer text-center">
          <button type="button" class="btn btn-outline-dark" data-dismiss="modal">
            Cerrar
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
