import { Component, OnInit } from '@angular/core';
import {Parametro} from "../../models/parametro";
import {ParametroFacade} from "../../state/parametroFacade";
import {filter, switchMap} from "rxjs";

@Component({
  selector: 'app-view-footer',
  templateUrl: './view-footer.component.html',
  styleUrls: ['./view-footer.component.css']
})
export class ViewFooterComponent implements OnInit {
  parametro: Parametro = new Parametro();

  constructor(
    private readonly parametroFacade: ParametroFacade
  ) {
    // This is intentional
  }

  ngOnInit(): void {
    // This is intentional
    this.parametroFacade.loaded$
      .pipe(
        filter((isLoaded: boolean) => isLoaded),
        switchMap(() => this.parametroFacade.parametro$)
      )
      .subscribe((parametro: Parametro) => {
        this.parametro = parametro;
      });
  }

}
