import {
  HttpContextToken,
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import {catchError, Observable, throwError} from 'rxjs';
import { Login } from '../models/login';

export const BYPASS_LOG = new HttpContextToken(() => false);

@Injectable({
  providedIn: 'root'
})
export class AuthInterceptorService implements HttpInterceptor {



  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let request = req;
    if (!req.context.get(BYPASS_LOG)) {
      const token = this.getToken();
      if (token != "") {
        request = req.clone({
          setHeaders: {
            authorization: `Bearer ${token}`
          }
        });
      }
    }
    return next.handle(request).pipe(
      catchError((err) => {
        console.log(err);
        if (err instanceof HttpErrorResponse) {
          console.log(err.status)
          if (err.status === 401) {
            // redirect user to the logout page
            localStorage.clear();
            window.location.href = "/";
          }
        }
        return throwError( () => new Error(err));
      })
    )
  }

  getToken(): string {
    const valor = localStorage.getItem('login');
    const temp = valor != null ? atob(valor + '') : valor;
    const token = temp == null ? new Login : JSON.parse(temp) as Login;

    if (token.token != null && token.token != "" && token.token != undefined) {
      return token.token
    }
    return "";
  }

}
