<app-header-pasos [paso]=1 [estado]="false" [total]="5" [paso_texto]="'REGISTRO DE DATOS'" [paso_texto_siguiente]="'POSTULACIÓN ENVIADA'"></app-header-pasos>

<div class="container container-duoc">
    <section class="mt-4 mb-5">
      <p class="text-center font-lato">EDUCACIÓN CONTINUA</p>
      <h1 class="text-center">Ficha de pre-inscripción</h1>
    </section>
  
    <div class="position-relative pl-3 mb-3">
      <h5 class="custom-item-border-100 ml-3">Datos empresa</h5>
    </div>
  
    <div class="row mt-2 mb-4">
      <div class="col-md-6 col-xs-12">
        <div class="form-group">
          <label for="rut-empresa">RUT Empresa (sin punto y con gui&oacute;n)<span class="text-danger">(*)</span></label>
  
          <input type="text" class="form-control" id="rut-empresa" validateRut (keyup)="setFormatoRut()"
            (change)="setFormatoRut()" maxlength="10" [(ngModel)]="model.rutEmpresa" name="rut-empresa" placeholder="11111111-1">
  
        </div>
      </div>
      <div class="col-md-6 col-xs-12">
        <div class="form-group">
          <label for="razon-social">Razón social <span class="text-danger">(*)</span></label>
          <input type="text" [(ngModel)]="model.razonSocial" class="form-control" id="razon-social" aria-describedby="razon social"
            placeholder="Razón social" maxlength="111" (keyup)="error = ''">
        </div>
      </div>
      <div class="col-md-6 col-xs-12">
        <div class="form-group">
          <label for="email-empresa">Email Encargado de Capacitación <span class="text-danger">(*)</span></label>
          <input type="text" [(ngModel)]="model.emailContacto" class="form-control" id="email-empresa"
            aria-describedby="email-empresa" placeholder="Email Encargado de Capacitación" maxlength="50" (keyup)="error = ''">
        </div>
      </div>
      <div class="col-md-6 col-xs-12">
        <div class="form-group">
          <label for="telefono">Teléfono Encargado de Capacitación <span class="text-danger">(*)</span></label>
          <div class="input-group mb-3">
            <div class="input-group-prepend">
              <span class="input-group-text" id="inputGroup-sizing-sm">+ 56 2</span>
            </div>
            <input type="text" [(ngModel)]="model.telefonoContacto" class="form-control" id="telefono" aria-describedby="telefono"
              placeholder="11111111" maxlength="8" (keyup)="error = ''">
          </div>
        </div>
      </div>
      <div class="col-md-6 col-xs-12">
        <div class="form-group">
          <label for="financiamiento-SENCE">Financiada a través de SENCE <span class="text-danger">(*)</span></label>
          <select class="form-control" [(ngModel)]="model.financiadaSence" aria-label="Financiamiento SENCE"
            id="financiamiento-SENCE">
            <option [value]=true>Si</option>
            <option [value]=false>No</option>
          </select>
        </div>
      </div>
      <div class="col-md-6 col-xs-12"></div>
      <div *ngIf="error!='' " class="col-md-12 col-xs-12 alert alert-danger mensaje-alerta">
        <em class='bi bi-x-circle icono-alerta'></em>
        <div role="alert" [innerHTML]="error"></div>
      </div>
    </div>
    <div class="col-md-12 col-xs-12 text-center mt-4 btns-container">
      <button type="button" (click)="volverAFicha()" class="btn btn-duoc btn-duoc-outline">Volver</button>
      <button type="button" (click)="clickGuardar()" class="btn btn-duoc btn-duoc-success">
        Continuar
      </button>
    </div>
  </div>