<div class="container container-duoc mb-4">
  <div class="row mt-4">
    <div class="col-md-4 col-xs-12">
      <div class="position-relative">
        <div class="custom-item-border-100 duoc-logo-container mb-2">
        </div>
      </div>
    </div>
    <div class="col-md-6 col-xs-12">
      <div class="containers">
        <p class="font-lato">EDUCACION CONTINUA</p>
        <h1 class="font-merriweather-bold" style="text-overflow: ellipsis;overflow: hidden;" [innerHTML]="titulo"></h1>
        <div class="custom-card-gray mb-2">
          <p class="p-1 pr-3 pl-3" [innerHTML]="texto"></p>
        </div>

        <div class="form-group">
          <label for="email">Email</label>
          <input type="email" class="form-control" id="email" aria-describedby="Email" maxlength="50" name="login.email"
            [(ngModel)]="login.email" placeholder="Email" (keyup)="error = ''">
        </div>

        <label for="contrasena">Contraseña</label>
        <div class="input-group custom-input-with-icon">
          <input type="password" [type]="showPassword ? 'text' : 'password'" minlength="6" maxlength="8"
            class="form-control" id="contrasena" name="login.password" [(ngModel)]="login.password"
            aria-describedby="Contraseña" placeholder="Contraseña" (keyup)="error = ''">
          <span (click)="viewPassword()" class="input-group-text"><em
              [ngClass]="showPassword ? 'bi-eye' : 'bi-eye-slash'" id="togglePassword"></em></span>
        </div>
        <div *ngIf="error!=''" class="alert alert-danger mt-2 mensaje-alerta" role="alert">
          <em class='bi bi-x-circle icono-alerta'></em>
          <div role="alert" [innerHTML]="error"></div>
        </div>

        <a class="text-right d-block duoc-color-primary text-decoration-underline" [routerLink]="['/recuperar-contrasena']">
          <u><small>¿Olvidaste tu contraseña?</small></u>
        </a>

        <div class="text-center mt-4">
          <button (click)="clickLoggin()" type="button" class="btn btn-duoc btn-duoc-success mr-1">
            Ingresar
          </button>
        </div>

        <div class="text-center mt-2">
          <re-captcha id="recaptcha" name="recaptcha" #recaptcha="ngModel" [(ngModel)]="token" required
            [class.is-invalid]="recaptcha.invalid && (recaptcha.dirty || recaptcha.touched)" class="g-recaptcha">
          </re-captcha>
          <div *ngIf="recaptcha.invalid && (recaptcha.dirty || recaptcha.touched)" class="invalid-feedback g-recaptcha">
          </div>
        </div>
        <p class="text-center text-small">Este sitio está protegido por reCAPTCHA y Google Políticas de privacidad y
          Condiciones de servicio.</p>
      </div>

    </div>
    <div class="col-md-2 col-xs-none"></div>
  </div>
</div>