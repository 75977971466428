import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import {Observable} from 'rxjs';
import {startWith, map} from 'rxjs/operators';
import { PostulacionService } from 'src/app/services/postulacion/postulacion.service';
import { ServicesService } from 'src/app/services/services.service';

@Component({
  selector: 'app-postulacion',
  templateUrl: './postulacion.component.html',
  styleUrls: ['./postulacion.component.css']
})
export class PostulacionComponent implements OnInit {
  courses: Array<any> = [{}]
  filteredCourses: Observable<Array<any>>
  selectedCourse: any = {}
  courseIsSelected: boolean = false
  saved: boolean = false
  financiamientoSence: boolean = false
  titulo: string = 'Postulación';

  searchCoursesForm = new FormControl('')

  detalleOferta: any
  filesAttached: any = []
  filesUploadRequired: number = 0

  filesAttachedDiscounts: any = []
  specialDiscounts: boolean = false
  filesUploadDiscountsRequired: number = 0
  selectedDiscountData: any

  error: string = ''
  errorModalTitle: string = ''
  errorModalText: string = ''
  avisoModalTitle: string = "";
  avisoModalText: string = "";

  errorTxtReqEspeciales: string = ''
  errorTxtDescEspeciales: string = ''

  cursoFueraPlazo:boolean = false;
  estadoPostulación:any;

  allowedFilesExt = [
    'image/jpg',
    'image/jpeg',
    'application/pdf',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'application/vnd.ms-excel.sheet.macroEnabled.12'
  ]
  allowedFilesExtDescuento = [
    'image/jpg',
    'image/jpeg',
    'application/pdf',
  ]
  allowedFileSizeBytes = 10485760

  filterSence: boolean = false

  constructor(
    private _postulacionService: PostulacionService,
    private spinner: NgxSpinnerService,
    private servicios: ServicesService,
    private router: Router,
  ) {
    this.filteredCourses = this.searchCoursesForm.valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value ?? '')),
    );
    const routerData = this.router.getCurrentNavigation()?.extras?.state?.['data']
    if (routerData?.infoModal) {
      window.document.getElementById('modal-ayuda-organizar-btn')?.click();
    }

    this.estadoPostulación = this._postulacionService.getEvent().subscribe((data) => {
      if (data.mensaje === "aceptar") {
            if (data.texto != "No se encontraron resultados en la búsqueda" || data.texto != "No se ha podido obtener los requisitos especiales y/o descuento para la oferta seleccionada.  Por favor, inténtelo nuevamente." ) {
              this._postulacionService.eliminarOferta().subscribe(res => {

              })
              this.detalleOferta = null;
              this.courseIsSelected = false;

            }



      }
    });

  }

  ngOnInit(): void {
    this.getCoursesByName('');
    this.spinner.show()
    this._postulacionService.ofertaSeleccionada().subscribe({
      next: (res1) => {
        console.log(res1)
        this.financiamientoSence = res1?.financiamientoSence
        if ( res1.oferta ) {
          this.selectedCourse = res1.oferta
          this.courseIsSelected = true

          if ( this.financiamientoSence && !this.selectedCourse.usoSense ) {
            window.document.getElementById('modalCambioFinanciamientoBtn')?.click()
          }
        }

        this.servicios.getTextos('POSTULACION').subscribe({
          next: (respuesta) => {
            this.titulo = respuesta[0].titulo;
          }
      });

        if(this.selectedCourse.idSap != null){
          this._postulacionService.detalleOferta(this.selectedCourse.idSap).subscribe({
            next: (res2: any) => {

              if ( res2?.error === null ) {
                this.detalleOferta = res2
                const filesRequired = this.detalleOferta?.detalle?.requisitosEspeciales?.filter((requisito: any) => requisito.cargaArchivo)
                this.filesUploadRequired = filesRequired.length

                const specialDiscountsArr = res2?.detalle?.descuentos?.filter((discount: any) => discount.tipoAutomatico === false)
                this.specialDiscounts = specialDiscountsArr.length > 0
                if ( this.specialDiscounts ) {
                  this.selectedDiscountData = this.detalleOferta?.detalle?.descuentos?.[0]
                  this.selectDiscount(this.selectedDiscountData)
                  console.log(this.selectedDiscountData)
                }
                this.verificarFueraPlazo();


              }
              this.spinner.hide()
            }, error: err => {
              this.errorModalTitle = 'Error'
              this.errorModalText = 'No se ha podido obtener los requisitos especiales y/o descuento para la oferta seleccionada.  Por favor, inténtelo nuevamente.'
              window.document.getElementById('triggerErrorModal')?.click();
              this.spinner.hide()
            }
        });
        }
        else{
          this.spinner.hide();
        }
      }, error: err => this.spinner.hide()
  })


  }

  mostrarFuerzaPlazo() {
    console.log("mostrar fuera plazo");
    this.avisoModalTitle = "Importante";
      this.avisoModalText = "No puedes dar continuidad al programa actual, ya que su fecha de inicio ha caducado. Sin embargo, tienes la opción de seleccionar otro curso para continuar con tu postulación.";
      window.document.getElementById('text-info-modal-btn')?.click();

  }

  displayFn(course: any): string {
    return course?.nombre;
  }

  selectedCourseFn(selectedCourse: any) {
    this.detalleOferta = null
    this.filesAttached = []
    this.filesAttachedDiscounts = []
    this.selectedCourse = selectedCourse
    console.log(this.selectedCourse)
    if ( this.financiamientoSence && !this.selectedCourse.usoSense ) {
      window.document.getElementById('modalCambioFinanciamientoBtn')?.click()
    }
    this.courseIsSelected = true
    this.spinner.show()
    this._postulacionService.detalleOferta(this.selectedCourse.idSap).subscribe({
      next: (res: any) => {
        if ( res?.error === null ) {
          this.detalleOferta = res
          const filesRequired = this.detalleOferta?.detalle?.requisitosEspeciales?.filter((requisito: any) => requisito.cargaArchivo)
          this.filesUploadRequired = filesRequired.length

          const specialDiscountsArr = res?.detalle?.descuentos?.filter((discount: any) => discount.tipoAutomatico === false)
          this.specialDiscounts = specialDiscountsArr.length > 0
          if ( this.specialDiscounts ) {
            this.selectedDiscountData = this.detalleOferta?.detalle?.descuentos?.[0]
            this.selectDiscount(this.selectedDiscountData)
            console.log(this.selectedDiscountData)
          }
        }
        this.spinner.hide()
      }, error: err => this.spinner.hide()
  })
  }

  selectCourse() {
    if ( typeof this.searchCoursesForm.value === 'object' ) {
      this.selectedCourseFn(this.searchCoursesForm.value)
    } else {
      const selectedCourse = this.courses.filter((course: any) => course.nombre === this.searchCoursesForm.value)
      console.log(selectedCourse)
      if ( selectedCourse?.[0] ) {
        this.selectedCourseFn(selectedCourse[0])
      } else {
        this.avisoModalTitle = "Aviso";
        this.avisoModalText = "No se encontraron resultados en la búsqueda";
        window.document.getElementById('text-info-modal-btn')?.click();
      }
    }
  }

  getCoursesByName(name: string) {
    this._postulacionService.filterCursoDiplomado(name).subscribe(
      (res) => {
        this.courses = res.ofertas
      }
    )
  }

  private _filter(value: string): string[] {
    if ( typeof value != 'string' ) {
      return [];
    }
    const filterValue = this._normalizeValue(value);
    if ( !this.filterSence ) {
      return this.courses.filter(course => this._normalizeValue(course?.nombre)?.includes(filterValue));
    } else {
      const filterSenceCourses = this.courses.filter(course => course?.usoSense === true)
      return filterSenceCourses.filter(course => this._normalizeValue(course?.nombre)?.includes(filterValue));
    }
  }

  private _normalizeValue(value: string): string {
    return value?.toLowerCase()?.normalize("NFD")?.replace(/[\u0300-\u036f]/g, '');
  }

  guardar() {
    if(this.courseIsSelected) {

      this.spinner.show()

      let formdata:FormData = this.getFormData();
      this._postulacionService.save(formdata).subscribe({
        next: (res: any) => {
          console.log(res)
          if ( res?.error === null ) {
            this.saved = true
            setTimeout(() => {
              this.saved = false
            }, 3000);
          } else {
            this.error = res?.error?.mensaje ? res.error.mensaje : 'Hubo un error al guardar la postulación'
            setTimeout(() => {
              this.error = ''
            }, 3000);
          }
          this.spinner.hide()
        }, error: err => {
          this.error = 'Error en los servicios'
          setTimeout(() => {
            this.error = ''
          }, 3000);
          this.spinner.hide()
        }
    })
    } else {

      this.avisoModalTitle = "Aviso";
      this.avisoModalText = "Debes seleccionar un Curso o Diplomado";
      window.document.getElementById('text-info-modal-btn')?.click();
    }
  }

  /**
   * obtiene los datos del formulario
   */
  getFormData():FormData {
    let formdata = new FormData();
      formdata.append("codigoCurso", this.selectedCourse.idSap)
      const usoSence = this.financiamientoSence && this.selectedCourse.usoSense ? '1' : '0'
      formdata.append("usoSence", usoSence)

      if ( this.detalleOferta?.detalle?.descuentos?.[0]?.id && !this.specialDiscounts ) {
        formdata.append("descuentos[0].descuentoId", this.detalleOferta?.detalle?.descuentos?.[0]?.id)
        formdata.append("tipoPersona.tipoTitulado", this.detalleOferta?.detalle?.descuentos?.[0]?.tipoTitulado)
        formdata.append("tipoPersona.nombreCarrera", this.detalleOferta?.detalle?.descuentos?.[0]?.nombreCarrera)
        formdata.append("tipoPersona.codigoCarrera", this.detalleOferta?.detalle?.descuentos?.[0]?.codigoCarrera)
        formdata.append("tipoPersona.anioTitulacion", this.detalleOferta?.detalle?.descuentos?.[0]?.anioTitulacion)
      }

      if ( this.specialDiscounts && this.selectedDiscountData?.id && !this.filesAttachedDiscounts?.[0] ) {
        formdata.append("descuentos[0].descuentoId", this.selectedDiscountData.id)
      }

      if ( this.detalleOferta?.detalle?.requisitosEspeciales?.length > 0) {
        this.detalleOferta?.detalle?.requisitosEspeciales.forEach((requisito: any, i: number) => {
          if ( !requisito.cargaArchivo ) {
            formdata.append(`requisitosEspeciales[${i}].id`, this.detalleOferta?.detalle?.requisitosEspeciales?.[i]?.id);
          }
        });
      }

      let index = 0
      this.filesAttached.forEach((file: any, i: number) => {
        if ( file ) {
          formdata.append(`requisitosEspeciales[${index}].id`, this.detalleOferta?.detalle?.requisitosEspeciales?.[i]?.id);
          formdata.append(`requisitosEspeciales[${index}].nombreArchivo`, file?.name);
          formdata.append(`requisitosEspeciales[${index}].tipoArchivo`, file?.type);
          formdata.append(`requisitosEspeciales[${index}].archivo`, file, file?.name);
          index++
        }
      });

      if ( this.specialDiscounts && this.filesAttachedDiscounts?.[0] ) {
        formdata.append(`descuentos[0].descuentoId`, this.selectedDiscountData.id);
        formdata.append(`descuentos[0].nombreArchivo`, this.filesAttachedDiscounts?.[0]?.name);
        formdata.append(`descuentos[0].tipoArchivo`, this.filesAttachedDiscounts?.[0]?.type);
        formdata.append(`descuentos[0].archivo`, this.filesAttachedDiscounts?.[0], this.filesAttachedDiscounts?.[0]?.name);
      }

      return formdata;
  }

  verificarFueraPlazo():boolean {
    let cursoFueraPlazo:boolean = false;
const fechaInicio = this.selectedCourse.fechaInicio;
const partesFechaInicio = fechaInicio.split('/').map(Number);
const fechaInicioDate = new Date(partesFechaInicio[2], partesFechaInicio[1] - 1, partesFechaInicio[0]);

  const hoy = new Date();
    hoy.setHours(0, 0, 0, 0); // Omitir la hora actual para comparar solo las fechas

    if (fechaInicioDate <= hoy) {
      cursoFueraPlazo = true;
      this.cursoFueraPlazo = true;
      this.mostrarFuerzaPlazo();
    }
    else {
      this.cursoFueraPlazo = false;
    }

    return cursoFueraPlazo;

  }

  /**
   * Obtener datos para realizar la postulacion
   */
  getDataFormPostular():FormData {
    let formdata = new FormData();
    formdata.append("codigoCurso", this.selectedCourse.idSap)
    const usoSence = this.financiamientoSence && this.selectedCourse.usoSense ? '1' : '0'
    formdata.append("usoSence", usoSence)

    if ( this.detalleOferta?.detalle?.descuentos?.[0]?.id && !this.specialDiscounts ) {
      formdata.append("descuentos[0].descuentoId", this.detalleOferta?.detalle?.descuentos?.[0]?.id)
      formdata.append("tipoPersona.tipoTitulado", this.detalleOferta?.detalle?.descuentos?.[0]?.tipoTitulado)
      formdata.append("tipoPersona.nombreCarrera", this.detalleOferta?.detalle?.descuentos?.[0]?.nombreCarrera)
      formdata.append("tipoPersona.codigoCarrera", this.detalleOferta?.detalle?.descuentos?.[0]?.codigoCarrera)
      formdata.append("tipoPersona.anioTitulacion", this.detalleOferta?.detalle?.descuentos?.[0]?.anioTitulacion)
    }


    if ( this.specialDiscounts && this.selectedDiscountData?.id && !this.filesAttachedDiscounts?.[0] ) {
      formdata.append("descuentos[0].descuentoId", this.selectedDiscountData.id)
    }

    if ( this.detalleOferta?.detalle?.requisitosEspeciales?.length > 0) {
      this.detalleOferta?.detalle?.requisitosEspeciales.forEach((requisito: any, i: number) => {
        if ( !requisito.cargaArchivo ) {
          formdata.append(`requisitosEspeciales[${i}].id`, this.detalleOferta?.detalle?.requisitosEspeciales?.[i]?.id);
        }
      });
    }

    let index = 0
    this.filesAttached.forEach((file: any, i: number) => {
      if ( file ) {
        formdata.append(`requisitosEspeciales[${index}].id`, this.detalleOferta?.detalle?.requisitosEspeciales?.[i]?.id);
        formdata.append(`requisitosEspeciales[${index}].nombreArchivo`, file?.name);
        formdata.append(`requisitosEspeciales[${index}].tipoArchivo`, file?.type);
        formdata.append(`requisitosEspeciales[${index}].archivo`, file, file?.name);
        index++
      }
    });

    if ( this.specialDiscounts  ) {
      formdata.append("descuentos[0].descuentoId", this.selectedDiscountData.id)
    }

    if ( this.specialDiscounts && this.filesAttachedDiscounts?.[0] ) {
      formdata.append(`descuentos[0].descuentoId`, this.selectedDiscountData.id);
      formdata.append(`descuentos[0].nombreArchivo`, this.filesAttachedDiscounts?.[0]?.name);
      formdata.append(`descuentos[0].tipoArchivo`, this.filesAttachedDiscounts?.[0]?.type);
      formdata.append(`descuentos[0].archivo`, this.filesAttachedDiscounts?.[0], this.filesAttachedDiscounts?.[0]?.name);
    }
    return formdata;
  }

  postular() {
    if (!this.verificarFueraPlazo()) {
    if (this.courseIsSelected) {
      console.log("this.selectedCourse", this.selectedCourse);
      this.spinner.show()
      let formdata:FormData = this.getDataFormPostular();

      this._postulacionService.postular(formdata).subscribe({
        next: (res: any) => {
          console.log(res)
          if ( res?.error === null ) {
            this.router.navigate(['/terminos-y-condiciones'], { state: { data: {selectedCourse: this.selectedCourse} } })
          } else {
            this.error = res?.error?.mensaje ? res.error.mensaje : 'Hubo un error al guardar la postulación'
            setTimeout(() => {
              this.error = ''
            }, 3000);
          }
          this.spinner.hide()
        }, error: err => this.spinner.hide()
    })
    } else {

      this.avisoModalTitle = "Aviso";
      this.avisoModalText = "Debes seleccionar un Curso o Diplomado";
      window.document.getElementById('text-info-modal-btn')?.click();
    }
  }
  else {

    this.avisoModalTitle = "Importante";
      this.avisoModalText = "No puedes dar continuidad al programa actual, ya que su fecha de inicio ha caducado. Sin embargo, tienes la opción de seleccionar otro curso para continuar con tu postulación.";
      window.document.getElementById('text-info-modal-btn')?.click();


  }
  }

  showMore() {
    window.document.getElementById('custom-card-gray-expand-content')?.classList.remove('d-none')
    window.document.getElementById('custom-card-gray-collapse-btn-hidden')?.classList.remove('d-none')
    window.document.getElementById('custom-card-gray-collapse-btn')?.classList.add('d-none')
  }

  showLess() {
    window.document.getElementById('custom-card-gray-collapse-btn')?.classList.remove('d-none')
    window.document.getElementById('custom-card-gray-collapse-btn-hidden')?.classList.add('d-none')
    window.document.getElementById('custom-card-gray-expand-content')?.classList.add('d-none')
  }

  clickFileUpload(input: any) {
    document.getElementById(input)?.click()
  }

  limpiarErrores() {
    setTimeout(() => {
      this.errorTxtReqEspeciales = ''
      this.errorTxtDescEspeciales = ''
    }, 3000)
  }
  validarArchivoCargado(files: any, discountFile: any, index: any): boolean {
    if ( !discountFile ) {
      if ( this.filesAttached[index] ) this.filesAttached[index] = null

      if ( files[0].size > this.allowedFileSizeBytes ) {
        this.errorTxtReqEspeciales = 'El tamaño del archivo es mayor a 10Mb.'
        this.limpiarErrores()
        return false
      }
      if ( !this.allowedFilesExt.includes(files[0].type) ) {
        this.errorTxtReqEspeciales = 'Tipo de archivo no válido. El tipo de archivo debe ser PDF, Excel o jpg'
        this.limpiarErrores()
        return false
      }
    } else {
      if ( this.filesAttachedDiscounts[index] ) this.filesAttachedDiscounts.splice(index, 1)

      if ( files[0].size > this.allowedFileSizeBytes ) {
        this.errorTxtDescEspeciales = 'El tamaño del archivo es mayor a 10Mb.'
        this.limpiarErrores()
        return false
      }
      if ( !this.allowedFilesExtDescuento.includes(files[0].type) ) {
        this.errorTxtDescEspeciales = 'Tipo de archivo no válido. El tipo de archivo debe ser PDF o jpg'
        this.limpiarErrores()
        return false
      }
    }
    return true
  }
  onFileChanged(event: any, index: number, discountFile: boolean = false) {
    console.log(event)
    if ( event?.target?.files ) {
      console.log(event.target.files)
       if (!this.validarArchivoCargado(event?.target?.files, discountFile, index)) return
      if ( !discountFile ) {
        this.filesAttached[index] = event.target.files[0]
      } else {
        this.filesAttachedDiscounts[index] = event.target.files[0]
      }
      console.log(this.filesAttached)
      event.target.value = "";
    }
  }

  removeFile(index: number, discountFile: boolean = false) {
    if ( !discountFile ) {
      this.filesAttached[index] = null
    } else {
      this.filesAttachedDiscounts.splice(index, 1)
    }
  }

  downloadFile(item: any) {
    console.log(item)
    const src = `data:${item?.tipoArchivo};base64,${item?.archivo}`;
    const link = document.createElement("a")
    link.href = src
    link.download = item?.nombreArchivo
    link.click()
    link.remove()
  }

  selectDiscount(discount: any) {
    this.selectedDiscountData = discount
    console.log(this.selectedDiscountData)
    this.filesAttachedDiscounts = []
    if ( this.selectedDiscountData.cargarArchivo ) {
      this.filesUploadDiscountsRequired = 1
    } else {
      this.filesUploadDiscountsRequired = 0
    }
    console.log(this.filesUploadDiscountsRequired)
  }

  checkFilesAttached() {
    const filteredFiles = this.filesAttached?.filter((file: any) => file !== null)
    return Object.keys(filteredFiles).length < this.filesUploadRequired
  }

  cambioTipoFinanciamiento() {
    this.financiamientoSence = false
    document.getElementById('modalCambioFinanciamientoDismiss')?.click()
  }

  filtrarSence() {
    this.filterSence = true
    this.detalleOferta = null
    this.filesAttached = []
    this.filesAttachedDiscounts = []
    this.courseIsSelected = false
    this.selectedCourse = undefined
  }
}
