<app-header-pasos *ngIf="getPostulacionTerminado" [paso]=3 [estado]="estadoFinal" [rechazado]="postulacionRechazada" [total]="5" [paso_texto]="'VALIDACIÓN POSTULACIÓN'" [paso_texto_siguiente]="'PAGO'" ></app-header-pasos>

<div class="container container-duoc">
  <section class="mt-4 mb-4">
    <h5 class="text-center">EDUCACIÓN CONTINUA</h5>
    <h1 class="text-center" [innerHTML]="titulo"></h1>
  </section>

  <!-- Desktop -->
  <div class="dn-mobile">
    <div class="custom-card-gray mt-2">
      <div class="custom-card-gray-content position-relative">
        <div class="custom-item-border-75 pl-4">
          <div class="row">
            <div class="col-md-6">
              <div class="custom-card-gray-title">
                <h5 class="font-lato-bold text-uppercase">{{resultado.oferta.tipo}}</h5>
                <h2 class="font-lato-bold">{{resultado.oferta.nombre}}</h2>
              </div>
            </div>
            <div class="col-md-6">
              <div class="custom-card-gray-content-info">
                <p>Modalidad: <strong class="font-lato-bold">{{resultado.oferta.modalidad}}</strong></p>
                <p>Fecha de inicio: <strong class="font-lato-bold">{{resultado.oferta.fechaInicio}}</strong></p>
                <p>Fecha de término: <strong class="font-lato-bold">{{resultado.oferta.fechaTermino}}</strong></p>
                <p>Días de clases: <strong class="font-lato-bold">{{resultado.oferta.diasClases}}</strong></p>
                <p>Horario: <strong class="font-lato-bold">{{resultado.oferta.horario}}</strong></p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Mobile -->
  <div class="dn-desktop">
    <div class="custom-card-gray mt-2">
      <div class="custom-card-gray-content position-relative">
        <div class="custom-item-border-75 pl-4">
          <div class="row">
            <div class="col-10">
              <div class="custom-card-gray-title">
                <h5 class="font-lato-bold text-uppercase">{{resultado.oferta.tipo}}</h5>
                <h2 class="font-lato-bold">{{resultado.oferta.nombre}}</h2>
              </div>
            </div>
            <div class="col-2">
              <em id="custom-card-gray-collapse-btn" class="bi bi-plus-circle custom-card-gray-collapse-btn" (click)="showMore()"></em>
              <em id="custom-card-gray-collapse-btn-hidden" class="bi bi-dash-circle d-none custom-card-gray-collapse-btn" (click)="showLess()"></em>
            </div>
            <div id="custom-card-gray-expand-content" class="col-md-12 d-none custom-card-gray-expand-hidden">
              <div class="custom-card-gray-content-info">
                <p>Modalidad: <strong class="custom-bold">{{resultado.oferta.modalidad}}</strong></p>
                <p>Fecha de inicio: <strong class="custom-bold">{{resultado.oferta.fechaInicio}}</strong></p>
                <p>Fecha de término: <strong class="custom-bold">{{resultado.oferta.fechaTermino}}</strong></p>
                <p>Días de clases: <strong class="custom-bold">{{resultado.oferta.diasClases}}</strong></p>
                <p>Horario: <strong class="custom-bold">{{resultado.oferta.horario}}</strong></p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="custom-card-price">
    <p>Valor del programa: <strong class="custom-bold text-white">${{ resultado.oferta.valorPrograma | number:'':'es-CL'
        }}</strong></p>
  </div>

  <div class="mt-5-5"></div>
  <div *ngIf="resultado.estado == 'VALIDACION_MANUAL_RECHAZADA'">
    <div class="postulacion-info">
      <h3 class="font-lato-bold mb-4" [innerHTML]="resultado.mensajes[0].titulo"></h3>
      <div [innerHTML]="resultado.mensajes[0].texto">
      </div>
    </div>
    <div class="postulacion-info" *ngIf="resultado.motivoRechazo != ''">
      <h3 class="font-lato-bold mb-4" [innerHTML]=""></h3>
      <div [innerHTML]="resultado.motivoRechazo">
      </div>
    </div>
    <div class="postulacion-info">
      <h3 class="font-lato-bold mb-4" [innerHTML]="resultado.mensajes[1].titulo"></h3>
      <div [innerHTML]="resultado.mensajes[1].texto">
      </div>
    </div>
  </div>
  <div *ngIf="resultado.estado != 'VALIDACION_MANUAL_RECHAZADA'">
    <div class="postulacion-info" *ngFor="let mensaje of resultado.mensajes">
      <h3 class="font-lato-bold mb-4" [innerHTML]="mensaje.titulo"></h3>
      <div [innerHTML]="mensaje.texto">
      </div>
    </div>
  </div>

  <div *ngIf="error!=''" class="col-md-12 col-xs-12 alert alert-danger mensaje-alerta">
    <em class='bi bi-x-circle icono-alerta'></em>
    <div role="alert" [innerHTML]="error"></div>
  </div>

  <div class="text-center mt-4 dn-mobile">
    <button type="button" data-toggle="modal" data-target="#modalVolverPostular"
      class="btn btn-duoc btn-duoc-outline mr-1"
      *ngIf="!postulacionPendiente">
      Volver a postular
    </button>
    <button type="button" class="btn btn-duoc btn-duoc-success" (click)="clickContinuar()" [disabled]="cursoFueraPlazo"
    *ngIf="!postulacionRechazada && !postulacionPendiente">
      Continuar
    </button>
    <button type="button" class="btn btn-duoc btn-duoc-outline mt-2 btn-w-260" (click)="clickHome()"
    *ngIf="postulacionPendiente">
      Ir a Home
    </button>
  </div>

  <div class="text-center mt-4 mb-2 dn-desktop">
    <button type="button" class="btn btn-duoc btn-duoc-success btn-w-260" (click)="clickContinuar()"
    *ngIf="!postulacionRechazada && !postulacionPendiente">
      Continuar
    </button>
    <button type="button" data-toggle="modal" data-target="#modalVolverPostular"
      class="btn btn-duoc btn-duoc-outline mt-2 btn-w-260"
      *ngIf="!postulacionPendiente">
      Volver a postular
    </button>
    <button type="button" class="btn btn-duoc btn-duoc-outline mt-2 btn-w-260" (click)="clickHome()"
    *ngIf="postulacionPendiente">
      Ir a Home
    </button>
  </div>
</div>

<app-text-info-modal [title]="tituloPopup" [innerHtmlText]="infoModalText"></app-text-info-modal>

<!-- Modal Volver a Postular -->
<!-- Modal -->
<div class="modal fade" id="modalVolverPostular" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
  aria-hidden="true">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h2 class="modal-title" id="exampleModalLabel">Aviso</h2>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <p class="font-lato text-justify text-center-mobile">Al presionar el botón Volver a postular, Ud. podrá generar una nueva
          postulación y la actual postulación quedará no vigente. <span class="dn-desktop"><br></span> ¿Está seguro de volver a postular?</p>
      </div>
      <div class="modal-footer text-center">
        <button type="button" class="btn btn-duoc btn-duoc-outline" data-dismiss="modal"
          (click)="clickRepostular()">Si</button>
        <button type="button" class="btn btn-duoc btn-duoc-success" data-dismiss="modal">No</button>
      </div>
    </div>
  </div>
</div>
<button type="button" style="display: none;" data-toggle="modal" data-target="#modalImportante" id="btnModalImportante"
  class="btn btn-duoc btn-duoc-outline mr-1">
  &nbsp;&nbsp;&nbsp;&nbsp;
</button>
<!-- Modal Volver a Postular -->
<!-- Modal -->
<div class="modal fade show" id="modalImportante" tabindex="-1" role="dialog" data-keyboard="false"
  data-backdrop="static" aria-labelledby="modalImportante" aria-hidden="true">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h2 class="modal-title" id="exampleModalLabel">Importante</h2>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="clickAceptar()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <p class="font-lato text-justify">
          La reserva de tu cupo vence en: <strong>{{respuestaResultado?.vigenciaReserva}} horas.</strong>
          <br />
          N&uacute;mero de vacantes disponibles: <strong>{{respuestaResultado?.cantVacantes}}.</strong>
          <br /><br />
          <span [innerHTML]="textosVer.texto"></span>
        </p>
      </div>
      <div class="modal-footer text-center">
        <button type="button" class="btn btn-duoc btn-duoc-outline" data-dismiss="modal" (click)="clickAceptar()"
          data-dismiss="modal">Aceptar</button>
      </div>
    </div>
  </div>
</div>
