
<div class="ESPACIO_TOP"></div>
<div class="container">

    <div class="row justify-content-md-center">

        <div class="col-lg-12">
            <div class="row justify-content-md-center">

                <div class="col-lg-12 SUBTITULOS-SITE text-left d-lg-none">
                    EDUCACIÓN CONTINUA
                </div>
                <div class="col-lg-12 SUBTITULOS-SITE text-center d-none d-lg-block">
                    EDUCACIÓN CONTINUA
                </div>
                <div class="col-lg-12">
                    &nbsp;
                </div>
                <div class="col-lg-12 TITULOS-SITE text-left d-lg-none" [innerHTML] ="titulo">
                </div>
                <div class="col-lg-12 TITULOS-SITE text-center d-none d-lg-block" [innerHTML] ="titulo">
                </div>
                <div class="col-lg-12">&nbsp;</div>
                <div class="col-lg-12">
                    <div [innerHTML]="texto" class="CAJA_TERMINOS_LEGALES DOS_COLUMNAS d-none d-lg-block">
                    </div>
                    <div [innerHTML]="texto" class="CAJA_TERMINOS_LEGALES col-lg-12 d-lg-none">
                    </div>
                </div>
                <div class="col-lg-12">
                    &nbsp;
                </div>
                <div class="col-lg-12 text-center">
                    <div class="form-check">
                        <input type="checkbox" class="form-check-input" id="exampleCheck1"
                            [(ngModel)]="terminosLegales">
                        <label class="form-check-label TEXT_EXTRANJERO" for="exampleCheck1">
                            <strong>Acepto los términos legales</strong>
                        </label>
                    </div>
                </div>
                <div class="col-lg-12">
                    &nbsp;
                </div>
                <div class="col-lg-12 text-center d-none d-lg-block">
                    <button type="button" [disabled]="!terminosLegales" class="btn btn-duoc btn-duoc-success" (click)="clickAceptaLegales()">
                        Aceptar
                    </button>
                </div>
                <div class="col-lg-12 d-none d-lg-block">
                    &nbsp;
                </div>
            </div>
        </div>
        <div class="col-lg-3">
        </div>
    </div>
    <div class="row justify-content-md-center">
        <div class="col-lg-12 text-left d-none d-lg-block">
        </div>
    </div>
    <div class=" d-lg-none" style="text-align: center;
                padding-top: 15px;
                padding-left: 76px;
                padding-right: 76px;
                margin: 0 auto 0 auto;
                vertical-align: middle;
                z-index: 1000;
                position: fixed;
                bottom: -6px;
                background-color: #fff;
                left: 0;">
        Para continuar con el proceso, debes aceptar los Términos legales que se encuentran al final.
        <div class="col-lg-12">
            &nbsp;
        </div>
        <div class="col-lg-12 text-center">
            <button type="button" [disabled]="!terminosLegales" class="btn btn-duoc btn-duoc-success" (click)="clickAceptaLegales()">
                Aceptar
            </button>
        </div>
        <div class="col-lg-12">
            &nbsp;
        </div>
    </div>
</div>
<div class="ESPACIO_BOTTOM"></div>
<div class="ESPACIO_BOTTOM_MOBILE"></div>