<ngx-spinner bdColor = "rgba(238, 238, 238, .8)" size = "medium" color = "#FFB800" type = "ball-running-dots" [fullScreen] = "true"><p style="color: #1A1A1A" > Cargando... </p></ngx-spinner>

<div id="page-container">
  <app-view-header *ngIf="!isPantallaVerEmail" class="duoc-menu-header"></app-view-header>
   <div id="content-wrap">
    <router-outlet></router-outlet>
    <app-modal-ayuda *ngIf="parametro.estadoSitio"></app-modal-ayuda>
   </div>
  <app-view-footer id="footer" *ngIf="!isPantallaVerEmail"></app-view-footer>
</div>
