import {catchError, interval, mergeMap, Observable, of, switchMap} from "rxjs";
import {Actions, createEffect, ofType} from "@ngrx/effects";
import {Injectable} from "@angular/core";

import * as parametroActions from './parametro.actions';
import {ParametroActionsNames} from './parametro.actions';
import {map, startWith} from "rxjs/operators";
import {Parametro} from "../../models/parametro";
import {ServicesService} from "../../services/services.service";

@Injectable()
export class ParametroEffects {
  constructor(
    private readonly actions$: Actions,
    private servicios: ServicesService
  ) {
  }

  public readonly fetchParametro$: Observable<any> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ParametroActionsNames.fetchParametro),
        switchMap(() => interval(60 * 1000).pipe(startWith(0))),
        mergeMap(() =>
          this.servicios.getParametro()
            .pipe(
              map((data: Parametro) =>
                parametroActions.fetchParametroSuccess({parameter: data})
              ),
              catchError((error: any) =>
                of(parametroActions.fetchParametroFailure({error: error.message}))
              )
            )
        )
      )
  );
}
