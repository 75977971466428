import { Component, OnInit } from '@angular/core';
import { Pantallas } from 'src/app/models/pantallas';
import { ContentService } from 'src/app/services/content/content.service';

@Component({
  selector: 'app-modal-ayuda',
  templateUrl: './modal-ayuda.component.html',
  styleUrls: ['./modal-ayuda.component.css']
})
export class ModalAyudaComponent implements OnInit {
  textos: Pantallas[] = [];
  paginaLista: Pantallas[] = [];
  pagina: number = 1;
  numeroDocumento: string = "";

  titulo: string = "titulo";
  texto: string = "texto";
  error: string = "";
  
  constructor(
    private contentService: ContentService
  ) {
    const modalContent = this.contentService.getInfoModal()
    if ( modalContent ) {
      this.textos = JSON.parse(modalContent)
    }
    this.contentService.modalInfoData.subscribe((res) => {
      this.textos = res
    })
  }

  ngOnInit(): void {
    // This is intentional
  }

  private setOrganizaLista(pagina: number) {
    let item = 1;
    this.pagina = pagina;
    this.paginaLista = [];
    let index = 0;
    this.textos.forEach(element => {
      if (this.pagina == 1) {
        if (item <= 3) {
          this.paginaLista[index] = element;
          index++;
        }
      } else if (this.pagina > 1) {
        let empieza = ((this.pagina - 2) * 2) + 3;
        let fin = empieza + 2;
        if (item > empieza && item <= fin) {
          this.paginaLista[index] = element;
          index++;
        }
      }
      item++;
    });
  }

  clickAnterior(): void {
    this.setOrganizaLista(this.pagina - 1);
  }

  clickSiguiente(): void {
    this.setOrganizaLista(this.pagina + 1);
  }

  esUltimaPagina(): boolean {
    let esUltima = false;
    let total = ((this.pagina - 1) * 2) + 3;
    esUltima = total >= this.textos.length;
    return esUltima;
  }

  clickOrganizaLista(): void {
    this.setOrganizaLista(1);
  }

}
