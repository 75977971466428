import { Component } from '@angular/core';
import { NgxSpinnerService, } from 'ngx-spinner';
import { ServicesService } from 'src/app/services/services.service';

@Component({
  selector: 'app-recuperar-contrasena-temp',
  templateUrl: './recuperar-contrasena-temp.component.html',
  styleUrls: ['./recuperar-contrasena-temp.component.css']
})
export class RecuperarContrasenaTempComponent {

  error: string = "";
  password: string = "";
  passwordProvisoria: string = "";
  confirmarPassword: string = "";
  condicionExito: boolean = false;

  showPassword: boolean = false;
  showComfirPassword: boolean = false;

  errorPassword: boolean = false;
  errorPasswordConfirmar: boolean = false;

  constructor( private servicios: ServicesService,
    private spinner: NgxSpinnerService,
  ) {
    const login = this.servicios.getLogin();
    if (login.token == "") {
      this.redirigirPantalla();
    }
  }

  validarPassword (password: string, nueva: boolean): void {
    if ( password.length >= 6 || password.length == 0){
      if ( nueva ) {
        this.errorPassword = false;
      }else {
        this.errorPasswordConfirmar = false;
      }
    }else if (nueva) {
        this.errorPassword = true;

      }else{
        this.errorPasswordConfirmar = true;
      }
  }

  requestNewPassword() {
      const exp_password = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@.$!%*#?&])[A-Za-z\d@.$!%*#?&]{3,}$/;
      this.error = "";
      //Validar la información del postulante
      if (this.password.length < 6 || this.password.length > 8){
        this.error = "<b>La contraseña debe tener largo minimo 6 y máximo 8 caracteres</b>"
      }
      if (!exp_password.exec(this.password)) {
        this.error ="<b>La contraseña debe tener mínimo un número, una letra y un caracter especial</b>";
      }
      if (this.password != this.confirmarPassword) {
        this.error = "<b>Las contraseñas ingresadas no coinciden</b>";
      }
      if (this.confirmarPassword == '') {
        this.error = "<b>El campo Confirmar nueva contraseña es obligatoria</b>";
      }
      if (this.password == '') {
        this.error = "<b>El campo Nueva contraseña es obligatoria</b>";

      }
      if (this.passwordProvisoria == '') {
        this.error = "<b>La contraseña provisoria es obligatoria</b>";
      }
      if (this.error == ""){
        this.spinner.show();
        this.servicios.actualizarPassword({ password: this.passwordProvisoria, nuevaPassword: this.password}).subscribe(
          (response: any) => {
            this.spinner.hide();
            if (response.error == null){
              this.condicionExito = true;
              this.error = '<b>¡ Su contraseña fue cambiada con exito !</b>';
              setTimeout(this.redirigirPantalla,2000);

            }else {
              this.condicionExito = false;
              this.error = "<b>" + response.error.mensaje + "</b>";
            }
          }
        )
      }
      this.limpiarError();
  }
  limpiarError(): void {
    const eliminarError = () => {
      this.error = "";
    }
    setTimeout(eliminarError,3000);

  }
  redirigirPantalla(): void {
    this.error = "";
    localStorage.clear();
    window.location.href = "/";
  }
  viewPassword() {
    this.showPassword = !this.showPassword;
  }

  viewConfirmPassword() {
    this.showComfirPassword = !this.showComfirPassword;
  }

}
