import {Injectable} from '@angular/core';
import {select, Store} from '@ngrx/store';
import {Observable} from 'rxjs';
import * as parametroSelectors from './parametro/parametro.selectors';
import * as parametroActions from './parametro/parametro.actions';
import {ParametroState} from "./parametro/parametro.state";
import {Parametro} from "../models/parametro";

@Injectable()
export class ParametroFacade {
  public readonly loaded$: Observable<boolean> = this.store.pipe(
    select(parametroSelectors.fetchParametroLoaded)
  );
  public readonly parametro$: Observable<Parametro> = this.store.pipe(
    select(parametroSelectors.fetchParametro)
  );

  constructor(private readonly store: Store<ParametroState>) {
  }

  public init(): void {
    this.store.dispatch(parametroActions.Init());
  }

  public parametro(): void {
    this.store.dispatch(parametroActions.fetchParametro());
  }
}
